import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AvatarSizeClassEnum, AvatarSizeEnum } from '../../enum/avatar.enum';
import { NpaperTypographyEnum } from '../../enum/npaper-typography.enum';
import { AvatarSize } from '../../types/avatar.type';
import { BehaviorAvatarComponent } from '../behavior-avatar/behavior-avatar.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent extends BehaviorAvatarComponent implements OnInit {

  @Input() size: AvatarSize;
  @Input() done: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.apolySizeAvatar(this.size);
    this.applyTypeFormatAvatar(this.type);
    if (this.name) {
      this.initial = this.generateInitial(this.name);
      this.applySizeInitials(this.size);
    }

    if (this.icon) {
      this.applySizeIcon(this.size);
    }

    if (typeof this.index === 'number') {
      this.applyRandomColor();
      return;
    }

    this.applyColor(this.color);
  }

  private applySizeInitials(size: AvatarSize): void {
    switch (size) {
      case AvatarSizeEnum.XSMALL:
        this.addClass(NpaperTypographyEnum.IconBody14);
        break;
      case AvatarSizeEnum.SMALL:
        this.addClass(NpaperTypographyEnum.Heading16);
        break;
      case AvatarSizeEnum.LARGE:
      case AvatarSizeEnum.MEDIUM:
      default:
        this.addClass(NpaperTypographyEnum.Heading20);
        break;
    }
  }

  private applySizeIcon(size: AvatarSize): void {
    switch (size) {
      case AvatarSizeEnum.SMALL:
        this.addClass(NpaperTypographyEnum.IconBody18);
        break;
      case AvatarSizeEnum.XSMALL:
        this.addClass(NpaperTypographyEnum.IconBody14);
        break;
      case AvatarSizeEnum.MEDIUM:
        this.addClass(NpaperTypographyEnum.IconBody22);
        break;
      case AvatarSizeEnum.LARGE:
      default:
        this.addClass(NpaperTypographyEnum.IconBody34);
        break;
    }
  }

  public generateInitial(nameUser: string): string {
    let initials = '';
    let isInitialForbidden = true;
    const separateName = nameUser.toUpperCase().split(' ');
    const forbiddenInitials: string[] = ['CU'];

    initials += separateName[0].charAt(0);

    if (separateName.length > 1) {
      initials += separateName[1].charAt(0);
      while (isInitialForbidden) {
        if (forbiddenInitials.includes(initials)) {
          initials = separateName[0].charAt(0);
          initials += separateName[1].charAt(Math.floor(Math.random() * 10) % separateName[1].length);
        } else {
          isInitialForbidden = false;
        }
      }
    }

    return initials;
  }

  private apolySizeAvatar(size: AvatarSize): void {
    switch (size) {
      case AvatarSizeEnum.SMALL:
        this.addClass(AvatarSizeClassEnum.SMALL);
        break;
      case AvatarSizeEnum.XSMALL:
        this.addClass(AvatarSizeClassEnum.XSMALL);
        break;
      case AvatarSizeEnum.MEDIUM:
        this.addClass(AvatarSizeClassEnum.MEDIUM);
        break;
      case AvatarSizeEnum.LARGE:
      default:
        this.addClass(AvatarSizeClassEnum.LARGE);
        break;
    }
  }
}
