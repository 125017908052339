import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { NIpService } from './ip.model';

@Injectable()
export class IpService {
    constructor(
        private requestService: RequestService,
    ) { }

    public async getUserIp(): Promise<string | null> {
        try {
            let ip: string | null = null;
            ip = await this.jsonIp();
            if (ip === null) {
                ip = await this.ipify();
            }

            return ip;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private async jsonIp(): Promise<string | null> {
        try {
            const response: NIpService.IJsonIpResponse = await this.requestService
                .get('https://jsonip.com/', undefined, undefined, {});
            if (!!response?.ip === true) {
                return response.ip;
            }
            return null;
        } catch (error) {
            return null;
        }
    }
    private async ipify(): Promise<string | null> {
        try {
            const response: NIpService.IIpifyResponse = await this.requestService.get('https://api.ipify.org/?format=json', undefined, undefined, {});
            if (!!response?.ip === true) {
                return response.ip;
            }
            return null;
        } catch (error) {
            return null;
        }
    }
}
