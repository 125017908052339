<div class="assinar-em-lote" [ngStyle]="{'height': isVisaoMobile ?  '75vh' : '82vh'}">
    <header class="header">
        <div *ngIf="isVisaoMobile">
            <div class="d-flex justify-content-between align-items-center border-header">
                <div class="p-3" [ngClass]="{'Heading20': !isVisaoMobile, 'Heading16': isVisaoMobile}">
                    Assinar em lote

                    <span *ngIf="papers" style="color: #ffffff; background-color: #60C02B;"
                        class="mx-2 number body12 px-2 py-1">
                        {{papers.length}}
                    </span>
                </div>

                <div class="select-search-assunto" [ngClass]="{'open-select-search-assunto': openInputSearchAssunto}">
                    <app-select-search (eventFocus)="onFocusInputSearchAssunto($event)"
                        (eventSelectOption)="onAssuntoSelect($event)" [optionsAssunto]="optionAssuntoSearch"
                        [formSelect]="formControlSelectFilterInAssunto" [isSearchExternal]="true"
                        (eventKeyUp)="onKeyPress()" placeholder="Pesquisar por assunto">
                    </app-select-search>
                </div>

            </div>
        </div>
        <div *ngIf="!isVisaoMobile" class="row border-header">
            <div class="col-md-5 col-lg-3" style="align-self: center">
                <div class="p-3" [ngClass]="{'Heading20': !isVisaoMobile, 'Heading16': isVisaoMobile}">
                    Assinar em lote

                    <span *ngIf="papers" style="color: #ffffff; background-color: #60C02B;"
                        class="mx-2 number body12 px-2 py-1">
                        {{papers.length}}
                    </span>
                </div>
            </div>

            <div class="col-md-7 col-lg-9">
                <div class="d-flex" style="justify-content: right;">
                    <div *ngIf="!isVisaoTablet" style="align-self: center" class="select-search-assunto"
                        [ngClass]="{'open-select-search-assunto': openInputSearchAssunto}">
                        <app-select-search (eventFocus)="onFocusInputSearchAssunto($event)"
                            (eventSelectOption)="onAssuntoSelect($event)" [optionsAssunto]="optionAssuntoSearch"
                            [formSelect]="formControlSelectFilterInAssunto" [isSearchExternal]="true"
                            (eventKeyUp)="onKeyPress()" placeholder="Pesquisar por assunto">
                        </app-select-search>
                    </div>

                    <button *ngIf="!isVisaoMobile" style="margin-right: 24px;" [disabled]="papersSelected.length === 0"
                        class="btn assinar-documento" (click)="assinarDocumentos()"><i
                            class="np-edit iconBody12 mr-1"></i>Assinar
                        documentos</button>
                </div>
            </div>
        </div>

    </header>

    <div *ngIf="existePapers" class="row mb-md-2" [ngClass]="{'p-4': !isVisaoMobile, 'p-0': isVisaoMobile}">
        <div *ngIf="isVisaoMobile" class="col-md-6 modificar-assinatura mobile pl-3 pt-3 pr-3 pb-0 ml-3">
            <span class="d-flex titulo">Assinatura: <b>{{signatureType}}</b></span>
            <span (click)="modificarCertificado()" class="texto-modificar">Modificar tipo de assinatura</span>

            <div class="d-flex align-items-center">
                <app-checkbox style="margin-top: -13px;" [form]="formCheck"
                    (checkedEvent)="changeChecked($event)"></app-checkbox>

                <button [disabled]="papersSelected.length === 0" class="btn assinar-documento"
                    (click)="assinarDocumentos()"><i class="np-edit iconBody12 mr-1"></i>Assinar
                    documentos</button>
            </div>
        </div>

        <div class="col-md-6 col-lg-4" *ngFor="let paper of papers">
            <app-card-documents-lote [id]="paper.paperId" [signatureType]="paper.signatureType" [date]="paper.createdAt"
                [subject]="paper.subject" [event]="eventPaper" (checkboxEvent)="changeCheckedChildren($event)"
                (eventCard)="eventCardEmitter($event)">
            </app-card-documents-lote>
        </div>
    </div>

    <div *ngIf="!existePapers" class="d-flex flex-grow-1 justify-content-center flex-column registro-vazio mt-5 pt-5">
        <div class="d-flex justify-content-center">
            <img style="width: 223px; height: 179px" src="assets/images/empty.svg" alt="Nenhum documento" height="150">
        </div>
        <span class="d-flex justify-content-center wBody14 titulo pt-3">
            Nada por aqui ainda.
        </span>
        <span class="d-flex justify-content-center wBody14 sub-titulo pt-3">
            Nenhum documento foi descartado.
        </span>
    </div>

    <div *ngIf="signatureType && !isVisaoMobile" class="modificar-assinatura desktop-tablet">
        <span class="titulo desktop-tablet">Tipo de certificado: <b>{{signatureType}}</b> | </span>
        <span (click)="modificarCertificado()" class="texto-modificar">Modificar tipo de assinatura</span>
    </div>
</div>