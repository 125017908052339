import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IResponsibility } from '../../models/responsibility.interface';
import { ResponsibilityHttpService } from '../../services/responsibility/responsibility.http.service';
import { ToasterService } from '../toaster/toaster.service';

@Component({
  selector: 'app-modal-delete-responsibility',
  templateUrl: './modal-delete-responsibility.component.html',
  styleUrls: ['./modal-delete-responsibility.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalDeleteResponsibilityComponent implements OnInit {

  public responsibility: IResponsibility;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private responsibilityService: ResponsibilityHttpService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  public async deleteResponsibility(): Promise<void> {

    try {
      const resultDelete = await this.responsibilityService.delete(this.responsibility.responsibilityId);

      if (resultDelete.success === false) {
        this.toasterService.showError(resultDelete.message || 'Não foi possivel deletar a responsabilidade' );
        return;
      }

      this.closeModal(true);
    } catch (error) {
      this.toasterService.showError('Falha na exclução da responsabilidade!');
      throw new Error('Erro ao excluir responsabilidade! ' + error);
    }

  }

  public closeModal(result: boolean): void {
    this.ngbActiveModal.close(result);
  }

}
