import { environment } from '../../../../src/environments/environment';

export class UrlApiNpaper {

    private static baseUrl = environment.baseUrlAPi;

    public static LOGIN: string = `${UrlApiNpaper.baseUrl}/auth/login`;
    public static GET_DATA_USER: string = `${UrlApiNpaper.baseUrl}/auth/datauser`;
    public static PUT_DATA_USER: string = `${UrlApiNpaper.baseUrl}/auth/update`;
    public static FORGOT: string = `${UrlApiNpaper.baseUrl}/auth/forgot`;
    public static LOGIN_CHANGE_PASSWORD: string = `${UrlApiNpaper.baseUrl}/auth/password`;
    public static VERIFY_TOKEN: string = `${UrlApiNpaper.baseUrl}/auth/verify`;
    public static LOGIN_CERTIFICATE_CPF: string = `${UrlApiNpaper.baseUrl}/auth/logincertificate/:cpf`;
    public static LOGIN_CERTIFICATE_CPF_CNPJ: string = `${UrlApiNpaper.baseUrl}/auth/logincertificate/:cpf/:cnpj`;
    public static CREATE_ACCOUNT_CUSTOMER: string = `${UrlApiNpaper.baseUrl}/admin/customer`;
    public static CREATE_ACCOUNT_CUSTOMER_TESTE: string = `${UrlApiNpaper.baseUrl}/customer`;
    public static GET_CUSTOMERS: string = `${UrlApiNpaper.baseUrl}/admin/customer`;
    public static UPDATE_CUSTOMER: string = `${UrlApiNpaper.baseUrl}/admin/customer/:customerId`;
    public static SEND_VERIFICATION_EMAIL: string = `${UrlApiNpaper.baseUrl}/admin/customer/verify/:userId`;
    public static GET_CUSTOMER_SEARCH: string = `${UrlApiNpaper.baseUrl}/admin/customer/:search`;
    public static SEARCH_USER_BY_EMAIL: string = `${UrlApiNpaper.baseUrl}/user/admin/search/byEmail/:search`;
    public static CREATE_USER_ADMIN: string = `${UrlApiNpaper.baseUrl}/user/admin`;
    public static GET_ALL_USERS_ADMIN: string = `${UrlApiNpaper.baseUrl}/user/admin`;
    public static REMOVE_USER_ADMIN: string = `${UrlApiNpaper.baseUrl}/user/admin`;
    public static GET_ALL_USERS: string = `${UrlApiNpaper.baseUrl}/user`;
    public static UPDATE_USER: string = `${UrlApiNpaper.baseUrl}/user/:id`;
    public static CREATE_USER: string = `${UrlApiNpaper.baseUrl}/user`;
    public static DELETE_USER: string = `${UrlApiNpaper.baseUrl}/user/:id`;
    public static DELETE_USER_AND_TRANSFERENCE: string = `${UrlApiNpaper.baseUrl}/user/:idUser/:idUserToTransference`;
    public static GET_USER_SIGNATURE: string = `${UrlApiNpaper.baseUrl}/user/signature`;
    public static CREATE_RESPONSIBILITY: string = `${UrlApiNpaper.baseUrl}/responsibility`;
    public static GET_RESPONSIBILITY_BY_PAGE: string = `${UrlApiNpaper.baseUrl}/responsibility/getAllPagination`;
    public static UPDATE_RESPONSIBILITY: string = `${UrlApiNpaper.baseUrl}/responsibility`;
    public static DELETE_RESPONSIBILITY: string = `${UrlApiNpaper.baseUrl}/responsibility/:responsibilityId`;
    public static UPDATE_SUBSCRIBERS: string = `${UrlApiNpaper.baseUrl}/subscriber`;
    public static GET_SUBSCRIBERS_SEARCH: string = `${UrlApiNpaper.baseUrl}/subscriber/genericsearch`;
    public static SEND_NEW_DOCUMENTS: string = `${UrlApiNpaper.baseUrl}/papers`;
    public static COMPANY_SEARCH: string = `${UrlApiNpaper.baseUrl}/company/search/:search`;
    public static PAPERS: string = `${UrlApiNpaper.baseUrl}/papers`;
    public static PAPER_DOWNLOAD: string = `${UrlApiNpaper.baseUrl}/paper/lote/paperall/`;
    public static DOCUMENT: string = `${UrlApiNpaper.baseUrl}/document`;
    public static FILE: string = `${UrlApiNpaper.baseUrl}/file`;

    public static DASHBOARD: string = `${UrlApiNpaper.baseUrl}/dashboard`;
    public static EXTRACT: string = `${UrlApiNpaper.baseUrl}/useextract/`;
    public static GET_CICLO_REPORT_CUSTOMER_BY_ID: string = `${UrlApiNpaper.baseUrl}/admin/customer/signatures/:customerId`;
    public static SUBSCRIBERS: string = `${UrlApiNpaper.baseUrl}/subscriber`;
    public static SUBSCRIBERS_ID: string = `${UrlApiNpaper.baseUrl}/subscriber/byId`;
    public static REMOVE_SUBSCRIBERS: string = `${UrlApiNpaper.baseUrl}/subscriber`;
    public static GET_SUSBCRIBERS_BY_CNPJ_COMPANY = `${UrlApiNpaper.baseUrl}/subscriber/physical`;
    public static UPDATE_COMPANY: string = `${UrlApiNpaper.baseUrl}/company/`;
    public static COMPANY: string = `${UrlApiNpaper.baseUrl}/company`;
    public static GET_COMPANY: string = `${UrlApiNpaper.baseUrl}/company/specific`;
    public static UNLINK_COMPANY: string = `${UrlApiNpaper.baseUrl}/subscriber/company`;

    public static PAPERS_NOT_LOGGED: string = `${UrlApiNpaper.baseUrl}/papers/tosign/anonymous`;
    public static PAPER_NOT_LOGGED: string = `${UrlApiNpaper.baseUrl}/papers/anonymous`;
    public static PAPER_TAG: string = `${UrlApiNpaper.baseUrl}/tag`;

}
