import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-install-lacuna-web-pki',
  templateUrl: './modal-install-lacuna-web-pki.component.html',
  styleUrls: ['./modal-install-lacuna-web-pki.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalInstallLacunaWebPkiComponent implements OnInit {

  @Input() message: string = 'Para realizar está ação é preciso a extensão Web PKI em seu navegador. Ao clicar em instalar você será redirecionado para a tela com o passo a passo da instalação.  ';

  constructor(
    private modalController: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.modalController.close(true);
  }

  cancel(): void {
    this.modalController.close(false);
  }

}
