<div class="modal-edit-name modal-tall m-4 d-flex justify-content-around flex-column">
    <div class="app-modal-edit-name">
        <div>
            <div class="col-12 d-flex justify-content-end Heading16 icon-close-mobile" (click)="onCancel()">
                <i style="color: #B9C0C7; margin-top: -5px;" class="np np-close btn-icon-close"></i>
            </div>
        </div>
        <div>
            <div class="row mb-2">
                <div class="col-12 icon-edit-mobile">
                    <div class="col-md d-flex justify-content-center">
                        <app-avatar icon="np-edit-2" size="large"></app-avatar>
                    </div>
                    <span style="margin-top: 15px;" class="d-flex justify-content-center Heading20">Editar perfil</span>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 text-center wbody14">
                    <span class="d-flex justify-content-center">
                        <app-input style="width: 360px;"  class="width-input-mobile" label="" [inputControl]="formControlInputNome"
                            [labelErro]="'o nome'" placeholder="Digite o seu nome completo"></app-input>
                    </span>
                </div>
            </div>
        </div>
        <div class="row buttons-mobile">
            <div class="col-12 body14 d-flex justify-content-center">
                <button (click)="onCancel()" style="width: 119px; height: 34px;" class="btn btn-secondary mr-2">
                    <i class="np-error mr-2 iconBody18"></i>
                    Cancelar
                </button>
                <button [disabled]="!isFormValid()" (click)="editNameUser()" class="btn btn-primary"
                    style="width: 131px; height: 34px;">
                    <i style="font-size: 18px;" class="np-done mr-2"></i>
                    Sim, editar
                </button>
            </div>
        </div>
    </div>
</div>