import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { IUpdateUserDTO, IUser } from '../../../../shared/models/user.model';
import { ModalConfirmBlockerOrDeleteService } from '../modal-confirm-blocker-or-delete/modal-confirm-blocker-or-delete.service';
import { ModalUsersService } from './../modal-users/modal-users.service';


@Component({
  selector: 'app-card-users',
  templateUrl: './card-users.component.html',
  styleUrls: ['./card-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardUsersComponent implements OnInit {

  @Input() user: IUser;
  @Input() userIdAuth: number;
  @Output() eventDeleteUser: EventEmitter<IUser> = new EventEmitter<IUser>();

  constructor(
    private modalUserService: ModalUsersService,
    private modalConfirmService: ModalConfirmBlockerOrDeleteService,
    private userHttpService: UserService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    if (this.user === undefined) {
      throw new Error('Att user não informado no card');
    }
    if (this.userIdAuth === undefined) {
      throw new Error('Id do usuário autenticado não informado');
    }
  }

  public isEqualUserId(): boolean {
    return this.userIdAuth === this.user.id;
  }

  public async changeBlocked(): Promise<void> {
    if (this.isEqualUserId()) {
      this.toasterService.showInfo('Usuário não pode bloquear ou desbloquear a si mesmo.');
      return;
    }

    if (this.user.isBlocked === false) {
      if ((await this.modalConfirmService.openBlock(this.user)).confirm === true){
        await this.blockerUser(true);
      }
      return;
    }

    if ((await this.modalConfirmService.openUnblock(this.user)).confirm === true) {
      await this.blockerUser(false);
    }
  }

  public async onDelete(): Promise<void> {
    if (this.isEqualUserId()) {
      this.toasterService.showInfo('Usuário não pode deletar a si mesmo.');
      return;
    }

    const resultDeleteModalConfirm = await this.modalConfirmService.openDelete(this.user);

    if (resultDeleteModalConfirm.confirm === false || resultDeleteModalConfirm.idUserTransference === undefined) {
      return;
    }
    await this.deleteAndTransferenceUser(resultDeleteModalConfirm.idUserTransference);
  }

  public async onEditUser(): Promise<void> {
    const resultModalUser = await this.modalUserService.openEdit(this.user);
    if (resultModalUser === undefined) {
      return;
    }
    this.user = resultModalUser;
  }

  public blockerButton(): boolean {
    return this.isEqualUserId();
  }

  private async blockerUser(pBlockerUser: boolean): Promise<void> {
    this.user.isBlocked = pBlockerUser;
    try {
      const resultUpdateUser = await this.userHttpService.updateUser(this.factoryUserUpdate());

      if (resultUpdateUser.success === false) {
        this.toasterService.showError(resultUpdateUser.message || 'Não foi possível bloquear/desbloquear usuário');
        return;
      }
      this.toasterService.showSuccess(`Usuário ${pBlockerUser ? 'bloqueado' : 'desbloqueado'} com sucesso!`);
    } catch (err: any) {
      console.error(err);
      this.toasterService.showError(err.error.message || 'Falha ao bloquear/desbloquear usuário');
    }
  }

  private async deleteAndTransferenceUser(idUserTransfer: number): Promise<void> {

    if (this.user.id === undefined) {
      this.toasterService.showInfo('Usuário não informado para ser deletado');
      return;
    }

    try {
      const resultDeleteTransfer = await this.userHttpService.deleteUserAndTransferenceToOuterUser(this.user.id, idUserTransfer);

      if (resultDeleteTransfer.success === false) {
        this.toasterService.showInfo(resultDeleteTransfer.message || 'Usuário não pode ser deletado!');
        return;
      }
      this.eventDeleteUser.emit(this.user);
      this.toasterService.showSuccess('Usuário excluído com sucesso!');
    } catch (err: any) {
      console.error(err);
      this.toasterService.showInfo(err.error.message || 'Falha ao deletar usuário!');
    }
  }

  public factoryUserUpdate(): IUpdateUserDTO {
    if (this.user === undefined || this.user.id === undefined) {
      this.toasterService.showInfo('Usuário não informado para atualização.');
      throw new Error('Usuário não informado');
    }
    return {
      customerId: this.user.customerId,
      email: this.user.email,
      identification: this.user.cpf,
      isBlocked: this.user.isBlocked,
      isPrimary: this.user.isPrimary,
      name: this.user.name,
      userId: this.user.id,
      viewAllDocs: this.user.viewAllDocs
    };
  }

}
