import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewPasswordComponent } from './views/new-password.component';
import { NewPasswordRoutingModule } from './new-password-routing.module';
import { FormNewPasswordComponent } from './components/form-new-password/form-new-password.component';

@NgModule({
  declarations: [
    NewPasswordComponent,
    FormNewPasswordComponent,


  ],
  imports: [
    CommonModule,
    NewPasswordRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class NewPasswordModule { }