<nav class="nav-bar d-flex align-items-center w-100">
  <div *ngIf="isVisaoTablet && !isVisaoMobile" class="brand-2" style="margin-right: 100px">
    <i [ngClass]="{'np-menu-trio': !menuAberto, 'np-close-doc': menuAberto}" class="menu-icon mr-2" id="sidebar"
      (click)="openMenuVertical(content)">

      <img style="margin-left: 24px" src="../../../../assets/images/npaperLogoNav.svg " alt="Logo Npaper" />
    </i>
  </div>

  <div *ngIf="!isVisaoTablet" class="col-lg-3 brand">
    <div class="m-auto">
      <img src="../../../../assets/images/npaperLogoNav.svg " alt="Logo Npaper" />
    </div>
  </div>

  <div [ngStyle]="{'justify-content': isVisaoMobile ? 'end' : 'space-between'}" class="containerNav w-100">
    <div *ngIf="!isVisaoMobile">
      <button style="height: 34px" class="btn btn-secondary-yellow size-btn-tablet" [routerLink]="['/auth/new-documents']">
        <i class="np-plus iconBody18 mr-2"></i>
        Novo documento
      </button>
    </div>

    <div class="buttons d-flex align-items-center justify-content-between ml-1">
      <span *ngIf="userNavigate.accountType !== AccountTypeName.MonthlyPayment" class="assinatura-restante color-nav">
        <div>
          <span *ngIf="userNavigate.accountType === AccountTypeName.Test" class="d-flex bold">Conta teste:
            {{userNavigate.testPeriod}} dias</span>
          <span *ngIf="userNavigate.accountType === AccountTypeName.PrePaid" class="d-flex bold">Pré-paga</span>
          <span class="normal">Assinaturas restantes: </span><span
            class="bold">{{userNavigate.signatureQtd}}/{{userNavigate.signaturePlan}}</span>
        </div>
      </span>

      <div style="margin-left: 18px;">
        <div ngbDropdown #dropdownNotification="ngbDropdown" class="notifications" placement="bottom-right">
          <i ngbDropdownToggle class="np-notifications-1 icon icone-logout" id="dropdownNotification" placement="bottom"
            ngbTooltip="Notificações" tooltipClass="btn-tooltip">
            <span [ngClass]="{ 'notification-bubble': notifications.length > 0 }"></span>
          </i>
          <div ngbDropdownMenu aria-labelledby="dropdownNotification" class="notification-dropdown-menu p-0 mt-3">
            <div class="
                header-label-dropdown
                d-flex
                align-items-center
                justify-content-between
              ">
              <span class="Heading16">Notificações ({{ notifications.length }})</span>
              <i class="np-close btn-icon-close" (click)="dropdownNotification.close()"></i>
            </div>
            <div class="notification-content pr-1" (scroll)="onScroll($event)">
              <div *ngIf="notifications.length > 0; else noNotificationContent">
                <div class="d-flex align-items-center mt-2" *ngFor="let notification of notifications">
                  <app-avatar class="mx-3" icon="np-user-folder" size="medium" color="primary"></app-avatar>
                  <div class="notification-text d-flex flex-column">
                    <span class="wBody14">{{ notification.subject }}</span>
                    <span class="wBody12 d-block">{{
                      notification.date | date: 'dd/MM/YYYY hh:MM'
                      }}</span>
                  </div>
                </div>
              </div>
              <ng-template #noNotificationContent>
                <div class="
                    no-notification-content
                    d-flex
                    align-items-center
                    justify-content-center
                    flex-column
                  ">
                  <img src="../../../../assets/images/tagEmpty.svg" alt="empty" class="mb-4" />
                  <span class="d-block Heading16">Nada por aqui ainda.</span>
                  <span class="d-block wBody14">Nenhuma notificação recebida.</span>
                </div>
              </ng-template>
            </div>
            <div [ngClass]="{ 'linear-gradient': isGradientScroll }"></div>
          </div>
        </div>

      </div>

      <i class="np-sign-out icon icone-logout icon-tablet-view" ngbTooltip="Sair" tooltipClass="btn-tooltip"
        (click)="logout()">
      </i>


      <div *ngIf="isVisaoTablet || isVisaoMobile" ngbDropdown #dropdownUser="ngbDropdown" class="dropdown-user"
        placement="bottom-right">
        <button style="height: 37px;" *ngIf="isVisaoTablet" type="button" class="
        dropdown-toggle
        d-flex
        justify-content-between
        align-items-center
        mx-auto
        dropdown-user-button
        body14 nav-color
      " id="dropdownUser" ngbDropdownToggle>
          <i class="np-user iconBody18 mr-2"></i>
          <span class="mr-2">{{userAuth?.name}}</span>
          <i class="iconBody18" [ngClass]="dropdownUser.isOpen() | angleUpDown"></i>
        </button>

        <button style="height: 34px; width: 60px; padding-left: 8px;" *ngIf="isVisaoMobile" type="button" class="
        dropdown-toggle
        d-flex
        justify-content-between
        align-items-center
        mx-auto
        dropdown-user-button
        body14 nav-color
      " id="dropdownUser" ngbDropdownToggle>
          <i class="np-user iconBody18 mr-2"></i>
          <i class="iconBody18" [ngClass]="dropdownUser.isOpen() | angleUpDown"></i>
        </button>

        <div ngbDropdownMenu aria-labelledby="dropdownManual" class="user-dropdown-menu p-0 mt-3">
          <div class="user-menu-options" backdrop="static">
            <div class="header">
              <div class="content-profile Heading20">
                <span class="d-flex justify-content-center mx-auto mb-2 avatar">
                  <app-avatar [size]="'small'" name="{{userAuth?.name}}" color="secondary"></app-avatar>
                </span>
                <div class="customer-name-perfil">
                  <span class="header-text">{{userAuth?.name}}</span>
                  <i class="ml-2 np-edit-2" (click)="editName(userId)"></i>
                </div>

                <div ngbDropdown placement="bottom" class="w-100 mt-1 dropdown-user" #dropUser="ngbDropdown">
                  <button type="button" class="
                                  dropdown-toggle
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  mx-auto
                                  dropdown-user-button
                                  body14 user-color
                                " id="dropdownUser" ngbDropdownToggle>
                    <span class="mr-2">{{customerSelecionado}}</span>
                    <i class="iconBody18" [ngClass]="dropUser.isOpen() | angleUpDown"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownUser" class="dropdown-user-content">
                    <span class="body14 d-block mb-1 p-1">Mudar para a conta</span>
                    <button (click)="trocarUser(i)" ngbDropdownItem class="itemDropdownUser" *ngFor="let customer of userAuth?.customers; let i=index">
                      <span>{{customer.name}}</span>
                      <i *ngIf="customer.name === customerSelecionado" class="ml-2 np-done"></i>
                    </button>
                  </div>
                </div>

                <span *ngIf="userNavigate.accountType !== AccountTypeName.MonthlyPayment"
                  class="assinatura-restante color-user">
                  <span>
                    <span *ngIf="userNavigate.accountType === AccountTypeName.Test"
                      style="display: block; line-height: 0px;" class="bold">Conta teste:
                      {{userNavigate.testPeriod}} dias</span>
                    <span *ngIf="userNavigate.accountType === AccountTypeName.PrePaid"
                      style="display: block; line-height: 0px;" class="bold">Pré-paga</span>
                    <span class="normal">Assinaturas restantes: </span><span
                      class="bold">{{userNavigate.signatureQtd}}/{{userNavigate.signaturePlan}}</span>
                  </span>
                </span>
              </div>
            </div>

            <div class="d-flex justify-content-between fundo" (click)="navegar('configuracoes'); dropdownUser.close()">
              <div class="d-flex align-items-center bloco-left">
                <i class="iconBody22 np-settings-2 bloco-right">
                </i>
                <span class="texto">Configurações</span>
              </div>

              <div>
                <i class="np-angle-right iconBody18 icon-right"></i>
              </div>
            </div>

            <div class="d-flex justify-content-between fundo" (click)="navegar('assinatura'); dropdownUser.close()">
              <div class="d-flex align-items-center bloco-left">
                <i class="np-edit iconBody26 bloco-right">
                </i>
                <span class="texto">Assinatura</span>
              </div>

              <div>
                <i class="np-angle-right iconBody18 icon-right"></i>
              </div>
            </div>

            <div class="d-flex justify-content-between fundo" (click)="navegar('assinantes'); dropdownUser.close()">
              <div class="d-flex align-items-center bloco-left">
                <i class="iconBody22 np-add-user bloco-right">
                </i>
                <span class="texto">Assinantes</span>
              </div>

              <div>
                <i class="np-angle-right iconBody18 icon-right"></i>
              </div>
            </div>

            <div class="d-flex justify-content-between fundo" (click)="navegar('usuarios'); dropdownUser.close()">
              <div class="d-flex align-items-center bloco-left">
                <i class="iconBody22 np-group bloco-right">
                </i>
                <span class="texto">Usuários</span>
              </div>

              <div>
                <i class="np-angle-right iconBody18 icon-right"></i>
              </div>
            </div>

            <div class="d-flex justify-content-between fundo" (click)="logout()">
              <div class="d-flex align-items-center bloco-left">
                <i class="np-sign-out icone-logout bloco-right">
                </i>
                <span class="texto">Sair</span>
              </div>

              <div>
                <i class="np-angle-right iconBody18 icon-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Modal -->
<ng-template #content let-modal>
  <div class="modal-body">
    <div class="sidebar">
      <div style="margin-top: 64px" class="button-profile">
        <button type="button" class="button-profile-items" (click)="closeMenuVertical(); modal.dismiss('Cross click')"
          [routerLink]="['enviados']" routerLinkActive="router-button-active">
          <i class="np-outgoing-mail iconBody26"></i><span class="Heading16">Enviados</span>
        </button>
        <button type="button" class="button-profile-items" (click)="closeMenuVertical(); modal.dismiss('Cross click')"
          [routerLink]="['descartados']" routerLinkActive="router-button-active">
          <i class="np-trash iconBody26"></i><span class="Heading16">Descartados</span>
        </button>
        <button type="button" class="button-profile-items" (click)="closeMenuVertical(); modal.dismiss('Cross click')"
          [routerLink]="['pastas']" routerLinkActive="router-button-active">
          <i class="np-folder iconBody26"></i><span class="Heading16">Pastas</span>
        </button>
        <button type="button" class="button-profile-items" (click)="closeMenuVertical(); modal.dismiss('Cross click')"
          [routerLink]="['assinar-em-lote']" routerLinkActive="router-button-active">
          <i class="np-edit iconBody26"></i><span class="Heading16">Assinar em lote</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>