<div class="modal-folder m-4">
  <div class="header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <app-avatar [icon]="viewModal.icon" color="primary" size="small"></app-avatar>
      <span class="ml-3 Heading20">{{ viewModal.title }}</span>
    </div>
    <i class="np np-close btn-icon-close" (click)="onCancel()"></i>
  </div>

  <div
    *ngIf="viewModal.view == 'addFolder' || viewModal.view == 'editNameFolder'"
  >
    <app-add-folder-and-edit-name-folder
      [addFolderAndEditNameFolder]="viewModal"
    >
    </app-add-folder-and-edit-name-folder>
  </div>
  <div
    *ngIf="viewModal.view == 'moveDocument' || viewModal.view == 'addDocument'"
  >
    <app-move-document [viewMoveDocument]="viewModal"> </app-move-document>
  </div>

  <div *ngIf="viewModal.view == 'shareFolder'">
    <app-shared-folder [viewSharedFolder]="viewModal"> </app-shared-folder>
  </div>
</div>
