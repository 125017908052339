import { IPaperKanban, ITag } from './../../models/paper.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValueCheckdEvent } from '../../models/BehaviorCheckboxRadioInput';
import { IEventCard, IEventKanban } from '../../models/Event-card.model';
import { TypeEventCardEnum, TypeEventKanbanEnum } from '../../enum/event-card.enum';
import { TagsModel } from '../../models/tagsModel';
import { TagService } from '../tag/tag.service';
import { Tag } from '../../models/tag';

@Component({
  selector: 'app-kanban-documents-field',
  templateUrl: './kanban-documents-field.component.html',
  styleUrls: ['./kanban-documents-field.component.scss']
})
export class KanbanDocumentsFieldComponent implements OnInit {
  public formCheck: FormControl;
  @Input() index: number;
  @Input() public title: string;
  @Input() public color: string;
  @Input() public colorDark: string;
  @Input() public isShippable: boolean = true;
  @Input() public papers: IPaperKanban[] = [];
  @Input() public imageEmpty: string = '';
  @Input() public messageEmpty: string = '';
  @Input() chamador: 'area-logged' | 'area-not-logged';
  @Input() situacaoPaper: 'enviados' | 'descartados';
  public papersSelected: number[] = [];
  public papersDiscarded: number[] = [];
  public eventPaper = new EventEmitter<ValueCheckdEvent>();
  public currentTags: TagsModel[];
  @Input() public tags: Tag[];
  public tagsSelected: ITag[] = [];
  @Output() public eventCard = new EventEmitter<IEventCard>();
  @Output() public eventKanban = new EventEmitter<IEventKanban>();

  public OCULTAR_BOTAO_MARCADORES: boolean = false;

  constructor(
    private tagService: TagService,
  ) {
    this.formCheck = new FormControl(false);
  }

  ngOnInit(): void {

  }

  public changeChecked(values: ValueCheckdEvent): void {
    this.formCheck.setValue(values.value);
    this.eventPaper.emit(values);
  }

  public changeCheckedChildren(bodyChildren: { id: number, values: ValueCheckdEvent }): void {
    if (bodyChildren.values.value) {
      this.papersSelected = [...this.papersSelected, bodyChildren.id];
      this.papersSelected = this.papersSelected.filter((id, index) => this.papersSelected.indexOf(id) === index);
    } else {
      this.papersSelected = this.papersSelected.filter(value => value !== bodyChildren.id);
    }

    this.formCheck.setValue(this.papersSelected.length === this.papers.length);
  }

  public isPapersEmpty(): boolean {
    return this.papers.length === 0;
  }

  public isPapersSelectEmpty(): boolean {
    return this.papersSelected.length === 0;
  }

  public send(): void {
    this.eventKanban.emit({ typeEvent: TypeEventKanbanEnum.SEND, papers: this.papersSelected });
  }
  public trash(): void {
    this.eventKanban.emit({ typeEvent: TypeEventKanbanEnum.TRASH, papers: this.papersSelected });
  }
  public tag(): void {
    this.eventKanban.emit({ typeEvent: TypeEventKanbanEnum.TAG, papers: this.papersSelected });
  }

  public eventsCard(event: IEventCard): void {
    this.eventCard.emit(event);
  }

  public salvarTags(): void {
    this.onTagClick();
    this.tagsSelected = [];
  }

  public onTagClick(): void {
    this.eventKanban.emit({ typeEvent: TypeEventKanbanEnum.TAG, papers: this.papersSelected, tagsSelected: this.tagsSelected });
  }

  public isCheckedItem(itemChecked: boolean | undefined, index: number): void {
    if (itemChecked) {
      this.currentTags[index].isChecked = true;
      this.tagsSelected.push({ tagId: this.currentTags[index].tagId });
    } else {
      this.currentTags[index].isChecked = false;

      const indexOf = this.tagsSelected.findIndex(field => field.tagId === this.currentTags[index].tagId);

      this.tagsSelected.splice(indexOf, 1);
    }
  }

  public async carregarTags(): Promise<void> {
    try {
      this.currentTags = await this.tagService.getTags();

      this.currentTags.forEach((item) => {
        item.isChecked = false;
      });

      /** Se tiver tag já marcada no paper */
      if (this.tags.length > 0) {
        for (let indexY = 0; indexY < this.currentTags.length; indexY++) {
          const element = this.currentTags[indexY];

          for (let index = 0; index < this.tags.length; index++) {
            const tagId = this.tags[index].id;

            if (element.tagId === tagId) {
              this.isCheckedItem(!element.isChecked, indexY);
            }
          }
        }
      }
    } catch (error) {

    }
  }
}
