<style>
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .color-whrite {
    color: #fff;
  }

  .spacer {
    flex: 1;
  }

  .toolbar {
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #00229a;
    color: white;
    font-weight: 600;
    margin-top: 45px;
  }

  .toolbar img {
    margin: 0 16px;
  }

  .toolbar #twitter-logo {
    height: 40px;
    margin: 0 16px;
  }

  .toolbar #twitter-logo:hover {
    opacity: 0.8;
  }

  .content {
    display: flex;
    margin: 0px auto 32px;
    padding: 0 16px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  svg.material-icons {
    height: 24px;
    width: auto;
  }

  svg.material-icons:not(:last-child) {
    margin-right: 8px;
  }

  .card svg.material-icons path {
    fill: #888;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
  }

  .card {
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 40px;
    width: 200px;
    margin: 0 8px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
  }

  .card-container .card:not(:last-child) {
    margin-right: 0;
  }

  .card.card-small {
    height: 16px;
    width: 168px;
  }

  .card-container .card:not(.highlight-card) {
    cursor: pointer;
  }

  .card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }

  .card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card.highlight-card {
    background-color: #1976d2;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
  }

  .card.card.highlight-card span {
    margin-left: 60px;
  }

  svg#rocket {
    width: 80px;
    position: absolute;
    left: -10px;
    top: -24px;
  }

  svg#rocket-smoke {
    height: calc(100vh - 95px);
    position: absolute;
    top: 10px;
    right: 180px;
    z-index: -10;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  .terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
  }

  .terminal::before {
    content: '\2022 \2022 \2022';
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
  }

  .terminal pre {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
  }

  .circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
  }

  .circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

  footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  .github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27, 31, 35, 0.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }

  .github-star-badge:hover {
    background-image: linear-gradient(-180deg, #f0f3f6, #e6ebf1 90%);
    border-color: rgba(27, 31, 35, 0.35);
    background-position: -0.5em;
  }

  .github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .card-container>*:not(.circle-link),
    .terminal {
      width: 100%;
    }

    .card:not(.highlight-card) {
      height: 16px;
      margin: 8px 0;
    }

    .card.highlight-card span {
      margin-left: 72px;
    }

    svg#rocket-smoke {
      right: 120px;
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 575px) {
    svg#rocket-smoke {
      display: none;
      visibility: hidden;
    }
  }
</style>

<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img alt="Angular Logo" src="./../../assets/images/npaperLogoNav.svg" />

  <div class="spacer Heading24 text-center color-whrite">Guia de estilo</div>
</div>


<div class="content scroll-content-page" role="main" style="background-color: #fff;">
  <div class="container">
    <div>
      <h1>Componente de digitar de assinatura</h1>
      <app-assinatura-digitar #assinarDigitar></app-assinatura-digitar>
      <div>
        <button class="btn btn-primary" (click)="getAssinaturaDigitarData()">
          Dados da assinatura
        </button>
      </div>
      <hr>
    </div>
    <div>
      <h1>Componente de desenho de assinatura</h1>
      <app-assinatura-desenho #assinarDesenho></app-assinatura-desenho>
      <div>
        <button class="btn btn-primary" (click)="getAssinaturaDesenharData()">
          Dados da assinatura
        </button>
      </div>
      <hr>
    </div>

    <div class="row justify-content-between">
      <div class="col-12 mb-3">
        <h1>Tipografia</h1>
        <hr />
        <h1 class="Heading46">H1</h1>
        <h2 class="Heading38">H2</h2>
        <h3 class="Heading30">H3</h3>
        <h4 class="Heading24">H4</h4>
        <h5 class="Heading20">H5</h5>
        <h6 class="Heading16">H6</h6>
      </div>
    </div>
    <hr />

    <h1>Badges</h1>
    <div class="row mt-4">
      <div class="col-12">
        <span class="badge badge-primary m-1">Badge primary</span>
        <span class="badge badge-primary m-1">
          Badge remove <i class="np-error badge-remove"></i></span>
        <span class="badge badge-primary m-1">
          <i class="np-clock badge-icon"></i>
          Badge primary with icon
        </span>
        <span class="badge badge-primary m-1">
          <i class="np-clock badge-icon"></i>
          Badge with icon and remove
          <i class="np-error badge-remove"></i>
        </span>
      </div>
    </div>
    <hr />

    <h1>Form text</h1>

    <div>
      <span class="Heading24">Email</span>
      <div class="inner-addon right-addon">
        <app-input [inputControl]="inputFormControl" label='Email' [labelErro]="'o email'" placeholder="Email"
          [isRequired]="true"></app-input>
      </div>
    </div>

    <div class="mt-3">
      <span class="Heading24">Default</span>
      <div class="inner-addon right-addon">
        <app-input [inputControl]="inputFormControlDefault" label='Default' [labelErro]="'o default'"
          placeholder="Default" [isRequired]="false"></app-input>
      </div>
    </div>

    <div class="mt-3">
      <span class="Heading24">Disabled</span>
      <div class="inner-addon right-addon">
        <app-input [inputControl]="inputFormControlDisabled" label='Disabled' disabled="false"
          [labelErro]="'o disabled'" placeholder="Disabled" [isRequired]="true"></app-input>
      </div>
    </div>

    <div class="mt-3">
      <span class="Heading24">Success</span>
      <div class="inner-addon right-addon">
        <app-input [inputControl]="inputFormControlSuccess" label='Success' [labelErro]="'o success'" placeholder="Success"
          [isRequired]="true" aria-describedby="icon-input"></app-input>
      </div>
    </div>
    <div class="mt-3">
      <span class="Heading24">Error</span>
      <div class="inner-addon right-addon">
        <app-input [inputControl]="inputFormControlError" label='Error' aria-invalid="true" [labelErro]="'o Error'"
          placeholder="Error" [isRequired]="true" aria-describedby="icon-input"></app-input>
      </div>
    </div>
    <hr />
    <h1>Data Picker</h1>
    <div class="row">
      <div class="col-4">
        <app-data-picker [label]="'DataPicker default'" [placeholder]="'Selecione uma data'" [id]="'dataPickerDefault'"
          [control]="dataPickerControlNormal"></app-data-picker>
      </div>
      <div class="col-4">
        <app-data-picker [label]="'DataPicker Inicializado'" [placeholder]="'Selecione uma data'"
          [id]="'dataPickerInicializado'" [control]="dataPickerControlInit"></app-data-picker>
      </div>
      <div class="col-4">
        <app-data-picker [label]="'DataPicker required'" [placeholder]="'Selecione uma data'"
          [id]="'dataPickerRequired'" [isRequired]="true" [control]="dataPickerControlRequired"></app-data-picker>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-4">
        <app-data-picker [label]="'DataPicker Error'" [placeholder]="'Selecione uma data'" [id]="'dataPickerDefault'"
          [labelErro]="'a data erro.'" [control]="dataPickerControlError"></app-data-picker>
      </div>
      <div class="col-4">
        <app-data-picker [label]="'DataPicker Disabled'" [placeholder]="'Selecione uma data'"
          [id]="'dataPickerDisabled'" [labelErro]="'a data erro.'" [control]="dataPickerControlDisabled">
        </app-data-picker>
      </div>
      <div class="col-4">
        <app-data-picker [label]="'DataPicker Disabled com valor'" [placeholder]="'Selecione uma data'"
          [id]="'dataPickerDisabled'" [labelErro]="'a data erro.'" [control]="dataPickerControlDisabledValue">
        </app-data-picker>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-data-picker [label]="'DataPicker Full Row'" [placeholder]="'Selecione uma data'" [id]="'dataPickerDefault'"
          [labelErro]="'a data erro.'" [control]="dataPickerControlFullRow"></app-data-picker>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-data-picker [label]="'DataPicker OutputEvent'" [placeholder]="'Selecione uma data'"
          [id]="'dataPickerOutput'" [labelErro]="'a data erro.'" [control]="dataPickerControlFullRow"
          (valueChangeEvent)="dataPickerOutput($event)"></app-data-picker>
      </div>
    </div>
    <hr />
    <h1>Form input</h1>
    <div class="row mt-3">
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <button type="button" class="input dropdown-toggle d-flex align-items-center" id="dropdownInput"
            ngbDropdownToggle>
            <span class="wBody14 flex-grow-1">input select</span>
            <i class="np-angle-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownInput" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <div class="
              inner-addon
              left-addon
              d-flex
              align-items-center
              two-addon-in-same-input
            ">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14" placeholder="Input select"
              aria-describedby="icon-input" ngbDropdownToggle />
            <i class="np np-angle-down"></i>
          </div>

          <div ngbDropdownMenu aria-labelledby="inputSearch" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <div class="inner-addon right-addon d-flex align-items-center">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14" placeholder="Input select"
              aria-describedby="icon-input" ngbDropdownToggle />
          </div>

          <div ngbDropdownMenu aria-labelledby="inputSearch" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <button type="button" class="input dropdown-toggle d-flex align-items-center" id="dropdownInput"
            ngbDropdownToggle disabled>
            <span class="wBody14 flex-grow-1">input select</span>
            <i class="np-angle-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownInput" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <div class="inner-addon left-addon d-flex align-items-center">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14" placeholder="Input select"
              aria-describedby="icon-input" ngbDropdownToggle disabled />
          </div>

          <div ngbDropdownMenu aria-labelledby="inputSearch" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <div class="inner-addon right-addon d-flex align-items-center">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14 test" placeholder="Input select"
              aria-describedby="icon-input" ngbDropdownToggle disabled />
          </div>

          <div ngbDropdownMenu aria-labelledby="inputSearch" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <button type="button" class="input dropdown-toggle d-flex align-items-center" id="dropdownInput"
            ngbDropdownToggle>
            <span class="wBody14 flex-grow-1">input select</span>
            <i class="np-angle-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownInput" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <div class="inner-addon left-addon d-flex align-items-center">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14" placeholder="Input select"
              aria-describedby="icon-input" ngbDropdownToggle />
          </div>

          <div ngbDropdownMenu aria-labelledby="inputSearch" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <div class="inner-addon right-addon d-flex align-items-center">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14" placeholder="Input select"
              aria-describedby="icon-input" ngbDropdownToggle />
          </div>

          <div ngbDropdownMenu aria-labelledby="inputSearch" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <button type="button" class="
              input
              dropdown-toggle
              d-flex
              align-items-center
              justify-content-between
            " id="dropdownInput" ngbDropdownToggle>
            <span class="wBody14">input select</span>
            <i class="np-angle-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownInput" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div ngbDropdown placement="bottom" class="w-100">
          <button type="button" class="
              input
              dropdown-toggle
              d-flex
              align-items-center
              justify-content-center
            " id="dropdownInput" ngbDropdownToggle>
            <span class="wBody14">input select</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownInput" class="p-1 w-100">
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Action - 1</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Another Action</span>
            </button>
            <button ngbDropdownItem class="pl-2 d-flex justify-content-between">
              <span>Something else is here</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row mt-4 align-items-center justify-content-end">
      <div class="col-5">
        <div class="input-checkbox">
          <input class="mr-2" type="checkbox" id="exampleCheck1" />
          <label for="exampleCheck1">Checkbox default</label>
        </div>
        <div class="input-checkbox">
          <input type="checkbox" value="" checked id="exampleCheck2" />
          <label for="exampleCheck2">Checkbox select</label>
        </div>
        <div class="input-checkbox">
          <input type="checkbox" value="" disabled checked id="exampleCheck3" />
          <label for="exampleCheck3">Checkbox select disabled</label>
        </div>
        <div class="input-checkbox not-checked-disable">
          <input type="checkbox" value="" disabled id="exampleCheck4" />
          <label for="exampleCheck4">Checkbox default disabled</label>
        </div>
      </div>
      <div class="col-5">
        <div class="input-radio">
          <input type="radio" name="inlineRadioOptions" id="exampleRadio1" value="exampleRadio1" />
          <label for="exampleRadio1">Radio default</label>
        </div>

        <div class="input-radio">
          <input type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked />
          <label for="inlineRadio1">Radio select</label>
        </div>

        <div class="input-radio">
          <input type="radio" name="inlineRadioOptions3" checked disabled id="inlineRadio1" value="option3" />
          <label for="inlineRadio3">Radio select disabled</label>
        </div>
        <div class="input-radio not-checked-disable">
          <input type="radio" name="inlineRadioOptions3" id="inlineRadio1" value="option3" />
          <label for="inlineRadio3">Radio default disabled</label>
        </div>
      </div>
      <div class="col-5">
        <h6>Ex: Radio Component</h6>
        <a label style="font-style: italic; color: red;">Empty label</a>
        <app-radio [form]="formGroupRadioControlEmptyLabel" group="form6" value="5"></app-radio>
        <app-radio [form]="formGroupRadioControlDefault" group="form1" value="1" label="Radio default"></app-radio>
        <app-radio [form]="formGroupRadioControlSelect" group="form2" value="2" label="Radio select" checked>
        </app-radio>
        <app-radio [form]="formGroupRadioControlSelectDisabled" group="form3" value="3" label="Radio select disabled">
        </app-radio>
        <app-radio [form]="formGroupRadioControlDisabled" group="form4" value="4" label="Radio default disabled">
        </app-radio>
        <app-radio [form]="formGroupRadioControlCustomizedLabel" group="form5" value="5"><a label
            style="font-style: italic;">Radio Customized label</a></app-radio>
      </div>
      <div class="col-5">
        <h6>Ex: Radio Group</h6>
        <p>Alternativa selecionada: {{formGroupRadioControlAlternative.value}}</p>
        <app-radio group="teste" [form]="formGroupRadioControlAlternative" label="Alternative A" value="A"></app-radio>
        <app-radio group="teste" [form]="formGroupRadioControlAlternative" label="Alternative B" value="B"></app-radio>
        <app-radio group="teste" [form]="formGroupRadioControlAlternative" label="Alternative C" value="C"
          (checkedEvent)="showToasterInfo('alternativa ' + $event.value + ' selecionada')"></app-radio>
        <app-radio group="teste" [form]="formGroupRadioControlAlternative" label="Alternative D" value="D"></app-radio>
        <app-radio group="teste" [form]="formGroupRadioControlAlternative" label="Alternative E" value="E"></app-radio>
      </div>
      <div class="col-10">
        <h6>Ex: Checkbox Component</h6>
        <a label style="font-style: italic;  color: red;">Empty label</a>
        <app-checkbox [form]="formControlCheckEmptyLabel"></app-checkbox>
        <app-checkbox label="Checkbox default" [form]="formControlCheckDefault" ></app-checkbox>
        <app-checkbox label="Checkbox select" [form]="formControlCheckSelect"></app-checkbox>
        <app-checkbox label="Checkbox select disabled" [form]="formControlCheckSelectDesabled" ></app-checkbox>
        <app-checkbox label="Checkbox default disabled" [form]="formControlCheckDesabled" ></app-checkbox>
        <app-checkbox [form]="formControlCustomizedLabel">
          <a style="font-style: italic;" label>Checkbox customized label component</a>
        </app-checkbox>
        <app-checkbox [label]="'Checkbox on event value: ' + (checkboxValueEvent ? 'check' : 'No check')"
          (checkedEvent)="onEventCheckbox($event)" [form]="formControlCheckDefault" value="7"></app-checkbox>
      </div>
      <div class="col-5">
        <div class="switch__container d-flex">
          <input id="switch-shadow" class="switch switch--shadow" type="checkbox" />
          <label for="switch-shadow"></label> <span>Switch off</span>
        </div>
        <div class="switch__container d-flex">
          <input id="switch-shadow1" checked class="switch switch--shadow" type="checkbox" />
          <label for="switch-shadow1"></label> <span>Switch on</span>
        </div>
      </div>
      <div class="col-5">
        <h6>Ex: Component Switch</h6>
        <a label style="font-style: italic;  color: red;">Empty label</a>
        <app-switch [form]="formControlSwitchEmptyLabel"></app-switch>
        <app-switch [form]="formControlSwitchOff" label="Switch default"></app-switch>
        <app-switch [form]="formControlSwitchOn" label="Switch On"></app-switch>
        <app-switch [form]="formControlSwitchOffDesabled" label="Switch disabled"></app-switch>
        <app-switch [form]="formControlSwitchOnDesabled" label="Switch On disabled"></app-switch>
        <app-switch [form]="formControlSwitchCustumizedLabel">
          <a label style="color: red;">Switch customized label component</a>
        </app-switch>
        <app-switch [form]="formControlSwitchEvent" (checkedEvent)="onEventSwitch($event)"
          [label]="'Switch Event: '+ (switchValueEvent ? 'on' : 'off')"></app-switch>
      </div>
    </div>
    <hr />

    <h1>Alerts</h1>
    <div class="alert mb-3 alert-success" role="alert">
      <div class="
          field-alert
          d-flex
          align-items-center
          justify-content-between
          body14
        ">
        <div class="d-flex align-items-center">
          <i class="icon-alert np-done-line iconBody18"></i>
          <span>Pronto. Texto movido para destaque.</span>
        </div>
        <button class="close">
          <i class="np-close iconBody18"></i>
        </button>
      </div>
      <div class="progress-alert">
        <div class="w-25"></div>
      </div>
    </div>
    <div class="alert mb-3 alert-info" role="alert">
      <div class="
          field-alert
          d-flex
          align-items-center
          justify-content-between
          body14
        ">
        <div class="d-flex align-items-center">
          <i class="icon-alert np-info iconBody18"></i>
          <span>Info. Texto movido para destaque.</span>
        </div>
        <button class="close">
          <i class="np-close iconBody18"></i>
        </button>
      </div>
      <div class="progress-alert">
        <div class="w-25"></div>
      </div>
    </div>
    <div class="alert mb-3 alert-danger" role="alert">
      <div class="
          field-alert
          d-flex
          align-items-center
          justify-content-between
          body14
        ">
        <div class="d-flex align-items-center">
          <i class="icon-alert np-warning-2 iconBody18"></i>
          <span>Aviso.Texto movido para destaque.</span>
        </div>
        <button class="close">
          <i class="np-close iconBody18"></i>
        </button>
      </div>
      <div class="progress-alert">
        <div class="w-25"></div>
      </div>
    </div>
    <div class="d-flex justify-content-evenly">
      <div class="me-auto p-2">
        <button (click)="showToasterSuccess()" class="btn btn-primary">
          Ex: success
        </button>
      </div>
      <div class="me-auto p-2">
        <button (click)="showToasterInfo()" class="btn btn-primary">
          Ex: info
        </button>
      </div>
      <div class="me-auto p-2">
        <button (click)="showToasterDanger()" class="btn btn-primary">
          Ex: danger
        </button>
      </div>
    </div>
    <hr />
    <h1>Buttons</h1>
    <div class="row">
      <div class="col">
        <button class="btn btn-primary">
          <i class="np-plus iconBody18 mr-2"></i>
          Primary
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <button class="btn btn-primary">
          Primary
          <i class="np-plus iconBody18 ml-2"></i>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <button class="btn btn-secondary">
          <i class="np-plus iconBody18 mr-2"></i>
          Secondary
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <button class="btn btn-secondary" disabled>
          <i class="np-plus iconBody18 mr-2"></i>
          Disabled
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-5">
        <button class="btn btn-primary w-100">
          Salvar
          <i class="np-done iconBody18 ml-2"></i>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div ngbDropdown class="col-5 d-inline-block">
        <button class="btn btn-secondary" id="dropdownManual" ngbDropdownToggle>
          <i class="np-plus iconBody18 mr-2"></i>
          <span>Label dropdown</span>
          <div class="badge badge-info ml-2">5</div>
          <i class="np-angle-down iconBody18 ml-2"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownManual" class="dropdown-menu">
          <button ngbDropdownItem class="p-2 d-flex align-items-center">
            <i class="np-folder iconBody18"></i>
            <span class="wBody14 ml-2">Action - 1</span>
          </button>
          <button ngbDropdownItem class="p-2 d-flex align-items-center">
            <i class="np-folder iconBody18"></i>
            <span class="wBody14 ml-2">Another Action</span>
          </button>
          <button ngbDropdownItem class="p-2 d-flex align-items-center">
            <i class="np-folder iconBody18"></i>
            <span class="wBody14 ml-2">Something else is here</span>
          </button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <button class="
          btn btn-large
          d-flex
          flex-column
          justify-content-center
          align-items-center
        ">
        <i class="np-chat-4 iconBody22"></i>
        <div class="body14">Label</div>
      </button>
    </div>
    <hr />
    <h1>Card com radio</h1>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <app-card-with-icon-title-radio value="E" [formControl]="this.formControlSignatureType"
              group="group-radio-signature-type-home" title="Eletronica" [icons]="['np-digital-signature']"
              description="Eletrônica
              Assina sem certificado digital">
            </app-card-with-icon-title-radio>
          </div>
          <div class="col-md-4 col-sm-12">
            <app-card-with-icon-title-radio value="P" [formControl]="this.formControlSignatureType"
              [icons]="['np-eletronic-signature']" group="group-radio-signature-type-home" title="Digital"
              description="Assina com certificado digital"></app-card-with-icon-title-radio>
            </div>
          <div class="col-md-4 col-sm-12">
            <app-card-with-icon-title-radio value="PE" [formControl]="this.formControlSignatureType"
              [icons]="['np-digital-signature','np-eletronic-signature']" group="group-radio-signature-type-home"
              title="Ambos" description="Assina com ou sem certificado digital"></app-card-with-icon-title-radio>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h1>Avatar</h1>
    <div class="row">
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-md-4 ">
            <h6>Avatar Default</h6>
            <div class="d-flex">
              <app-avatar name="Guilherme Abreu"></app-avatar>
              <app-avatar icon="np-user"></app-avatar>
            </div>
          </div>
          <div class="col-md-4 ">
            <h6>Regra do 'C' e 'U: Carlos Ulisses'</h6>
            <app-avatar name="Carlos Ulisses"></app-avatar>
          </div>
          <div class="col-md-4 ">
            <h6>Avatar com icone de done</h6>
            <app-avatar name="Guilherme" [done]="true"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, circular com iniciais tamanho grande</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="primary" size="large"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="danger" size="large"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar name="Guilherme Abreu" color="secondary" size="large"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="success" size="large"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, circular com iniciais tamanho medio</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="primary" size="medium"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="danger" size="medium"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar name="Guilherme Abreu" color="secondary" size="medium"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="success" size="medium"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, circular com iniciais tamanho pequeno</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="primary" size="small"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="danger" size="small"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar name="Guilherme Abreu" color="secondary" size="small"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="success" size="small"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, quadrada com iniciais, tamanho grande</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="primary" size="large" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="danger" size="large" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar name="Guilherme Abreu" color="secondary" size="large" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="success" size="large" type="square"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, quadrada com iniciais, tamanho medio</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="primary" size="medium" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="danger" size="medium" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar name="Guilherme Abreu" color="secondary" size="medium" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="success" size="medium" type="square"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, quadrada com iniciais, tamanho pequeno</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="primary" size="small" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="danger" size="small" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar name="Guilherme Abreu" color="secondary" size="small" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar name="Guilherme Abreu" color="success" size="small" type="square"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, circula com icone, tamanho grande</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="primary" size="large"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="danger" size="large"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar icon="np-user" color="secondary" size="large"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="success" size="large"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, circula com icone, tamanho medio</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="primary" size="medium"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="danger" size="medium"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar icon="np-user" color="secondary" size="medium"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="success" size="medium"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, circula com icone, tamanho pequeno</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="primary" size="small"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="danger" size="small"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar icon="np-user" color="secondary" size="small"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="success" size="small"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, quadrado com icone, tamanho grande</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="primary" size="large" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="danger" size="large" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar icon="np-user" color="secondary" size="large" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="success" size="large" type="square"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, quadrado com icone, tamanho medio</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="primary" size="medium" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="danger" size="medium" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar icon="np-user" color="secondary" size="medium" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="success" size="medium" type="square"></app-avatar>
          </div>
        </div>
        <h6>Avatares com cores pre definidas, quadrado com icone, tamanho pequeno</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="primary" size="small" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="danger" size="small" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center bg-primary">
            <app-avatar icon="np-user" color="secondary" size="small" type="square"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar icon="np-user" color="success" size="small" type="square"></app-avatar>
          </div>
        </div>
        <h6>Avatar com cores aleatórias passando o index usando as iniciais</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar *ngFor="let avatar of avatares; let i = index" [name]="avatar" size="small" type="square"
              [index]="i"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar *ngFor="let avatar of avatares; let i = index" [name]="avatar" size="small" type="circle"
              [index]="i"></app-avatar>
          </div>
        </div>
        <h6>Avatar com cores aleatórias passando o index usando o icone</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar *ngFor="let avatar of avatares; let i = index" icon="np-user" size="small" type="square"
              [index]="i"></app-avatar>
          </div>
          <div class="col-md d-flex justify-content-center">
            <app-avatar *ngFor="let avatar of avatares; let i = index" icon="np-user" size="small" type="circle"
              [index]="i"></app-avatar>
          </div>
        </div>
        <h4>Avatar extra pequeno</h4>
        <h6>Com icone</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar-one-letter *ngFor="let avatar of avatares; let i = index" icon="np-user" [index]="i">
            </app-avatar-one-letter>
          </div>
        </div>
        <h6>Com apenas uma inicial</h6>
        <div class="row">
          <div class="col-md d-flex justify-content-center">
            <app-avatar-one-letter *ngFor="let avatar of avatares; let i = index" [name]="avatar" [index]="i">
            </app-avatar-one-letter>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h1>Loader</h1>
    <div class="row">
      <div class="col">
        <button class="btn btn-primary" (click)="showLoaderSignature()">
          Testar Loader Signature
        </button>
      </div>
      <div class="col">
        <button class="btn btn-primary" (click)="showLoader()">
          Testar Loader
        </button>
      </div>
    </div>
    <hr />
    <h1>Select</h1>
    <hr />
    <div class="row">
      <div class="col">
        <app-select-normal [formulario]="formSelectNormal" [id]="''" [options]="optionsSelectNormal"
          [description]="'This is a select default for all application'" [isRequired]='false'
          [placeholder]="'Selecione um Elemento'"></app-select-normal>
        <br>
        <app-select-normal [formulario]="formSelectNormalDisabled" [options]="optionsSelectNormal"
          [description]="'This is a select default for all application {disabled}'" [isRequired]='false'
          [placeholder]="'Disabled'"></app-select-normal>
        <br>
        <app-select-normal [id]="'idSelectNormal'" [formulario]="formSelectNormalRequired"
          [options]="optionsSelectNormal" [description]="'This is a select default for all application {required}'"
          [isRequired]='true' [placeholder]="'Selecione um Elemento'"></app-select-normal>
      </div>
    </div>
    <hr>
    <h1>Select Seta</h1>
    <hr>
    <div class="row">
      <div class="col">
        <app-select-seta [formulario]="formSelectNormal" [options]="optionsSelectNormal"
          [description]="'This is a select with arrow for all application'" [isRequired]='false'
          [placeholder]="'Selecione um Elemento'"></app-select-seta>
        <br>
        <app-select-seta [formulario]="formSelectNormalDisabled" [options]="optionsSelectNormal"
          [description]="'This is a select with arrow for all application {disabled}'" [isRequired]='false'
          [placeholder]="'Disabled'"></app-select-seta>
        <br>
        <app-select-seta [id]="'idSelectSeta'" [formulario]="formSelectNormalRequired" [options]="optionsSelectNormal"
          [description]="'This is a select with arrow for all application {required}'" [isRequired]='true'
          [placeholder]="'Selecione um Elemento'"></app-select-seta>
      </div>
    </div>
    <hr>
    <h1>Select Search</h1>
    <hr>
    <!-- <app-select-search [formulario]="formSelectNormal" [options]="optionsSelectNormal"
      [description]="'This is a select with search for all application'" [isRequired]='false'
      [placeholder]="'Selecione um Elemento'"></app-select-search>
    <br>
    <app-select-search [formulario]="formSelectNormalDisabled" [options]="optionsSelectNormal"
      [description]="'This is a select with arrow for all application {disabled}'" [isRequired]='false'
      [placeholder]="'Disabled'"></app-select-search>
    <br>
    <app-select-search [id]="'idSelectSeta'" [formulario]="formSelectNormalRequired" [options]="optionsSelectNormal"
      [description]="'This is a select with arrow for all application {required}'" [isRequired]='true'
      [placeholder]="'Selecione um Elemento'"></app-select-search> -->
    <hr>
  </div>
</div>