import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAtualizarAssinaturaComponent } from './modal-atualizar-assinatura.component';

@Injectable()
export class ModalAtualizarAssinaturaService {
    constructor(
        private modalService: NgbModal,
    ) { }

    public async open(): Promise<boolean> {
        try {
            const modalRef = this.modalService.open(ModalAtualizarAssinaturaComponent, {
                centered: true,
                backdrop: false,
            });
            const result = await modalRef.result;
            return Promise.resolve(result);
        } catch (error) {
            return Promise.resolve(false);
        }
    }
}
