import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Certificate } from "../../classes/certificate";

@Component({
  selector: 'app-modal-localize-certificate',
  templateUrl: './modal-localize-certificate.component.html',
  styleUrls: ['./modal-localize-certificate.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModalLocalizeCertificateComponent implements OnInit {
  @Output() public localizeSubscribers: EventEmitter<Certificate> = new EventEmitter();

  public certificates: Certificate[] = [];
  public formControlCertificate = new FormControl('');
  public subscribers: Certificate[];

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.carregarCertificados();
  }

  public closedModal(): void {
    this.activeModal.close({ certificado: null });
  }

  private async carregarCertificados(): Promise<void> {
    this.certificates = this.subscribers;
  }

  public onClickSelectCertificate(event: Certificate): void {
    this.localizeSubscribers.emit(event);
    this.activeModal.close({ certificado: event });
  }

}