import { Injectable } from '@angular/core';
import { Folder } from './../../../../shared/models/folder';

@Injectable({
  providedIn: 'root',
})
export class CardFolderService {

  getDateCardFolder(): Folder {
    return {
      name: 'Nome da pasta',
      value: 'nomeDaPasta',
      document: 5,
      date: new Date(2021, 7, 28, 15, 17, 0),
    };
  }

  getSharedUsers() {
    return [
      {
        name: 'Eduardo Sene',
      },
      {
        name: 'Camila Alves',
      },
    ];
  }
}
