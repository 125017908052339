import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm-delete-tag',
  templateUrl: './modal-confirm-delete-tag.component.html',
  styleUrls: ['./modal-confirm-delete-tag.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalConfirmDeleteTagComponent implements OnInit {

  constructor(
    private ngbActiveModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  public closeModal(result: boolean): void {
    this.ngbActiveModal.close(result);
  }
}
