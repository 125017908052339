<div class="pastas">
  <header class="header">
    <div class="d-flex justify-content-between align-items-center border-header">
      <div class="p-3 Heading20">
        Pastas
      </div>

      <div class="d-flex search-position">
        <div class="search" ngbDropdown display="dynamic" placement="bottom-right" #dropSearch="ngbDropdown">
          <div class="
              inner-addon
              left-addon
              d-flex
              align-items-center
              input-search-addon
              two-addon-in-same-input
            ">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14 input-search" placeholder="Pesquisar por título"
              aria-describedby="icon-input" ngbDropdownToggle />
            <i *ngIf="dropSearch.isOpen()" class="np np-error wBody14"></i>
          </div>
          <div ngbDropdownMenu aria-labelledby="inputSearch" class="search-dropdown w-100">
            <span class="p-2 body14">Sugestões de busca </span>
            <button ngbDropdownItem class="p-2 d-flex align-items-center" *ngFor="let search of searchs">
              <i class="np-search icon-search-dropdown wBody14" id="icon-input"></i>
              <span class="wBody14 ml-2">{{ search }}</span>
            </button>
          </div>
        </div>
      </div>

    </div>

  </header>
  <main class="d-flex">
    <app-card-folder [viewCard]="'createCardFolder'"> </app-card-folder>
    <app-card-folder [viewCard]="'cardFolder'"> </app-card-folder>
  </main>
</div>