<div class="row p-4 app-modal-localize-certificate">
  <div class="col-md-11 login-certificate-title">Para localizar os documentos será necessário
    selecionar o certificado digital!</div>
  <div class="col-md-1">
      <a style="cursor: pointer;" (click)="closedModal()">
          <i class="np np-close-doc"></i>
      </a>
  </div>
  <div *ngIf="certificates" class="col-md-12 mt-2">
      <app-select-search-certificate [certificates]="certificates" [formSelect]="formControlCertificate" (eventSelectOption)="onClickSelectCertificate($event)" ></app-select-search-certificate>
  </div>
</div>