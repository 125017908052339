import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Certificate } from 'src/app/shared/classes/certificate';
import { ModalConfirmarInicioAssinaturaComponent } from './modal-confirmar-inicio-assinatura.component';

@Injectable()
export class ModalConfirmarInicioAssinaturaService {
  constructor(
    private modalService: NgbModal,
  ) { }

  public async open(pCertificado: Certificate | null): Promise<boolean> {
    try {
      const modalRef = this.modalService.open(ModalConfirmarInicioAssinaturaComponent, {
        centered: true,
      });
      modalRef.componentInstance.certificadoSelecionado = pCertificado;
      const resultadoConfirmacao = await modalRef.result;
      return Promise.resolve(resultadoConfirmacao);
    } catch (error) {
      return Promise.resolve(false);
    }
  }
}
