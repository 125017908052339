import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  public set(key: string, value: string): boolean {
    this.storage.setItem(key, value);
    return true;
  }

  public get(key: string): string | null {
    return this.storage.getItem(key);
  }

  public remove(key: string): boolean {
    if (this.get(key) === null) {
      return false;
    }
    this.storage.removeItem(key);
    return true;
  }

  public clear(): boolean {
    this.storage.clear();
    return true;
  }
}
