import { Injectable } from '@angular/core';
import { RequestService } from '../../services/request/request.service';
import { UrlApiNpaper } from '../../models/url-api-npaper';
import { ITagCreate, ITagUpdate, TagsModel } from '../../models/tagsModel';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(
    private requestService: RequestService
  ) { }

  public async getTags(): Promise<TagsModel[]> {
    const returnTags = await this.requestService
      .setHeaderToken()
      .get(`${UrlApiNpaper.PAPER_TAG}`);

    return returnTags.tags;
  }

  public async deleteTag(tagId: number): Promise<{ success: boolean }> {
    return await this.requestService
      .setHeaderToken()
      .delete(`${UrlApiNpaper.PAPER_TAG}/${tagId}`);
  }

  public async putTag(tagId: number, tag: ITagUpdate): Promise<{ success: boolean }> {
    return await this.requestService
      .setHeaderToken()
      .put(`${UrlApiNpaper.PAPER_TAG}/${tagId}`, tag);
  }

  public async postTag(tag: ITagCreate): Promise<{ success: boolean }> {
    return await this.requestService
      .setHeaderToken()
      .post(`${UrlApiNpaper.PAPER_TAG}`, tag);
  }

  public async verificarVinculoTags(tagId: number): Promise<{ success: boolean }> {
    const returnTags = await this.requestService
      .setHeaderToken()
      .get(`${UrlApiNpaper.PAPER_TAG}/${tagId}`);

    return returnTags.tags;
  }
}
