<div class="modal-confirm-blocker-or-delete-component p-4 pb-md-5">
    <div class="content">
        <div class="d-flex justify-content-end icon-close">
            <i class=" iconBody22 np-close color-gray-300" (click)="cancel()"></i>
        </div>
        <div *ngIf="isModalBlock()" class="d-flex justify-content-around flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center mb-3">
                <app-avatar icon="np-locked" color="danger"></app-avatar>
            </div>
            <div class="d-flex justify-content-around flex-column align-items-center">
                <h4>
                    <strong class="Heading20 color-gray-500">Deseja bloquear usuário?</strong>
                </h4>
            </div>
            <div class="flex-grow-1 d-flex justify-content-around flex-column align-items-center">
                <div class="d-flex my-3">
                    <button (click)="cancel()" class="btn btn-secondary mr-3">
                        <i class="iconBody18 mr-2 np-error"></i>
                        Cancelar
                    </button>
                    <button (click)="confirm()" class="btn btn-danger ml-3">
                        <i class="iconBody18 mr-2 np-locked"></i>
                        Sim, bloquear
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isModalUnBlock()" class="d-flex justify-content-around flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center mb-3">
                <app-avatar icon="np-unlocked"></app-avatar>
            </div>
            <div class="d-flex justify-content-around flex-column align-items-center text-center">
                <strong class="Heading20 color-gray-500">Deseja desbloquear usuário?</strong>
            </div>
            <div class="flex-grow-1 d-flex justify-content-around flex-column align-items-center">
                <div class="d-flex buttons">
                    <button (click)="cancel()" class="btn btn-secondary mr-2">
                        <i class="iconBody18 mr-2 np-error"></i>
                        Cancelar
                    </button>
                    <button (click)="confirm()" class="btn btn-primary ml-2">
                        <i class="iconBody18 mr-2 np-unlocked"></i>
                        Sim, desbloquear
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isModalDelete()" class="d-flex justify-content-around flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center mb-2">
                <app-avatar icon="np-trash" color="danger"></app-avatar>
            </div>
            <div class="text-center text-wrap">
                <div class="mb-1 Heading20">
                    <strong>Deseja excluir usuário?</strong>
                </div>
                <div class="wBody14 mt-1">
                    <strong>{{usuarioExcluir.email}}</strong>
                    <br>
                    <span>Essa ação não poderá ser revertida.</span>
                </div>
            </div>
            <div class="flex-grow-1 d-flex justify-content-around row align-items-center">
                <div class="d-flex my-3">
                    <button (click)="cancel()" class="btn btn-secondary mr-2">
                        <i class="iconBody18 mr-2 np-error"></i>
                        Cancelar
                    </button>
                    <button (click)="confirm()" class="btn btn-danger ml-2">
                        <i class="iconBody18 mr-2 np-trash"></i>
                        Sim, excluir
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isModalTransfer()" class="d-flex justify-content-around flex-column align-items-center">
            <div class="d-flex justify-content-center align-items-center mb-2">
                <app-avatar icon="np-trash" color="danger"></app-avatar>
            </div>
            <div class="text-center px-3">
                <strong class="Heading20 color-gray-500">Deseja excluir usuário?</strong>
                <div class="d-flex flex-column wBody14 color-gray-400">
                    <strong>{{usuarioExcluir.email}}</strong>
                    <span class="mb-1">Essa ação não poderá ser revertida.</span>
                    <span>
                        O usuário selecionado é proprietário de documentos enviados. Para excluí-lo você deve
                        atribuir a outra pessoa a função de proprietário.
                    </span>
                    <div class="row">
                        <div class="col-md-12 my-3">
                            <app-select-user-with-search [users]="this.usuariosTransferir"
                                [formControlSelectUserWithSearch]="formControlSelectUserWithSearch"
                                >
                            </app-select-user-with-search>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1 d-flex justify-content-around flex-column align-items-center">
                <div class="d-flex mb-1">
                    <button (click)="cancel()" class="btn btn-secondary mr-3">
                        <i class="iconBody18 mr-2 np-error"></i>
                        Cancelar
                    </button>
                    <button (click)="confirm()" class="btn btn-danger ml-3"
                        [disabled]="formControlSelectUserWithSearch.invalid">
                        <i class="iconBody18 mr-2 np-trash"></i>
                        Sim, excluir
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>