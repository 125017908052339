<div class="app-toaster">
    <div  class="alert mb-3 " [class]="this.color"
    [ngClass]="animationToaster()"
    role="alert">
    <div class="
        field-alert
        d-flex
        align-items-center
        justify-content-between
        body14
      ">
      <div class="d-flex align-items-center" [ngClass]="this.textClassSize">
        <i class="icon-alert iconBody18" [ngClass]="this.toaster?.icon || ''"></i>
        <span class="card-box-text color-gray-500">{{toaster?.text}}</span>
      </div>
      <button class="close" (click)="closed()" *ngIf="this.btnClose">
        <i class="np-close iconBody18"></i>
      </button>
    </div>
    <div class="progress-alert" *ngIf="this.autoClose">
      <div [ngStyle]="{width: this.progressBar+'%'}"></div>
    </div>
  </div>
</div>