export enum TagColorEnum{
    'red' = '#d32f2f',
    'pink' = '#c2185b',
    'purple' = '#7b1fa2',
    'deep-purple' = '#512da8',
    'indigo' = '#303f9f',
    'blue' = '#1976d2',
    'light-blue' = '#0288d1',
    'cyan' = '#0097a7',
    'teal' = '#00796b',
    'green' = '#388e3c',
    'light-green' = '#689f38',
    'lime' =  '#afb42b',
    'yellow' = '#fbc02d',
    'orange' = '#ffa000',
    'deep-orange' = '#e64a19',
    'brown' = '#5d4037',
    'grey' = '#616161',
    'blue-grey' = '#455a64'
}
