import { Component, OnInit } from '@angular/core';
import { ModalTermosDeUsoService } from './modal-termos-de-uso.service';

@Component({
  selector: 'app-modal-termos-de-uso',
  templateUrl: './modal-termos-de-uso.component.html',
  styleUrls: ['./modal-termos-de-uso.component.scss']
})
export class ModalTermosDeUsoComponent implements OnInit {
  public showModalTermos: boolean = false;

  constructor(
    private modalTermosDeUsoService: ModalTermosDeUsoService
  ) { }

  ngOnInit(): void {
    this.modalTermosDeUsoService.getObservable()
      .subscribe(() => this.showModalTermos = true);
  }

  public closeModalTermos(): void {
    this.showModalTermos = false;
  }

}
