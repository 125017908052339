import { ModelosAssinaturasEletronicasEnum } from '../enum/modelos-assinaturas-eletronica.enum';

export class EletronicSignData {
    public readonly base64: string;
    public readonly modelo: ModelosAssinaturasEletronicasEnum;

    constructor(pBase64: string, pModeloAssinatura: ModelosAssinaturasEletronicasEnum) {
        this.base64 = pBase64;
        this.modelo = pModeloAssinatura;
    }
}
