import { SignStatusEnum } from '../enum/signStatusEnum';
import SignatureInvolved from './signatureInvolved';

export default class SignatureDocumentDigital {
    public readonly docGuid: string;
    public readonly involveds: SignatureInvolved[];
    public readonly nomeDocumento: string;
    public readonly status: SignStatusEnum;
    public readonly assinar: boolean;

    constructor(
        pDocGuid: string,
        pType: string,
        pStatus: SignStatusEnum,
        pAssinar: boolean,
        pInvolved: SignatureInvolved[],
    ) {
        this.docGuid = pDocGuid;
        this.nomeDocumento = pType;
        this.status = pStatus;
        this.assinar = pAssinar;
        this.involveds = pInvolved;
    }
}
