
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeNotLoggedComponent } from 'src/app/home-not-logged/home-not-logged.component';
import { NotAuthLayoutComponent } from './not-auth-layout.component';

const routes: Routes = [
  {
    path: '',
    component: NotAuthLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'assinar-documentos',
      },
      {
        path: 'assinar-documentos',
        loadChildren: () =>
          import('../../modules/not-logged/send-and-details/send-and-details-not-logged.module').then(m => m.SendAndDetailsNotLoggedModule),
      },
      {
        path: 'home',
        component: HomeNotLoggedComponent,
      },
      // {
      //   path: 'usuarios',
      //   loadChildren: () =>
      //     import('../../modules/users/users.module').then(m => m.UsersModule),
      // },
      // {
      //   path: 'enviados',
      //   loadChildren: () =>
      //     import('../../modules/send-and-details/send-and-details.module').then(m => m.SendAndDetailsModule),
      // },
      // {
      //   path: 'descartados',
      //   loadChildren: () =>
      //     import('../../modules/discarded/discarded.module').then(m => m.DiscardedModule),
      // },
      // {
      //   path: 'pastas',
      //   loadChildren: () =>
      //     import('../../modules/folder/folder.module').then(m => m.FolderModule),
      // },
      // {
      //   path: 'assinar-em-lote',
      //   loadChildren: () =>
      //     import('../../modules/batch-sign/batch-sign.module').then(
      //       m => m.BatchSignModule,
      //     ),
      // },
      // {
      //   path: 'assinantes',
      //   loadChildren: () =>
      //     import('../../modules/subscriber-company/subscriber-company.module').then(
      //       m => m.SubscriberCompanyModule,
      //     ),
      // },
      // {
      //   path: 'configuracoes',
      //   loadChildren: () =>
      //     import('../../modules/settings/settings.module').then(m => m.SettingsModule),
      // },
      // {
      //   path: 'assinatura',
      //   loadChildren: () =>
      //     import('../../modules/signature/signature.module').then(
      //       m => m.SignatureModule,
      //     ),
      // },
      // {
      //   path: 'admin',
      //   loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule),
      // },
      // {
      //   path: 'loading',
      //   component: LoadingComponent,
      // },
      // {
      //   path: 'new-documents',
      //   loadChildren: () => import('../../modules/new-documents/new-documents.module').then(m => m.NewDocumentsModule),
      // }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotAuthLayoutRoutingModule { }
