import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'angleUpDown',
})
export class AngleUpDownPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value) {
      return 'np-angle-up';
    }

    return 'np-angle-down';
  }
}
