<div class="app-card-user h-100 w-100 p-3">
  <!-- layout card para tablet e desktop  -->
  <div class="d-none d-md-inline">
    <div class="row">
      <div class="col-4 iconBody18">
        <i *ngIf="user.isPrimary" class="np-edit-user border-less text-warning mr-2" placement="right"
          ngbTooltip="Pode editar qualquer usuário" tooltipClass="tooltipCustomer">
        </i>
        <i *ngIf="user.viewAllDocs" class="np-file-view border-less text-warning" placement="right"
          ngbTooltip="Pode visualizar documentos enviados de quaisquer usuários" tooltipClass="tooltipCustomer"></i>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <app-avatar [name]="user.name"></app-avatar>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <span class="wBody12 blocked-card-user"
          [ngClass]="{'d-none': user.isBlocked === false, 'd-inline': user.isBlocked === true}">
          Bloqueado
        </span>
      </div>
      <div class="col-12 d-flex justify-content-center flex-column text-center m-1">
        <ng-component [ngTemplateOutlet]="dataUser"></ng-component>
      </div>
      <div class="col-12 mt-2 d-flex justify-content-center">
        <button class="btn-icon hover-border-primary mr-2" ngbTooltip="Editar" tooltipClass="btn-tooltip"
          (click)="onEditUser()">
          <i class="np-edit-2"> </i>
        </button>
        <button class="btn-icon hover-border-primary mr-2" (click)="changeBlocked()"
          ngbTooltip="{{user.isBlocked ? 'Desbloquear' : 'Bloquear'}}" tooltipClass="btn-tooltip"
          [disabled]="blockerButton()" [ngClass]="{'button-option-disabled' : blockerButton()}">
          <i [ngClass]="user.isBlocked ? 'np-unlocked' : 'np-locked'"></i>
        </button>
        <button (click)="onDelete()" class="btn-icon hover-border-primary" ngbTooltip="Excluir"
          tooltipClass="btn-tooltip" [disabled]="blockerButton()"
          [ngClass]="{'button-option-disabled' : blockerButton()}">
          <i class="np-trash"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- layout para mobile -->
  <div class="d-inline d-md-none">
    <div class="row">
      <div class="col-2">
        <app-avatar [name]="user.name" size="small"></app-avatar>
      </div>
      <div class="col-8 d-flex justify-content-center flex-column">
        <ng-component [ngTemplateOutlet]="dataUser"></ng-component>
      </div>
      <div class="col-2">
        <div ngbDropdown class="pr-2 icon-mobile">
          <i class="iconBody22 np np-other-1 color-gray-400" ngbDropdownToggle></i>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-list-mobile body14">
            <button ngbDropdownItem (click)="onEditUser()">
              <span class="mx-2 d-flex align-items-center">
                <i class="iconBody18 np np-edit-2 color-gray-400 pr-2"></i>
                Editar
              </span>
            </button>
            <button ngbDropdownItem (click)="onDelete()" class="button-mobile px-2 d-flex align-items-center" [disabled]="blockerButton()"
              [ngClass]="{'button-option-disabled' : blockerButton()}">
              <i class="iconBody18 np np-trash color-gray-400 pr-2"></i>
              Excluir
            </button>
            <button ngbDropdownItem (click)="changeBlocked()" class="mx-2 button-mobile d-flex align-items-center"
              [disabled]="blockerButton()" [ngClass]="{'button-option-disabled' : blockerButton()}">
              <i class="iconBody18 np color-gray-400 pr-2" [ngClass]="user.isBlocked ? 'np-unlocked' : 'np-locked'"></i>
              {{user.isBlocked ? 'Desbloquear' : 'Bloquear'}}
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mb-0 pb-0 mt-2">
        <h5 class="m-0 p-0">
          <i *ngIf="user.isPrimary" class="mr-2 np-edit-user border-less text-warning" placement="bottom"
            ngbTooltip="Pode editar qualquer usuário"></i>
          <i *ngIf="user.viewAllDocs" class="mr-2 np-file-view border-less text-warning" placement="bottom"
            ngbTooltip="Pode visualizar documentos enviados de quaisquer usuários"></i>
          <i *ngIf="user.isBlocked" class="mr-2 np-locked text-danger"></i>
        </h5>
      </div>
    </div>
  </div>
</div>


<ng-template #dataUser>
  <span class="Heading16 limit-caracter">
    <strong>{{ user.name }}</strong>
  </span>
  <span class="wBody12 limit-caracter">CPF: {{ user.cpf | cpfCnpjFormat }}</span>
  <span class="wBody12 limit-caracter">{{ user.email }}</span>
</ng-template>