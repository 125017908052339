<div class="assinatura-digitar">
    <div class="row">
        <div class="col-12">
            <app-input [label]="'Digite seu nome'" [placeholder]="''" [inputControl]="nomeControl"></app-input>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <span class="wBody14">Selecione a fonte preferida</span>
        </div>
    </div>
    <div class="row font-list" *ngFor="let font of fontsList">
        <div class="col-1">
            <app-radio group="font-assinatura" [form]="fontControl" [value]="font.value" [group]="'fonts'"></app-radio>
        </div>
        <div class="col-10 font-text">
            <span [ngClass]="font.fontClass">{{nomeControl.value||'Digite seu nome'}}</span>
        </div>
    </div>
</div>