import { ModalFolderService } from '../modal-folder.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ModalFolder, Folder } from 'src/app/shared/models/folder';

@Component({
  selector: 'app-move-document',
  templateUrl: './move-document.component.html',
  styleUrls: ['./move-document.component.scss'],
})
export class MoveDocumentComponent implements OnInit {
  @Input() viewMoveDocument: ModalFolder;
  moveDocumentForm: FormGroup;
  folders: Folder[] = this.modalFolderService.getFolders();

  constructor(
    private activeModal: NgbActiveModal,
    private modalFolderService: ModalFolderService,
    public formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.moveDocumentForm = this.formBuilder.group({
      folderSelect: ['', [Validators.required]],
    });
  }

  moveToFolder() {
    console.log(this.moveDocumentForm.get('folderSelect')?.value);
    this.activeModal.close();
  }
  onCancel(): void {
    this.activeModal.close();
  }
}
