import { Component, Input, OnInit } from '@angular/core';
import { CurrencyMaskConfig } from '../../directive/currency-mask/currency-mask.config';
import { BehaviorInputComponent } from '../behavior-input/behavior-input.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends BehaviorInputComponent implements OnInit {

  @Input() public type: string = 'text';
  @Input() public mask: string = '';
  @Input() public autocomplete: 'on' | 'off' = 'off';
  @Input() public optionsCurrencyMask: Partial<CurrencyMaskConfig> = { prefix: 'R$:', thousands: '.', decimal: ',', required: this.isRequired };
  @Input() public isCurrency: boolean = false;
  @Input() placeholder: string = 'default';

  ngOnInit(): void {
    if (this.inputControl === undefined) {
      throw new Error('Obrigatório informar InputControl');
    }
  }

  constructor() {
    super();
  }

}
