import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IValorLabel } from '../../models/valor-label';
import { geradorStringAleatorio } from '../../utils/string-util';
import { BehaviorSelectComponent } from '../behavior-select/behavior-select.component';


@Component({
  selector: 'app-select-seta',
  templateUrl: './select-seta.component.html',
  styleUrls: ['./select-seta.component.scss']
})
export class SelectSetaComponent extends BehaviorSelectComponent implements OnInit {
  @ViewChild('toggleInput') toggleInput?: ElementRef;
  @ViewChild('menu') menu?: ElementRef;

  @Input()
  public formulario: FormControl;

  public value: any;

  public dataListOptions: IValorLabel[];

  public showList: boolean = false;

  constructor( private renderer: Renderer2 ) {
    super();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleInput?.nativeElement && e.target !== this.menu?.nativeElement) {
        this.showList = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.id === '') { this.id = geradorStringAleatorio(10); }
    this.dataListOptions = this.options;
    this.formulario.valueChanges
      .subscribe(() => this.changeOptions());
  }

  public showOptions(): void {
    this.showList = true;
  }

  public selectItem(o: IValorLabel): void {
    this.formulario.setValue(o.valor);
    this.showList = false;
  }

  public changeOptions(): void {
    this.showList = true;
    this.dataListOptions = this.options.filter(option => option.label.toLowerCase().includes(this.formulario.value.toLowerCase()));
  }
}
