import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SignatureLayoutStorageService } from 'src/app/core/signature-layout/signature-layout.storage.service';
import { SignStatusEnum } from 'src/app/shared/enum/signStatusEnum';
import { NDocumentosHttpService } from 'src/app/shared/models/document';
import { DocumentHttpService } from 'src/app/shared/services/document/document.http.service';
import { NAssinarDocumentoModel } from './models/assinar-documento.model';

@Injectable()
export class AssinarDocumentoStorageService {
    public paperData: NDocumentosHttpService.IGetDocumentoAssinar;
    public paperId: number;
    public involvedGuid: string;
    public involvedEmail: string;

    public documentosApresentar: NAssinarDocumentoModel.IDocumentosNav[] = [];
    public documentoSelecionadoInvolveds: NAssinarDocumentoModel.IDocumentosNav;
    public envolvidosApresentar: NAssinarDocumentoModel.IDocumentoIvolved[] = [];
    public documentosTotalmenteAssinados: number = 0;
    public todosDocumentosAssinados: boolean = false;
    public haDocumentosAssinarEnvolvido = false;
    constructor(
        private documentHttpService: DocumentHttpService,
        private signatureLayoutStorageService: SignatureLayoutStorageService,
    ) { }

    public async setPaperDataResolver(pData: NDocumentosHttpService.IGetDocumentoAssinar, pInvolvedGuid: string, pPaperId: number): Promise<void> {
        this.paperData = pData;
        this.involvedGuid = pInvolvedGuid;
        this.paperId = pPaperId;
        this.sumarizarDocumentos();
        if (this.involvedEmail) {
            this.signatureLayoutStorageService.userEmail = this.involvedEmail;
        }
    }

    public async reloadDocuments(): Promise<void> {
        const retorno = await this.documentHttpService.getDocumentoAssinar(this.involvedGuid, this.paperId);
        this.paperData = retorno.paper;
        this.sumarizarDocumentos();
    }

    public sumarizarDocumentos(): void {
        this.documentosApresentar = [];
        this.todosDocumentosAssinados = false;
        this.haDocumentosAssinarEnvolvido = false;
        this.documentosTotalmenteAssinados = 0;
        this.paperData.documents.forEach((pDoc) => {
            const documento: NAssinarDocumentoModel.IDocumentosNav = {
                documentGuid: pDoc.guid,
                documentId: pDoc.documentId,
                filename: pDoc.type,
                status: pDoc.status,
                isAssinado: pDoc.status === SignStatusEnum.totalmenteAssinado,
                qtdEnvolvidoAssinado: 0,
                checkAssinar: new FormControl(true),
                documentBase64: null,
                involveds: [],
                envolvidoPrecisaAssinar: false,
            };
            const sumarioEnvolvido = this.sumarizarEnvolvido(pDoc.involveds);
            documento.involveds = [...sumarioEnvolvido.envolvidos];
            documento.qtdEnvolvidoAssinado = sumarioEnvolvido.qtdAssinados;

            if (sumarioEnvolvido.envolvidoAindaPrecisaAssinar) {
                documento.envolvidoPrecisaAssinar = true;
            }
            if (documento.isAssinado) {
                this.documentosTotalmenteAssinados += 1;
            }

            this.documentosApresentar.push(documento);
        });
        if (this.documentosTotalmenteAssinados === this.documentosApresentar.length) {
            this.todosDocumentosAssinados = true;
        }
        this.documentoSelecionadoInvolveds = this.documentosApresentar[0];
        this.envolvidosApresentar = this.documentoSelecionadoInvolveds.involveds;
    }

    public sumarizarEnvolvido(pEnvolvido: NDocumentosHttpService.IInvolved[]): {
        envolvidos: NAssinarDocumentoModel.IDocumentoIvolved[],
        qtdAssinados: number,
        envolvidoAindaPrecisaAssinar: boolean;
    } {
        let qtdAssinados = 0;
        let envolvidoAindaPrecisaAssinar = false;
        const envolvidos = pEnvolvido.map((pEnv) => {
            const isAssinado = pEnv.status === SignStatusEnum.totalmenteAssinado;
            if (isAssinado) {
                qtdAssinados += 1;
            }
            if (pEnv.guid === this.involvedGuid) {
                this.involvedEmail = pEnv.email;
                if (isAssinado === false) {
                    this.haDocumentosAssinarEnvolvido = true;
                    envolvidoAindaPrecisaAssinar = true;
                }
            }
            const envolvido: NAssinarDocumentoModel.IDocumentoIvolved = Object.assign({
                isAssinado,
            }, pEnv);
            return envolvido;
        });

        return { envolvidos, qtdAssinados, envolvidoAindaPrecisaAssinar };
    }
}
