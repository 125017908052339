import { notification, userLogged } from '../../../../shared/models/nav';
import { NavNService } from './nav.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-not-logged',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  searchs: string[] = this.navbarService.getSearch();
  notifications: notification[] = this.navbarService.getNotification();
  user: userLogged = this.navbarService.getUserLogged();

  isGradientScroll: boolean = true;

  constructor(
    private navbarService: NavNService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) { }

  ngOnInit(): void { }

  onScroll(event: any) {
    let elementHeight = event.target.scrollHeight - event.target.offsetHeight;
    let scrollPosition = Math.round(event.target.scrollTop);

    if (elementHeight <= scrollPosition) {
      return (this.isGradientScroll = false);
    }
    return (this.isGradientScroll = true);
  }

  public logout(): void {
    this.localStorageService.clear();
    this.router.navigate(['/login']);
  }
}
