export class DateUtil {

  static format(date: Date | string, format: string): string {
    if (typeof date === 'string') {
      date = new Date(date);
      date.setHours(date.getHours() + 3);
    }

    const formattedDate = format
      .replace(/DD/g, date.getUTCDate().toString().padStart(2, '0'))
      .replace(/D/g, date.getUTCDate().toString())
      .replace(/MM/g, (date.getUTCMonth() + 1).toString().padStart(2, '0'))
      .replace(/M/g, (date.getUTCMonth() + 1).toString())
      .replace(/YYYY/g, date.getUTCFullYear().toString())
      .replace(/hh/g, date.getUTCHours().toString())
      .replace(/mm/g, date.getUTCMinutes().toString())
      .replace(/ss/g, date.getUTCSeconds().toString());

    return formattedDate;
  }

  static iniciarData(pData: string | Date): Date {
    try {
      let data: Date;
      if (typeof pData === 'string') {
        data = new Date(`${pData.split('T')[0]} 00:00:00`);
        data = new Date(data.getTime() + new Date().getTimezoneOffset() / 60);
      } else {
        data = pData;
        data.setMilliseconds(0);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }


  static formatDate(date: string): string {
    const months: string[] = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    const day: string = date.substring(8, 10);
    const month: string = months[parseInt(date.substring(5, 7), 0) - 1];
    const year: string = date.substring(0, 4);

    const hour: string = date.substring(11, 16);

    return `${day} ${month} ${year} - ${hour}`;
  }
}
