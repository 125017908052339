import { SignatureType } from '../types/SignatureType.type';
import { ICustomerDataResponse } from './customer';
import { INpaperResposer } from './npaper-request.interface';

export interface IUserHttpResponse extends INpaperResposer {}
export interface IUserResponse<T = any> extends IUserHttpResponse {
  user: T;
}
export interface IUsersResponse<T = any> extends IUserHttpResponse {
  users: T;
}

export interface IUserSearchByEmailResponse extends IUsersResponse<IUserAdmin[]> {}

export interface IUserDataLoginResponse extends IUserResponse<IUserData> {}

export interface IUserCreateUserAdminResponse extends IUsersResponse<IUserAdmin> {}

export interface IUsersAdminResponse extends IUsersResponse<IUserAdmin[]> {}

export interface IUserCreateResponse extends IUserResponse<ICreateUser>{}

export interface IUser {
  id?: number;
  name: string;
  cpf: string;
  email: string;
  customerId: number;
  isBlocked: boolean;
  isPrimary: boolean;
  viewAllDocs: boolean;
  initials?: string;
}
export interface IUserAdmin {
  userId: number;
  email: string;
  name: string;
  isAdmin: boolean;
  identification: string;
}
export interface IUserData {
  id: number;
  name: string;
  email: string;
  customer: string;
  isBlocked: boolean;
  accessLevel: number;
  token: string;
  identification: string;
  apiKey: string;
  timestamp: boolean;
  hasTimestamp: boolean;
  useStorage: boolean;
  sizeStorage: number;
  isPrimary: boolean;
  printStamp: boolean;
  prePaid: boolean;
  customers: Array<ICustomerDataResponse>;
  customerTest: boolean;
  test: boolean;
  testPeriod: null;
  useSecurityCode: boolean;
  signaturePlan: number;
  signatureQtd: number;
  signatureType: SignatureType;
}
export class User implements IUserData {
  manager: string;
  cpf: string;
  email: string;
  isBlocked: boolean;
  id: number;
  name: string;
  customer: string;
  accessLevel: number;
  token: string;
  identification: string;
  apiKey: string;
  timestamp: boolean;
  hasTimestamp: boolean;
  useStorage: boolean;
  sizeStorage: number;
  isPrimary: boolean;
  printStamp: boolean;
  prePaid: boolean;
  customers: ICustomerDataResponse[];
  customerTest: boolean;
  testPeriod: null;
  signaturePlan: number;
  test: boolean;
  useSecurityCode: boolean;
  signatureQtd: number;
  signatureType: SignatureType;

  constructor(user: Partial<IUser> | undefined) {
    Object.assign(this, user);
  }

  public getCustomers(): ICustomerDataResponse[] {
    return this.customers;
  }

  public getName(): string {
    return this.name;
  }

  public getToken(): string {
    return this.token;
  }

  public getIdentification(): string {
    return this.identification;
  }

  public getApikey(): string {
    return this.apiKey;
  }

}

export interface IUserResponseData {
  email: string;
  identification: string;
  name: string;
  userId: number;
  customerUsers: ICustomerUserResponse[];
  customerId: number;
  isBlocked: boolean;
  isPrimary: boolean;
  isVerified: boolean;
  viewAllDocs: boolean;
}

export interface ICustomerUserResponse{
  customerId: number;
  isBlocked: boolean;
  isPrimary: boolean;
  isVerified: boolean;
  userId: number;
  viewAllDocs: boolean;
}

export interface IUpdateUserDTO {
  name: string;
  identification: string;
  email: string;
  userId: number;
  customerId: number;
  isPrimary: boolean;
  isBlocked: boolean;
  viewAllDocs: boolean;
}
export interface IUpdateNameUserDTO {
  name: string;
  identification: string;
  email: string;
  userId: number;
}

export interface ICreateUserDTO {
  name: string;
  identification: string;
  email: string;
  isPrimary: boolean;
  viewAllDocs: boolean;
}

export interface ICreateUser extends ICreateUserDTO {
  customerId: number;
  isBlocked: boolean;
  userId: number;
}
