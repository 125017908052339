<div>
    <label *ngIf="description !== ''" for="{{id}}">
        {{description}}<span class="red" *ngIf="isRequired"> * </span></label>
    <br>
    <div class="input-group">
        <input type="text" id="id" [disabled]="formulario.disabled" [formControl]="formulario" placeholder="{{placeholder}}" (focus)="showOptions()" #toggleInput>
        <div class="list" *ngIf="showList" #menu>
            <div class="item" *ngIf="dataListOptions.length === 0">Não há opções</div>
            <div class="item item-hover" *ngFor="let option of dataListOptions" (click)="selectItem(option)">{{option.label}}</div>
        </div>
        <span class="pos-left">
            <i _ngcontent-bwd-c130="" id="icon-input" class="np np-search"></i>
        </span>
        <span class="pos-right">
            <i _ngcontent-suj-c130="" class="np np-angle-down"></i>
        </span>
    </div>
    <br>
</div>