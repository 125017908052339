import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FolderRoutingModule } from './folder-routing.module';
import { FolderComponent } from './views/folder/folder.component';
import { CardFolderComponent } from './components/card-folder/card-folder.component';
import { ModalFolderComponent } from './components/modal-folder/modal-folder.component';
import { AddFolderAndEditNameFolderComponent } from './components/modal-folder/add-folder-and-edit-name-folder/add-folder-and-edit-name-folder.component';
import { MoveDocumentComponent } from './components/modal-folder/move-document/move-document.component';
import { SharedFolderComponent } from './components/modal-folder/shared-folder/shared-folder.component';

@NgModule({
  declarations: [
    FolderComponent,
    CardFolderComponent,
    ModalFolderComponent,
    AddFolderAndEditNameFolderComponent,
    MoveDocumentComponent,
    SharedFolderComponent,
  ],
  imports: [
    CommonModule,
    FolderRoutingModule,
    SharedModule,
    ReactiveFormsModule,
  ],
})
export class FolderModule {}
