import { ModalEditNameComponent } from './modal-edit-name.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalEditNameService {

  constructor(
    private ngbModal: NgbModal
  ) { }


  public async openEdit(pIdUser: string): Promise<{ confirm: boolean, nome: null | string }> {
    const modalRef = this.ngbModal.open(ModalEditNameComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'pt-0'
    });

    modalRef.componentInstance.isEditar = true;
    modalRef.componentInstance.userId = pIdUser;

    return await modalRef.result;
  }
}
