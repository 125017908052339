<footer>
  <div *ngIf="!isVisaoMobile"class="footer wBody14">
    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <div class="d-flex justify-content-between margin">
          <div>
            <span>© 2020 <strong>Decisão Sistemas</strong>. Todos os direitos
              reservados.</span>
          </div>
          <div>
            <span>Privacidade | Termos</span>
  
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isVisaoMobile">
    <app-menu-navegacao-mobile></app-menu-navegacao-mobile>
  </div>
</footer>