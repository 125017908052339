import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Certificate } from 'src/app/shared/classes/certificate';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { LocalizeCertificateConstantes } from 'src/app/shared/services/localize-certificate/localize-certificate-constantes';
import { LocalizeCertificateService } from 'src/app/shared/services/localize-certificate/localize-certificate.service';
import { StateService } from 'src/app/shared/services/state/state.service.spec';
import { NotAuthLayoutService } from './not-auth-layout.service';

@Component({
  selector: 'app-not-auth-layout',
  templateUrl: './not-auth-layout.component.html',
  styleUrls: ['./not-auth-layout.component.scss']
})
export class NotAuthLayoutComponent implements OnInit {

  public MESSAGE_CASE_NOT_WEB_PKI_INSTALLED: string = 'Para realizar o login com certificado é preciso a extensão Web PKI em seu navegador. Ao clicar em instalar você será redirecionado para a tela com o passo a passo da instalação.';
  public certificates: Certificate[] = [];
  LocalizeCertificateConstantes = LocalizeCertificateConstantes;

  constructor(
    public notAuthLayoutService: NotAuthLayoutService,
    public stateService: StateService,
    private router: Router,
    private localizeCertificateService: LocalizeCertificateService,
    private toastrService: ToasterService
  ) { }

  ngOnInit(): void {

  }

  public async onLocalizeCertificate(): Promise<void> {
    this.router.navigate(['/not-auth/home']);
    await this.localizeCertificateService.onLocalizeCertificate();

    this.router.navigate(['/not-auth']);
  }

}
