import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IResponsibility } from '../../models/responsibility.interface';
import { ResponsibilityHttpService } from '../../services/responsibility/responsibility.http.service';
import { ToasterService } from '../toaster/toaster.service';

@Component({
  selector: 'app-modal-add-edit-responsibility',
  templateUrl: './modal-add-edit-responsibility.component.html',
  styleUrls: ['./modal-add-edit-responsibility.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalAddEditResponsibilityComponent implements OnInit {

  public formControlResponsibility: FormControl;
  public responsibility: IResponsibility | undefined;
  public edit: boolean = false;
  public title: string = 'Adicionar responsabilidade';

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private responsibilityService: ResponsibilityHttpService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.formControlResponsibility = new FormControl(this.responsibility?.responsibility || null, [Validators.required]);
    if (this.edit) {
      this.modeEdit();
    }
  }

  public closeModal(responsibility?: IResponsibility): void {
    this.ngbActiveModal.close(responsibility);
  }

  public modeEdit(): void {
    this.title = 'Editar responsabilidade';
    this.formControlResponsibility.markAsTouched();
  }

  public async checkIfTitleNameAlreadyRegisteredAndCreate(): Promise<void> {
    try {
      const resultResponsibility = await this.responsibilityService.create(this.formControlResponsibility.value);
      if (!resultResponsibility) {
        this.formControlResponsibility.setErrors({
          nameTitleAlreadyRegistered: true,
        });
        return;
      }
    } catch (error) {

    }
  }

  public async editResponsibility(): Promise<void> {

    if (this.responsibility === undefined) {
      throw new Error('responsibility is undefined');
    }

    try {
      const resultResponsibility = await this.responsibilityService.update(this.responsibility.responsibilityId, this.formControlResponsibility.value);

      if (resultResponsibility.success === false) {
        this.formControlResponsibility.setErrors({
          nameTitleAlreadyRegistered: resultResponsibility.message,
        });
        return;
      }
      this.responsibility.responsibility = this.formControlResponsibility.value;
      this.closeModal(this.responsibility);
    } catch (error: any) {
      this.toasterService.showError(error.message || 'Falha ao editar responsabilidade ');
      throw new Error(error);
    }

  }

  public isFormValid(): boolean {
    return this.formControlResponsibility.valid;
  }

  public addOrEditResponsibility(): void {

    if (this.edit) {
      this.editResponsibility();
      return;
    }

    this.addResponsibility();

  }

  public async addResponsibility(): Promise<void> {
    try {
      const resultResponsibility = await this.responsibilityService.create(this.formControlResponsibility.value);

      if (resultResponsibility.success === false) {
        this.formControlResponsibility.setErrors({
          nameTitleAlreadyRegistered: resultResponsibility.message,
        });
        return;
      }

      this.closeModal(resultResponsibility.responsibility);
    } catch (error: any) {
      this.toasterService.showError(error.message || 'Falha ao adicionar responsabilidade ');
      throw new Error(error);
    }
  }

}
