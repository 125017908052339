
export function  geradorStringAleatorio(tamanho: number): string {
  let stringAleatoria = '';
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < tamanho; i++) {
    stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }

  return stringAleatoria;
}

export const replaceAllParamsUrl = (url: string, ...params: Array<{[key: string]: any}>): string => {
  params.forEach(param => {
    const keyObject = Object.keys(param)[0];
    url = url.replace(`:${keyObject}`, param[keyObject]);
  });

  return url;
};
