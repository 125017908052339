<div class="modal-confirmar-inicio-assinatura m-4 d-flex justify-content-around flex-column">
    <div class="col-12 d-flex justify-content-end Heading16" (click)="cancelamento()">
        <i class="np np-close btn-icon-close"></i>
    </div>
    <div class="row mb-4">
        <div class="col-12 d-flex justify-content-center">
            <app-avatar [icon]="'np-edit'"></app-avatar>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12 justify-content-center">
            <h4>
                <span class="d-flex justify-content-center Heading20">
                    Atenção
                </span>
            </h4>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12 text-center">
            <span>
                Deseja assinar todos os documentos selecionados?
            </span>
        </div>
    </div>
    <div class="row mb-2 certificado-content" *ngIf="!!certificadoSelecionado===true">
        <div class="col-12 text-center">
            <div class="row">
                <div class="col-12">
                    <span>Certificado selecionado</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span><strong>{{certificadoSelecionado?.subjectName}}</strong>
                        - {{certificadoSelecionado?.cpf}}</span>
                </div>
                <div class="col-12">
                    <span> {{certificadoSelecionado?.expirationDate | date:'dd/MM/yyyy'}} -
                        {{certificadoSelecionado?.issuerName}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12 body14 d-flex justify-content-center">
            <button class="btn btn-transparent-white-border mr-4" (click)="cancelamento()"> 
                <span><i class="np-error"></i>
                Cancelar</span> </button>
            <button class="btn btn-primary" (click)="confirmacao()"> <span> <i class="np-done"></i> Sim, assinar</span>
            </button>
        </div>
    </div>
</div>