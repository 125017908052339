import { SecretUtil } from './../../utils/secret-util';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private authService: AuthService
  ) { }

  public isAdmin(): boolean {
    const token = this.authService.getToken();

    if (!token) {
      return false;
    }

    return SecretUtil.parseJwt(token).scope.includes('admin');
  }
}
