<div class="modal-sucesso-assinatura">
    <div class="col-12 d-flex justify-content-end Heading16" (click)="cancelamento()">
        <i class="np np-close btn-icon-close"></i>
    </div>
    <div class="body">
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <img src="../../../../../assets//images//assinatura-sucesso.png" alt="">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 ">
                <h2>Pronto!</h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 ">
                <h2>Documento assinado!</h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 ">
                <span>Você receberá um e-mail com os documentos assinados, assim que todos assinarem.</span>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 ">
                <span><i class="np np-locked"></i> Ambiente seguro Npaper</span>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12 body14 d-flex justify-content-center">
            <button class="btn btn-transparent-white-border mr-4" (click)="cancelamento()">
                <i class="np-error"></i> Fechar
            </button>
        </div>
    </div>
</div>