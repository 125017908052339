import { SignStatusEnum } from '../enum/signStatusEnum';
import { TipoPessoaLetraEnum } from '../enum/tipoPessoaEnum';
import { NDocumentosHttpService } from './document';

export default class SignatureInvolved {
    public readonly cnpj: string | null;
    public readonly cpf: string;
    public readonly guid: string;
    public readonly signatureId: number;
    public readonly signatureQuantity: number;
    public readonly signedAmount: number;
    public readonly signerType: TipoPessoaLetraEnum;
    public readonly status: SignStatusEnum;
    public readonly title: string;
    public readonly useECNPJ: boolean;
    public readonly useECPF: boolean;
    public readonly qtdAssinaturasRealizar: number;

    constructor(pValues: NDocumentosHttpService.IInvolved) {
        this.cnpj = pValues.cnpj;
        this.cpf = pValues.cpf;
        this.guid = pValues.guid;
        this.signatureId = pValues.signatureId;
        this.signatureQuantity = pValues.signatureQuantity;
        this.signedAmount = pValues.signedAmount;
        this.signerType = pValues.signerType;
        this.status = pValues.status;
        this.title = pValues.title;
        this.useECNPJ = pValues.useECNPJ;
        this.useECPF = pValues.useECPF;
        this.qtdAssinaturasRealizar = this.signatureQuantity - this.signedAmount;
    }
}
