import { TagColorEnum } from '../enum/tag-color';
import { ITags } from './paper.model';

export interface Tag {
  id: number;
  name: string;
  color: TagColorEnum | string;
}

export function getTagColor(name: string): TagColorEnum | string {
  switch (name) {
    case 'red':
      return TagColorEnum['red'];
    case 'pink':
      return TagColorEnum['pink'];
    case 'purple':
      return TagColorEnum['purple'];
    case 'deep-purple':
      return TagColorEnum['deep-purple'];
    case 'indigo':
      return TagColorEnum['indigo'];
    case 'blue':
      return TagColorEnum['blue'];
    case 'light-blue':
      return TagColorEnum['light-blue'];
    case 'cyan':
      return TagColorEnum['cyan'];
    case 'teal':
      return TagColorEnum['teal'];
    case 'green':
      return TagColorEnum['green'];
    case 'light-green':
      return TagColorEnum['light-green'];
    case 'lime':
      return TagColorEnum['lime'];
    case 'yellow':
      return TagColorEnum['yellow'];
    case 'orange':
      return TagColorEnum['orange'];
    case 'deep-orange':
      return TagColorEnum['deep-orange'];
    case 'brown':
      return TagColorEnum['brown'];
    case 'grey':
      return TagColorEnum['grey'];
    case 'blue-grey':
      return TagColorEnum['blue-grey'];
  }

  return name;
}
