<div class="row p-3 modal-instal-lacuna-web-pki">
    <div class="col-md-12 d-flex justify-content-end">
        <a (click)="cancel()">
            <i class="np np-close iconBody26"></i>
        </a>
    </div>
    <div class="col-md-12 d-flex justify-content-center my-3">
        <app-avatar icon="np-hourglass" ></app-avatar>
    </div>
    <div class="col-md-12 text-center">
        <h3 class="text-center" class="Heading20"><strong>Instalar extensão</strong></h3>
        <p class="message-modal mx-4">
            {{message}}
        </p>
    </div>
    <div class="col-md-12 mb-5 mt-2 d-flex justify-content-center">
        <button (click)="cancel()" class="btn btn-secondary mr-3">
            <i class="np np-error mr-2 iconBody18"></i>
            <span>Fechar</span>
        </button>
        <button (click)="confirm()" class="btn btn-primary">
            <i class="np np-angle-right mr-2 iconBody18"></i>
            <span>instalar</span>
        </button>
    </div>
</div>