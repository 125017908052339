import { CurrencyMaskConfig, CurrencyMaskInputMode } from './currency-mask.config';
import { InputManager } from './input.manager';

export class InputService {
    private SINGLE_DIGIT_REGEX: RegExp = new RegExp(/^[0-9\u0660-\u0669\u06F0-\u06F9]$/);
    private ONLY_NUMBERS_REGEX: RegExp = new RegExp(/[^0-9\u0660-\u0669\u06F0-\u06F9]/g);

    PER_AR_NUMBER: Map<string, string> = new Map<string, string>();
    public inputManager: InputManager;

    initialize(): void {
        this.PER_AR_NUMBER.set('\u06F0', '0');
        this.PER_AR_NUMBER.set('\u06F1', '1');
        this.PER_AR_NUMBER.set('\u06F2', '2');
        this.PER_AR_NUMBER.set('\u06F3', '3');
        this.PER_AR_NUMBER.set('\u06F4', '4');
        this.PER_AR_NUMBER.set('\u06F5', '5');
        this.PER_AR_NUMBER.set('\u06F6', '6');
        this.PER_AR_NUMBER.set('\u06F7', '7');
        this.PER_AR_NUMBER.set('\u06F8', '8');
        this.PER_AR_NUMBER.set('\u06F9', '9');

        this.PER_AR_NUMBER.set('\u0660', '0');
        this.PER_AR_NUMBER.set('\u0661', '1');
        this.PER_AR_NUMBER.set('\u0662', '2');
        this.PER_AR_NUMBER.set('\u0663', '3');
        this.PER_AR_NUMBER.set('\u0664', '4');
        this.PER_AR_NUMBER.set('\u0665', '5');
        this.PER_AR_NUMBER.set('\u0666', '6');
        this.PER_AR_NUMBER.set('\u0667', '7');
        this.PER_AR_NUMBER.set('\u0668', '8');
        this.PER_AR_NUMBER.set('\u0669', '9');
    }


    constructor(private htmlInputElement: any, private options: CurrencyMaskConfig) {
        this.inputManager = new InputManager(htmlInputElement, this.options.nullable);
        this.initialize();
    }

    addNumber(keyCode: number): void {
        const { decimal, precision, inputMode } = this.options;
        const keyChar = String.fromCharCode(keyCode);
        const isDecimalChar = keyChar === this.options.decimal;

        if (!this.rawValue) {
            this.rawValue = this.applyMask(false, keyChar);
            let selectionStart: number | undefined;
            if (inputMode === CurrencyMaskInputMode.NATURAL && precision > 0) {
                selectionStart = this.rawValue.indexOf(decimal);
                if (isDecimalChar) {
                    selectionStart++;
                }
            }
            this.updateFieldValue(selectionStart);
        } else {
            const selectionStart = this.inputSelection.selectionStart;
            const selectionEnd = this.inputSelection.selectionEnd;
            const rawValueStart = this.rawValue.substring(0, selectionStart);
            let rawValueEnd = this.rawValue.substring(selectionEnd, this.rawValue.length);

            // In natural mode, replace decimals instead of shifting them.
            const inDecimalPortion = rawValueStart.indexOf(decimal) !== -1;
            if (inputMode === CurrencyMaskInputMode.NATURAL && inDecimalPortion && selectionStart === selectionEnd) {
                rawValueEnd = rawValueEnd.substring(1);
            }

            const newValue = rawValueStart + keyChar + rawValueEnd;
            let nextSelectionStart = selectionStart + 1;
            const isDecimalOrThousands = isDecimalChar || keyChar === this.options.thousands;
            if (isDecimalOrThousands && keyChar === rawValueEnd[0]) {
                // If the cursor is just before the decimal or thousands separator and the user types the
                // decimal or thousands separator, move the cursor past it.
                nextSelectionStart++;
            } else if (!this.SINGLE_DIGIT_REGEX.test(keyChar)) {
                // Ignore other non-numbers.
                return;
            }

            this.rawValue = newValue;
            this.updateFieldValue(nextSelectionStart);
        }
    }

    applyMask(isNumber: boolean, rawValue: string, disablePadAndTrim = false): string {
        const options = this.options;

        rawValue = isNumber ? Number(rawValue).toFixed(options.precision) : rawValue;
        let onlyNumbers = rawValue.replace(this.ONLY_NUMBERS_REGEX, '');

        if (!onlyNumbers && rawValue !== options.decimal) {
            return '';
        }

        if (options.inputMode === CurrencyMaskInputMode.NATURAL && !isNumber && !disablePadAndTrim) {
            rawValue = this.padOrTrimPrecision(rawValue);
            onlyNumbers = rawValue.replace(this.ONLY_NUMBERS_REGEX, '');
        }

        let integerPart = onlyNumbers.slice(0, onlyNumbers.length - options.precision)
            .replace(/^\u0660*/g, '')
            .replace(/^\u06F0*/g, '')
            .replace(/^0*/g, '');

        if (integerPart === '') {
            integerPart = '0';
        }
        const integerValue = parseInt(integerPart, 10);

        integerPart = integerPart.replace(/\B(?=([0-9\u0660-\u0669\u06F0-\u06F9]{3})+(?![0-9\u0660-\u0669\u06F0-\u06F9]))/g, options.thousands);
        if (options.thousands && integerPart.startsWith(options.thousands)) {
            integerPart = integerPart.substring(1);
        }

        let newRawValue = integerPart;
        const decimalPart = onlyNumbers.slice(onlyNumbers.length - options.precision);
        const decimalValue = parseInt(decimalPart, 100) || 0;

        const isNegative = rawValue.indexOf('-') > -1;

        // Ensure max is at least as large as min.
        options.max = (this.isNullOrUndefined(options.max) || this.isNullOrUndefined(options.min)) ? options.max : Math.max(options.max || 0, options.min || 0);

        // Ensure precision number works well with more than 2 digits
        // 23 / 100... 233 / 1000 and so on
        const divideBy = Number('1'.padEnd(options.precision + 1, '0'));

        // Restrict to the min and max values.
        let newValue = integerValue + (decimalValue / divideBy);

        newValue = isNegative ? -newValue : newValue;
        if (options.max === null && newValue > options.max) {
            return this.applyMask(true, options.max + '');
        } else if (options.min === null && newValue < options.min) {
            return this.applyMask(true, options.min + '');
        }

        if (options.precision > 0) {
            if (newRawValue === '0' && decimalPart.length < options.precision) {
                newRawValue += options.decimal + '0'.repeat(options.precision - 1) + decimalPart;
            } else {
                newRawValue += options.decimal + decimalPart;
            }
        }

        const isZero = newValue === 0;
        const operator = (isNegative && options.allowNegative && !isZero) ? '-' : '';
        return operator + options.prefix + newRawValue + options.suffix;
    }

    padOrTrimPrecision(rawValue: string): string {
        const { decimal, precision } = this.options;

        let decimalIndex = rawValue.lastIndexOf(decimal);
        if (decimalIndex === -1) {
            decimalIndex = rawValue.length;
            rawValue += decimal;
        }

        let decimalPortion = rawValue.substring(decimalIndex).replace(this.ONLY_NUMBERS_REGEX, '');
        const actualPrecision = decimalPortion.length;
        if (actualPrecision < precision) {
            for (let i = actualPrecision; i < precision; i++) {
                decimalPortion += '0';
            }
        } else if (actualPrecision > precision) {
            decimalPortion = decimalPortion.substring(0, decimalPortion.length + precision - actualPrecision);
        }

        return rawValue.substring(0, decimalIndex) + decimal + decimalPortion;
    }

    clearMask(rawValue: string | null): number | null {
        if ((this.isNullable() || this.isRequired()) && (rawValue === '' || rawValue === null)) {
            return null;
        }

        let value = (rawValue || '').replace(this.options.prefix, '').replace(this.options.suffix, '');

        if (this.options.thousands) {
            value = value.replace(new RegExp('\\' + this.options.thousands, 'g'), '');
        }

        if (this.options.decimal) {
            value = value.replace(this.options.decimal, '.');
        }

        this.PER_AR_NUMBER.forEach((val: string, key: string) => {
            const re = new RegExp(key, 'g');
            value = value.replace(re, val);
        });
        return parseFloat(value);
    }

    changeToNegative(): void {
        if (this.options.allowNegative && this.rawValue !== null && this.rawValue !== '' && this.rawValue.charAt(0) !== '-' && this.value !== 0) {
            // Apply the mask to ensure the min and max values are enforced.
            this.rawValue = this.applyMask(false, '-' + this.rawValue);
        }
    }

    changeToPositive(): void {
        if (this.rawValue === null) {
            return;
        }
        // Apply the mask to ensure the min and max values are enforced.
        this.rawValue = this.applyMask(false, this.rawValue.replace('-', ''));
    }

    removeNumber(keyCode: number): void {
        const { decimal, thousands, prefix, suffix, inputMode } = this.options;

        if (this.isNullable() && this.value === 0 || this.rawValue === null) {
            this.rawValue = null;
            return;
        }

        let selectionEnd = this.inputSelection.selectionEnd;
        let selectionStart = this.inputSelection.selectionStart;

        const suffixStart = this.rawValue.length - suffix.length;
        selectionEnd = Math.min(suffixStart, Math.max(selectionEnd, prefix.length));
        selectionStart = Math.min(suffixStart, Math.max(selectionStart, prefix.length));

        // Check if selection was entirely in the prefix or suffix.
        if (selectionStart === selectionEnd &&
            this.inputSelection.selectionStart !== this.inputSelection.selectionEnd) {
            this.updateFieldValue(selectionStart);
            return;
        }

        let decimalIndex = this.rawValue.indexOf(decimal);
        if (decimalIndex === -1) {
            decimalIndex = this.rawValue.length;
        }

        let shiftSelection = 0;
        let insertChars = '';
        const isCursorInDecimals = decimalIndex < selectionEnd;
        const isCursorImmediatelyAfterDecimalPoint = decimalIndex + 1 === selectionEnd;
        if (selectionEnd === selectionStart) {
            if (keyCode === 8) {
                if (selectionStart <= prefix.length) {
                    return;
                }
                selectionStart--;

                // If previous char isn't a number, go back one more.
                if (!this.rawValue.substr(selectionStart, 1).match(/\d/)) {
                    selectionStart--;
                }

                // In natural mode, jump backwards when in decimal portion of number.
                if (inputMode === CurrencyMaskInputMode.NATURAL && isCursorInDecimals) {
                    shiftSelection = -1;
                    // when removing a single whole number, replace it with 0
                    if (isCursorImmediatelyAfterDecimalPoint && this.value !== null && this.value < 10 && this.value > -10) {
                        insertChars += '0';
                    }
                }
            } else if (keyCode === 46 || keyCode === 63272) {
                if (selectionStart === suffixStart) {
                    return;
                }
                selectionEnd++;

                // If next char isn't a number, go one more.
                if (!this.rawValue.substr(selectionStart, 1).match(/\d/)) {
                    selectionStart++;
                    selectionEnd++;
                }
            }
        }

        // In natural mode, replace decimals with 0s.
        if (inputMode === CurrencyMaskInputMode.NATURAL && selectionStart > decimalIndex) {
            const replacedDecimalCount = selectionEnd - selectionStart;
            for (let i = 0; i < replacedDecimalCount; i++) {
                insertChars += '0';
            }
        }

        let selectionFromEnd = this.rawValue?.length || 0 - selectionEnd;
        this.rawValue = this.rawValue.substring(0, selectionStart) + insertChars + this.rawValue.substring(selectionEnd);

        // Remove leading thousand separator from raw value.
        const startChar = this.rawValue.substr(prefix.length, 1);
        if (startChar === thousands) {
            this.rawValue = this.rawValue.substring(0, prefix.length) + this.rawValue.substring(prefix.length + 1);
            selectionFromEnd = Math.min(selectionFromEnd, this.rawValue.length - prefix.length);
        }

        this.updateFieldValue(this.rawValue.length - selectionFromEnd + shiftSelection, true);
    }

    updateFieldValue(selectionStart?: number, disablePadAndTrim = false): void {
        const newRawValue = this.applyMask(false, this.rawValue || '', disablePadAndTrim);
        selectionStart = selectionStart === undefined ? this.rawValue?.length || 0 : selectionStart;
        selectionStart = Math.max(this.options.prefix.length, Math.min(selectionStart, this.rawValue?.length || 0 - this.options.suffix.length));
        this.inputManager.updateValueAndCursor(newRawValue, this.rawValue?.length || 0, selectionStart);
    }

    updateOptions(options: any): void {
        const value: number | null = this.value;
        this.options = options;
        this.value = value;
    }

    isRequired(): boolean {
        return this.options.required;
    }

    prefixLength(): number {
        return this.options.prefix.length;
    }

    suffixLength(): number {
        return this.options.suffix.length;
    }

    isNullable(): boolean {
        return this.options.nullable;
    }

    get canInputMoreNumbers(): boolean {
        return this.inputManager.canInputMoreNumbers;
    }

    get inputSelection(): any {
        return this.inputManager.inputSelection;
    }

    get rawValue(): string | null {
        return this.inputManager.rawValue;
    }

    set rawValue(value: string | null) {
        this.inputManager.rawValue = value;
    }

    get storedRawValue(): string | null {
        return this.inputManager.storedRawValue;
    }

    get value(): number | null {
        return this.clearMask(this.rawValue);
    }

    set value(value: number | null) {
        this.rawValue = this.applyMask(true, '' + value);
    }

    private isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }
}
