import { Progress } from '../../models/progress';
import { SendDetailComponent } from './send-detail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsDetail } from '../../models/detail';
import { SendDetail } from '../../models/detail';
import { Tag } from '../../models/tag';
import { Injectable } from '@angular/core';
import { RegistrationSentEmails } from '../../models/detail';

@Injectable({
  providedIn: 'root',
})
export class SendDetailService {
  constructor(private modalService: NgbModal) {}

  modalSendDetail(selectSendDetail: SendDetail) {
    const modalRef = this.modalService.open(SendDetailComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.dateSendDetail =
      this.filterDateSendDetail(selectSendDetail);
    modalRef.componentInstance.registrationSentEmails =
      this.getRegistrationSentEmails();
    modalRef.componentInstance.documents = this.getDocuments();
  }
  filterDateSendDetail(selectSendDetail: SendDetail) {
    if (selectSendDetail.discarded) {
      return this.getDateSendDiscarded().find(
        date => date.id == selectSendDetail.id,
      );
    }
    return this.getDateSendDetail().find(
      date => date.id == selectSendDetail.id,
    );
  }

  getDateSendDetail(): SendDetail[] {
    return [
      {
        id: 1,
        type: 'send', //send || received
        typeSignature: 'eletronic', //digital || eletronic
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [1]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: false,
      },
      {
        id: 2,
        type: 'received',
        typeSignature: 'digital',
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [2]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: false,
      },
      {
        id: 3,
        type: 'send',
        typeSignature: 'eletronic',
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [3]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: false,
      },
      {
        id: 4,
        type: 'received',
        typeSignature: 'eletronic',
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [4]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: false,
      },
      {
        id: 5,
        type: 'send',
        typeSignature: 'eletronic',
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [5]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: false,
      },
    ];
  }

  getDateSendDiscarded(): SendDetail[] {
    return [
      {
        id: 6,
        type: 'send',
        typeSignature: 'eletronic',
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [6]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: true,
      },
      {
        id: 7,
        type: 'received',
        typeSignature: 'eletronic',
        sendBy: 'Camila Abel',
        managedBy: 'Tomás Machado',
        time: new Date(2020, 7, 20, 17, 15, 0),
        subject: 'Termo de retirada [7]',
        message:
          ' Declaro ainda que me comprometo a devolver os mesmos nas mesmas condições do momento da retirada. Quais sejam, os equipamentos funcionam perfeitamente e se encontram em perfeitas condições de uso e em bom estado de conservação.',
        documents: 2,
        tags: this.getTags(),
        progressSignature: this.getProgress(),
        discarded: true,
      },
    ];
  }

  getProgress(): Progress {
    return {
      loading: Math.floor(Math.random() * (6 - 0) + 0), //remover Math
      total: 5, ///remover
    };
  }

  getRegistrationSentEmails(): RegistrationSentEmails[] {
    return [
      {
        email: 'camila.batista@decisaosistemas.com.br	',
        send: new Date(2020, 7, 20, 17, 15, 0),
        received: new Date(2020, 7, 20, 17, 15, 0),
        open: new Date(2020, 7, 20, 17, 15, 0),
        clickOnLink: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        email: 'tomas.machado@decisaosistemas.com.br	',
        send: new Date(2020, 7, 20, 17, 15, 0),
        received: new Date(2020, 7, 20, 17, 15, 0),
        open: new Date(2020, 7, 20, 17, 15, 0),
        clickOnLink: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        email: ' contato@decisaosistemas.com.br	',
        send: new Date(2020, 7, 20, 17, 15, 0),
        received: new Date(2020, 7, 20, 17, 15, 0),
        open: undefined,
        clickOnLink: undefined,
      },
    ];
  }

  private getTags(): Tag[] {
    return [
      {
        id: 1,
        name: 'Adm',
        color: '#26CECE',
      },
      {
        id: 2,
        name: 'Financeiro',
        color: '#FFB31A',
      },
    ];
  }

  getDocuments(): DocumentsDetail[] {
    return [
      {
        nameDocument: 'Termo de retirada de equipamento',
        subscribers: [
          {
            typeSignature: 'digital',
            name: 'Decisão Sistemas',
            email: 'contato@decisaosistemas.com.br',
            identification: 25076589000132,
            paper: 'Responsável solidário',
            situation: 'e-CNPJ',
            date: new Date(2020, 7, 20, 17, 15, 0),
          },
          {
            typeSignature: 'eletronic',
            name: 'Tomás Machado',
            email: 'tomas.machado@decisaosistemas.com.br',
            identification: 45715205069,
            paper: 'Representante',
            situation: null,
            date: null,
          },
        ],
      },
      {
        nameDocument: 'Documento de contrato',
        subscribers: [
          {
            typeSignature: 'digital',
            name: 'Camila Abel',
            email: 'camila.batista@decisaosistemas.com.br',
            identification: 30998891134,
            paper: 'Signatário do contrato',
            situation: null,
            date: null,
          },
          {
            typeSignature: 'eletronic',
            name: 'Tomás Machado',
            email: 'tomas.machado@decisaosistemas.com.br',
            identification: 45715205069,
            paper: 'Representante',
            situation: 'e-CNPJ',
            date: new Date(2020, 7, 20, 17, 15, 0),
          },
        ],
      },
    ];
  }
}
