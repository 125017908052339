<div class="app-login-certificate">
    <div class="d-flex justify-content-between">
        <div class="login-certificate-title" [ngClass]="{'mobile': isVisaoMobile, 'tablet-desktop': !isVisaoMobile}">
            Selecione o certificado desejado!</div>
        <div>
            <a (click)="closed()">
                <i class="np np-close-doc"></i>
            </a>
        </div>

    </div>

    <div class="mt-2">
        <app-select-search-certificate [certificates]="certificates" [formSelect]="formControlCertificate"
            (eventSelectOption)="onClickSelectCertificate($event)"></app-select-search-certificate>
    </div>
</div>