<div class="content-info">
  <div class="send-managed">
    <div class="button d-flex mb-3">
      <button
        *ngFor="let button of buttons"
        class="btn-icon mr-1"
        ngbTooltip="{{ button.text }}"
        tooltipClass="btn-tooltip"
        (click)="onButton(button.type)"
      >
        <i class="{{ button.icon }}"> </i>
      </button>
      <button
        class="btn-icon btn-clip d-flex align-items-center p-2"
        ngbTooltip="Documentos "
        tooltipClass="btn-tooltip"
        (click)="onButton('documentCard')"
      >
        <i class="np-clip mr-1"></i>
        <span class="wBody14">{{ dateSendDetail.documents }}</span>
      </button>
    </div>
    <div class="wBody14">
      <div class="textSendManager">
        <span class="Heading16">Enviado por: </span>
        {{ dateSendDetail.sendBy }}
        <br class="br" />
        <span class="Heading16">Gerenciado por:</span>
        {{ dateSendDetail.managedBy }}
      </div>
      <div class="wBody12 d-flex align-items-center time">
        <i class="np-clock mr-1"></i>
        {{ dateSendDetail.time | date: 'dd MMM YYYY - hh:MM' }}
      </div>
      <div class="my-2 bagde-tag">
        <app-bagde-tag [tags]="dateSendDetail.tags"></app-bagde-tag>
      </div>
    </div>
  </div>
  <hr class="line p-0 my-2" />
  <div class="info wBody14">
    <span class="Heading16">Assunto:</span>
    {{ dateSendDetail.subject }}
    <br />
    <span class="Heading16">Mensagem:</span>
    {{ dateSendDetail.message }}
  </div>
</div>
