<div class="modal-confirm-descart-paper" *ngIf="showConfirm">
    <div class="body-confirm px-4 py-3">
        <div class="d-flex flex-row-reverse color-300">
            <i class="np-close iconBody22" (click)="close()"></i>
        </div>
        <div class="d-flex justify-content-center">
            <app-avatar
                icon="np-warning-2"
                color="danger"
            ></app-avatar>
        </div>
        <div class="Heading20 m-3">
            Deseja descarta esse documento?
        </div>
        <div class="wBody14 color-400">
            Após confirmação os documentos serão
            <br>
            movidos para descartados!
        </div>
        <div class="buttons d-flex m-3 justify-content-around">
            <button class="btn btn-secondary body14" (click)="close()">
                <i class="np-error mr-2"></i>
                Cancelar
            </button>
            <button class="btn btn-danger body14" (click)="delete()">
                <i class="np-trash mr-2"></i>
                Sim, descartar
            </button>
        </div>
    </div>
</div>