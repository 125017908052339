import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsDetail } from '../../models/detail';
import { SendDetail } from '../../models/detail';
import { RegistrationSentEmails } from '../../models/detail';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-detail',
  templateUrl: './send-detail.component.html',
  styleUrls: ['./send-detail.component.scss'],
})
export class SendDetailComponent implements OnInit {
  @Input() dateSendDetail: SendDetail;
  @Input() registrationSentEmails: RegistrationSentEmails[];
  @Input() documents: DocumentsDetail[];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  onClose() {
    this.activeModal.close();
  }

  onButton(type: string) {
    console.log(type);
  }
}
