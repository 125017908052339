import { CertificateModel } from 'web-pki';
import { ICertificate } from './../models/certificate';

export class Certificate implements ICertificate {
    public readonly cnpj: string | undefined;
    public readonly cpf: string;
    public readonly thumbprint: string;
    public readonly issuerName: string;
    public readonly expirationDate: Date;
    public readonly dateIssue: Date;
    public readonly certificateType: string;
    public readonly responsible: string;
    public readonly email: string;
    public readonly isRemote: boolean | undefined;
    public readonly isPhysicalPerson: boolean;
    public readonly isLegalEntity: boolean;
    public readonly subjectName: string;
    public readonly companyName: string;
    public readonly rawCertificate: CertificateModel;

    constructor(certificate: CertificateModel) {
        this.cnpj = certificate.pkiBrazil.cnpj || undefined;
        this.cpf = certificate.pkiBrazil.cpf;
        this.thumbprint = certificate.thumbprint;
        this.issuerName = certificate.issuerName;
        this.expirationDate = new Date(certificate.validityEnd);
        this.dateIssue = new Date(certificate.validityStart);
        this.certificateType = certificate.pkiBrazil.certificateType;
        this.responsible = certificate.pkiBrazil.responsavel;
        this.email = certificate.email;
        this.isRemote = certificate.isRemote;
        this.isPhysicalPerson = certificate.pkiBrazil.isPessoaFisica;
        this.isLegalEntity = certificate.pkiBrazil.isPessoaJuridica;
        this.subjectName = certificate.subjectName;
        this.companyName = certificate.pkiBrazil.companyName;
        this.rawCertificate = certificate;
    }

    public isExpired(pDataReferencia: Date = new Date()): boolean {
        return this.expirationDate.getTime() < pDataReferencia.getTime();
    }
}
