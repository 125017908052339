import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IValorLabel } from '../../models/valor-label';
import { geradorStringAleatorio } from '../../utils/string-util';
import { BehaviorSelectComponent } from '../behavior-select/behavior-select.component';

@Component({
  selector: 'app-select-normal',
  templateUrl: './select-normal.component.html',
  styleUrls: ['./select-normal.component.scss']
})
export class SelectNormalComponent extends BehaviorSelectComponent implements OnInit {

  @Input() public formulario: FormControl;
  public optionSelect: IValorLabel;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.id === '') {
      this.id = geradorStringAleatorio(10);
    }
    this.verifyOptionDefaultAndSelect();
  }

  public verifyOptionDefaultAndSelect(): void {
    this.options.forEach(option => {
      if (option.valor === this.formulario.value) {
        this.onSelectOption(option);
        return;
      }
    });
  }

  public onSelectOption(pOption: IValorLabel): void {
    this.optionSelect = pOption;
    this.formulario.setValue(pOption.valor);
    this.eventSelectOption.emit(pOption);
  }

}
