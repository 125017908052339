<div class="app-form-new-password">
    <div class="mt-6">
        <span class="redefine-password"><b>Redefinir senha!</b></span>
    </div>
    <div class="mt-3">
        <span class="redefine-link">Crie uma senha forte e uma conta mais segura</span>
    </div>
    <form>
        <div class="row">
            <div class="col-md-12">
                <div class="inner-addon right-addon">
                    <app-input (keyup)="checkConfirmationIqual()" [inputControl]="senhaControl" label='Nova Senha'
                        [labelErro]="'a senha'" [isRequired]="true" aria-describedby="icon-input" placeholder="Senha">
                    </app-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="inner-addon right-addon">
                    <app-input (keyup)="checkConfirmationIqual()" [inputControl]="confirmarSenhaControl" label='Confirme nova senha' [labelErro]="'a senha'"
                        [isRequired]="true" aria-describedby="icon-input" placeholder="Senha">
                    </app-input>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <button style="height: 37px;" class="btn btn-primary w-100"
                [disabled]="!formulario.valid" (click)="submit()">
                    Confirmar
                    <i class="np-angle-right iconBody18 ml-2"></i>
                </button>
            </div>
        </div>
    </form>
    <div class="mt-3">
        <p class="register" (click)="clearFields();">Limpar campos</p>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <a [routerLink]="['../']">
                <button class="btn btn-return w-100">
                    Retornar para login
                </button>
            </a>
        </div>
    </div>
    <div class="col-12 mt-3 meet-npaper">
        <a href="https://npaper.com.br"><b>Clique e conheça o Npaper</b></a>
    </div>
</div>