import { Injectable } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './modal-delete.component';
import { ModalDelete } from '../../models/modal-delete.model';

@Injectable({
  providedIn: 'root',
})
export class ModalDeleteService {
  constructor(private modalService: NgbModal) { }

  private modalDeleteView(view: ModalDelete) {
    const modalRef = this.modalService.open(ModalDeleteComponent);
    modalRef.componentInstance.viewModalDelete = view;
    return (<ModalDeleteComponent>modalRef.componentInstance).confirmResult$;
  }

  confirmationDeleteTag() {
    return this.modalDeleteView({
      iconAvatar: 'np-trash',
      title: 'Deseja excluir marcador?',
      text: 'Essa ação não poderá ser revertida. Marcador associado a um ou mais documentos!',
      btnSaveText: 'Sim, excluir',
      btnSave: 'confirmationDeleteTag',
    });
  }
  onAlertUserNotDelete() {
    return this.modalDeleteView({
      iconAvatar: 'np-warning-2',
      title: 'O usuário não pode ser excluído.',
      text: 'O usuário selecionado é proprietário de pastas e documentos. Para exclui-lo você deve atribuir a outra pessoa a função de proprietário. ',
      btnSaveText: 'Alterar proprietário',
      btnSave: 'alertUserNotDelete',
    });
  }
}
