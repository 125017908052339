import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/shared/components/settings/loading/loading.service';
import { IValorLabel } from '../../models/valor-label';
import { ToasterService } from '../toaster/toaster.service';
import { Certificate } from './../../classes/certificate';
import { DateUtil } from './../../utils/date-util';
import { LacunaWebPkiService } from './../lacuna-web-pki/lacuna-web-pki.service';

@Component({
  selector: 'app-select-search-certificate',
  templateUrl: './select-search-certificate.component.html',
  styleUrls: ['./select-search-certificate.component.scss'],
})
export class SelectSearchCertificateComponent
  implements OnInit, OnDestroy, OnChanges
{
  public optionCertificate: IValorLabel[] = [];

  @Input() certificates: Certificate[] = [];
  @Input() formSelect: FormControl;
  @Output() eventSelectOption: EventEmitter<Certificate> =
    new EventEmitter<Certificate>();

  constructor(
    private lacunaWebPkiService: LacunaWebPkiService,
    private loadingService: LoadingService,
    private toasterService: ToasterService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.certificates) {
      this.clearListOptionCertificate();
      this.generateOptionCertificate(changes.certificates.currentValue);
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clearListOptionCertificate();
    this.eventSelectOption.unsubscribe();
  }

  private generateOptionCertificate(certificates: Certificate[]): void {
    certificates.forEach(certificate => {
      this.optionCertificate.push({
        valor: certificate.cnpj || certificate.cpf,
        label: this.generateLabelHtml(certificate),
      });
    });
  }

  private clearListOptionCertificate(): void {
    this.optionCertificate = [];
  }

  private generateLabelHtml(certificate: Certificate): string {
    const cpfCnpj = certificate.cpf || certificate.cnpj;
    const responsavel = certificate.responsible;
    const validade = DateUtil.format(certificate.expirationDate, 'DD/MM/YYYY');
    const type =
      certificate.certificateType === 'Unknown'
        ? 'A1'
        : certificate.certificateType;
    const nameCertitifacate = certificate.issuerName;

    return `<strong>${responsavel}</strong> - ${cpfCnpj}\n${validade} - ${type} - ${nameCertitifacate}`;
  }

  public onEventSelectOption(option: IValorLabel): void {
    const certificateSelect = this.certificates.find(
      certificate =>
        certificate.cpf === option.valor || certificate.cnpj === option.valor,
    );
    this.formSelect.setValue(option.valor);
    this.eventSelectOption.emit(certificateSelect);
  }

  public async refreshCertificate(): Promise<void> {
    this.loadingService.showLoader();
    this.clearListOptionCertificate();
    try {
      this.generateOptionCertificate(
        await this.lacunaWebPkiService.getCertificates(),
      );
    } catch (error: any) {
      this.toasterService.showInfo(error.message);
    }
    this.loadingService.hideLoader();
  }
}
