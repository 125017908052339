import { Injectable } from '@angular/core';
import { Location, UserLocation } from './location.model';

@Injectable()
export class LocationService {
    constructor() { }

    public getLocation(): Promise<UserLocation> {
        return new Promise((resolve) => {
            let erroLocation: boolean = true;
            let erroDescription: string | null = null;
            let location: Location | null = null;

            navigator.geolocation.getCurrentPosition((pPosition) => {
                erroLocation = false;
                location = new Location(pPosition.coords.latitude, pPosition.coords.longitude);
                resolve(new UserLocation(erroLocation, erroDescription, location));
            }, (pPositionError) => {
                erroLocation = true;
                switch (pPositionError.code) {
                    case pPositionError.PERMISSION_DENIED:
                        erroDescription = 'User denied the request for Geolocation.';
                        break;
                    case pPositionError.POSITION_UNAVAILABLE:
                        erroDescription = 'Location information is unavailable.';
                        break;
                    case pPositionError.TIMEOUT:
                        erroDescription = 'The request to get user location timed out.';
                        break;
                    default:
                        erroDescription = 'An unknown error occurred.';
                        break;
                }
                resolve(new UserLocation(erroLocation, erroDescription, location));
            });
        });
    }
}
