import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NAssinarDocumentoModel } from '../../models/assinar-documento.model';

@Component({
  selector: 'app-modal-sucesso-assinatura',
  templateUrl: './modal-sucesso-assinatura.component.html',
  styleUrls: ['./modal-sucesso-assinatura.component.scss']
})
export class ModalSucessoAssinaturaComponent implements OnInit {
  @Input() assinantes: NAssinarDocumentoModel.IDocumentoIvolved[] = [];
  public qtdAssinados = 0;

  constructor(
    private readonly activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void { }

  public cancelamento(): void {
    this.activeModal.close();
  }
}
