import { ModalDelete } from '../../models/modal-delete.model';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, of } from 'rxjs';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModalDeleteComponent implements OnInit {
  @Input() viewModalDelete: ModalDelete;
  confirmResult$: Subject<boolean> = new Subject();

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onConfirm() {
    this.confirmAndClose(true);
  }

  onCancel() {
    this.confirmAndClose(false);
  }
  private confirmAndClose(value: boolean) {
    this.confirmResult$?.next(value);
    this.activeModal.close();
  }
}
