import { ValueCheckdEvent } from './../../models/BehaviorCheckboxRadioInput';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TypeSignatureEnum } from '../../enum/type-signature';
import { Tag } from '../../models/tag';
import { TypeEventCardEnum } from '../../enum/event-card.enum';
import { IEventCard } from '../../models/Event-card.model';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TagService } from '../tag/tag.service';
import { TagsModel } from '../../models/tagsModel';
import { ITag } from '../../models/paper.model';
import { LayoutService } from '../../services/layout/layout.service';
import { LayoutVisaoEnum } from '../../enum/layout-visao.enum';

@Component({
  selector: 'app-card-documents',
  templateUrl: './card-documents.component.html',
  styleUrls: ['./card-documents.component.scss'],
  providers: [NgbDropdownConfig]
})
export class CardDocumentsComponent implements OnInit, OnDestroy {
  public formCheck: FormControl;

  @Input() public id: number;
  @Input() public typeSignature: TypeSignatureEnum;
  @Input() public date: string;
  @Input() public signed: number;
  @Input() public totalSign: number;
  @Input() public subject: string;
  @Input() public tags: Tag[];
  @Input() public fullySigned: boolean = false;
  @Input() public titulo: string;
  @Input() chamador: 'area-logged' | 'area-not-logged';
  @Input() situacaoPaper: 'enviados' | 'descartados';

  @Output() public eventCard = new EventEmitter<IEventCard>();

  @Input() public event: EventEmitter<ValueCheckdEvent>;
  @Output() public checkboxEvent = new EventEmitter<{ id: number, values: ValueCheckdEvent }>();

  public currentTags: TagsModel[];
  public tagsSelected: ITag[] = [];
  public isVisaoMobile: boolean = false;

  public telaChamador: string | null = null;

  constructor(
    config: NgbDropdownConfig,
    private tagService: TagService,
    private layoutVisao: LayoutService
  ) {
    config.placement = 'top-start';
  }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
    this.formCheck = new FormControl(false);

    this.event.subscribe((values: any) => {
      this.formCheck.setValue(values.value);
      this.checkboxEmmiter(values);
    });
  }

  ngOnDestroy(): void {
    this.eventCard.unsubscribe();
  }

  public onDeleteTag(tag: number, isTagSelected: boolean): void {
    let tagAllSelected: ITag[] = [];
    tagAllSelected = isTagSelected ? this.tagsSelected : [];
    if (this.situacaoPaper !== 'descartados') {
      this.eventCard.emit({ typeEvent: TypeEventCardEnum.DELETETAG, id: this.id, idTag: tag, tagsSelected: tagAllSelected });
    }
  }

  public onSendClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.SEND, id: this.id });
  }

  public onRestoreClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.RESTORE, id: this.id });
  }

  public onTrashClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.TRASH, id: this.id });
  }

  public onTagClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.TAG, id: this.id, tagsSelected: this.tagsSelected });
  }

  public onFolderClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.FOLDER, id: this.id });
  }

  public onDownLoadClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.DOWNLOAD, id: this.id });
  }

  public onNextClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.NEXT, id: this.id });
  }

  public onChangeCheckbox(values: ValueCheckdEvent): void {
    this.formCheck.setValue(values.value);
    this.checkboxEmmiter(values);
  }

  public async selecionarTags() {
    await this.carregarTags();
  }

  public salvarTags() {
    this.onTagClick();
    this.tagsSelected = [];
  }

  public isCheckedItem(itemChecked: boolean | undefined, index: number) {
    if (itemChecked) {
      this.currentTags[index].isChecked = true;
      this.tagsSelected.push({ tagId: this.currentTags[index].tagId });
    } else {
      this.currentTags[index].isChecked = false;

      const indexOf = this.tagsSelected.findIndex(field => field.tagId === this.currentTags[index].tagId);

      this.tagsSelected.splice(indexOf, 1);
    }
  }

  private checkboxEmmiter(values: ValueCheckdEvent): void {
    this.checkboxEvent.emit({ id: this.id, values });
  }

  private async carregarTags() {
    try {
      this.currentTags = await this.tagService.getTags();

      this.currentTags.forEach((item) => {
        item.isChecked = false;
      })

      /** Se tiver tag já marcada no paper */
      if (this.tags.length > 0) {
        for (let indexY = 0; indexY < this.currentTags.length; indexY++) {
          const element = this.currentTags[indexY];

          for (let index = 0; index < this.tags.length; index++) {
            let tagId = this.tags[index].id;

            if (element.tagId === tagId) {
              this.isCheckedItem(!element.isChecked, indexY);
            }
          }
        }
      }
    } catch (error) {

    }
  }
}
