<div class="switch__container d-flex mt-2">
  <input
  [id]="id"
  class="switch switch--shadow"
  type="checkbox"
  [formControl]="form"
  [checked]="isChecked()"
  (click)="onChecked()"
  [ngClass]="form.disabled ? 'disabled' : ''"
  />
  <label [for]="id"></label>
  <span class="ml-2 body14 color-gray-500" [ngClass]="{'wBody14 color-gray-300': form.disabled}">
    {{label}}
    <ng-content select="[label]">
    </ng-content>
  </span>
</div>