<div class="row card-documents-lote">
    <div class="col-md-12 mb-3 " [ngClass]="{'mb-3': !isVisaoMobile, 'mb-0': isVisaoMobile}">
        <div class="card p-3 border">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <app-checkbox [form]="formCheck" (checkedEvent)="onChangeCheckbox($event)"></app-checkbox>
                    <div
                        class="border-secondary rounded mx-2 px-2 background-gray color-400 d-flex align-items-center ">
                        <i class="np-edit iconBody12 mr-1 "></i>
                        <span class="wBody10 my-1">{{signatureType}}</span>
                    </div>
                </div>
                <div class="d-flex align-itens-center">
                    <div class="border-secondary rounded px-1 background-gray color-400 d-flex align-items-center">
                        <i class="np-clock iconBody12 mr-1"></i>
                        <span class="wBody10 my-1">{{date}}</span>
                    </div>
                </div>
            </div>
            <div class="w-100">
                <hr style="margin-bottom: 8px;">
                <div class="quebra-texto" ngbTooltip="{{subject}}">
                    <span class="body14">
                        {{subject}}
                    </span>
                </div>
                <br>
                <hr style="margin-top: 8px;">
                <div class="d-flex justify-content-end">
                    <div class="icon-group">
                        <button class="btn btn-icon border mx-1 color-400" (click)="onNextClick()"
                            ngbTooltip="Detalhes">
                            <i class="np-angle-right iconBody18"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>