import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/shared/components/settings/loading/loading.service';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { IUser } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ModalUsersService } from '../../components/modal-users/modal-users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public users: Array<IUser>;
  public onlyOne: boolean;
  public idUserAuth: number;
  public isVisaoTablet: boolean = false;
  public isVisaoMobile: boolean = false;

  constructor(
    private userService: UserService,
    private modalUserService: ModalUsersService,
    private authService: AuthService,
    private layoutVisao: LayoutService,
    private loadingService: LoadingService,
    private toastrService: ToasterService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isVisaoTablet = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.TABLET);
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE)

    this.getUsers();
  }

  private async getUsers() {
    try {
      this.loadingService.showLoader();
      this.users = await this.userService.getUsers();

      this.orderUserByName();
      const user = this.authService.getUserLocal();
      if (user === null) {
        throw new Error('Usuário não autenticado');
      }
      this.idUserAuth = user.id;

      this.loadingService.hideLoader();
    } catch (error) {
      this.toastrService.showError('Não foi possível carregar a lista de usuários');
      this.loadingService.hideLoader();
    }
  }

  private orderUserByName(): void {
    this.users.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  public async addUser(): Promise<void> {
    const resultCreateUser = await this.modalUserService.open();
    if (resultCreateUser === undefined) {
      return;
    }
    this.users.push(resultCreateUser);
    this.orderUserByName();
  }

  public onDeleteUser(pUser: IUser): void {
    this.users = this.users.filter(user => user.id !== pUser.id);
  }

}
