import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeModalConfirm } from 'src/app/shared/enum/tipoModalEnum';
import { IUser } from 'src/app/shared/models/user.model';
import { ModalConfirmBlockerOrDeleteComponent } from './modal-confirm-blocker-or-delete.component';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmBlockerOrDeleteService {

  constructor(
    private modalService: NgbModal
  ) { }

  public async openBlock(user: IUser): Promise<{confirm: boolean}> {
    return await this.open(user, TypeModalConfirm.BLOCK);
  }

  public async openUnblock(user: IUser): Promise<{confirm: boolean}> {
    return await this.open(user, TypeModalConfirm.UNBLOCK);
  }

  public async openDelete(user: IUser): Promise<{confirm: boolean, idUserTransference?: number }> {
    return await this.open(user, TypeModalConfirm.DELETE);
  }

  public async openTransfer(user: IUser): Promise<{confirm: boolean, idUserTransference?: number}> {
    return await this.open(user, TypeModalConfirm.TRANSFER);
  }

  private async open(user: IUser, typeModalConfirm: TypeModalConfirm): Promise<{confirm: boolean, idUserTransference?: number}> {
    const modal = this.modalService.open(
      ModalConfirmBlockerOrDeleteComponent, {
      centered: true,
      backdropClass: 'backdrop-blur-filter',
    }
    );
    const instanceModal: ModalConfirmBlockerOrDeleteComponent = modal.componentInstance;
    instanceModal.usuarioExcluir = user;
    instanceModal.typeModal = typeModalConfirm;

    return modal.result;
  }

}
