<div class="app-modal-confirm-logout p-3 px-md-4 py-3">
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <a (click)="closeModal(false)">
                <i class="iconBody22 np np-close-doc np np-close-doc color-gray-300"></i>
            </a>
        </div>
        <div class="col-md-12 d-flex justify-content-center pb-2">
            <app-avatar icon="np-sign-out"></app-avatar>
        </div>
        <div class="col-md-12 d-flex justify-content-center my-2">
            <span class="Heading20 text-center">
                <strong class="text-nowrap d-flex justify-content-center">Atenção</strong>
                <strong class="text-nowrap">Deseja sair da aplicação?</strong>
            </span>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <button class="wbody14 btn btn-secondary mr-2 altura" (click)="closeModal(false)">
                <i class="iconBody18 mr-2 np np-error"></i> Cancelar
            </button>
            <button class="wbody14 btn btn-primary altura" (click)="closeModal(true)">
                <i class="iconBody18 mr-2 np np-done"></i> Sim, sair
            </button>
        </div>
    </div>
</div>