<div class="input-radio" [class]="disableCheckIfDisabledAndNotChecked()">
  <input
    (click)="onChecked()"
    [formControl]="form"
    [id]="id"
    type="radio"
    [name]="name"
    [value]="value"
  />
  <label [for]="id">{{label}}<ng-content select="[label]"></ng-content></label>
</div>
