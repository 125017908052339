import { notification, userLogged } from '../../../../shared/models/nav';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavNService {
  constructor() {}

  getUserLogged(): userLogged {
    return {
      name: 'Diego Silva',
      company: 'Decisão sistemas',
      email: 'diego@decisaosistemas.com.br',
    };
  }

  getSearch(): string[] {
    return ['Última busca', 'Penúltima busca', 'Antepenúltima busca'];
  }
  getNotification(): notification[] {
    return [
      {
        subject: 'Você foi convidado para acessar uma pasta compartilhada ',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
      {
        subject: 'Notificação de assinatura.',
        date: new Date(2020, 7, 20, 17, 15, 0),
      },
    ];
  }
}
