import { Tag } from '../../../models/tag';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bagde-tag',
  templateUrl: './bagde-tag.component.html',
  styleUrls: ['./bagde-tag.component.scss'],
})
export class BagdeTagComponent implements OnInit, OnDestroy {
  @Input() tags: Tag[];
  @Input() situacaoPaper: 'enviados' | 'descartados';
  @Output() deleteTag = new EventEmitter<{idTag: number}>();

  constructor() {}

  ngOnInit(): void {}

  public removeBadge(value: number): void {
    if(this.situacaoPaper !== 'descartados') {
      this.tags = this.tags.filter(tag => tag.id !== value);
      this.deleteTag.emit({idTag: value});
    }
  }

  ngOnDestroy(): void {
    this.deleteTag.unsubscribe();
  }
}
