import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { typedFormControlBuilder } from 'src/typedReactiveForm';
import { AssinaturaDesenhoComponent } from '../shared/components/assinatura-desenho/assinatura-desenho.component';
import { AssinaturaDigitarComponent } from '../shared/components/assinatura-digitar/assinatura-digitar.component';
import { IDataPickerFormControl, IDataPickerValues } from '../shared/components/data-picker/data-picker.model';
import { LoadingService } from '../shared/components/settings/loading/loading.service';
import { ToasterService } from '../shared/components/toaster/toaster.service';
import { ValueCheckboxRadioInput, ValueCheckdEvent } from '../shared/models/BehaviorCheckboxRadioInput';
import { IValorLabel } from '../shared/models/valor-label';
import { LoadingSignatureService } from './../shared/components/settings/loading-signature/loading-signature.service';
import { homeAvatarList } from './home.constant.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public dataPickerControlNormal: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>(null);
  public dataPickerControlFullRow: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>(null);
  public dataPickerControlRequired: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>(null, [Validators.required]);
  public dataPickerControlError: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>(null, [Validators.required]);
  public dataPickerControlInit: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>({
    year: 2022,
    month: 3,
    day: 19,
  });
  public dataPickerControlOutputEvent: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>(null);
  public dataPickerControlDisabled: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>(null);
  public dataPickerControlDisabledValue: IDataPickerFormControl = typedFormControlBuilder<IDataPickerValues | null>({
    year: 2022,
    month: 3,
    day: 19,
  });
  public formSelectNormal: FormControl = new FormControl('', [Validators.required]);
  public formSelectNormalDisabled: FormControl = new FormControl('', [Validators.required]);
  public formSelectNormalRequired: FormControl = new FormControl('', [Validators.required]);

  public optionsSelectNormal: IValorLabel[] = [
    {
      valor: 'Action - 1',
      label: 'Action - 1',
    },
    {
      valor: 'Another Action1',
      label: 'Another Action1',
    },
    {
      valor: 'Another Action2',
      label: 'Another Action2',
    },
    {
      valor: 'Another Action3',
      label: 'Another Action3',
    },
    {
      valor: 'Another Action4',
      label: 'Another Action4',
    },
    {
      valor: 'Something else is here',
      label: 'Something else is here',
    }
  ];

  public formControlCheckDefault: FormControl = this.factoryformControl();
  public formControlCheckSelect: FormControl = this.factoryformControl();
  public formControlCheckSelectDesabled: FormControl = this.factoryformControl();
  public formControlCheckDesabled: FormControl = this.factoryformControl();
  public formControlCustomizedLabel: FormControl = this.factoryformControl();
  public formControlCheckEmptyLabel: FormControl = this.factoryformControl();
  public formControlCheckEvent: FormControl = this.factoryformControl();
  public formGroupRadioControlAlternative: FormControl = this.factoryformControl('A');
  public formGroupRadioControlDefault: FormControl = this.factoryformControl();
  public formGroupRadioControlSelect: FormControl = this.factoryformControl();
  public formGroupRadioControlSelectDisabled: FormControl = this.factoryformControl();
  public formGroupRadioControlDisabled: FormControl = this.factoryformControl();
  public formGroupRadioControlCustomizedLabel: FormControl = this.factoryformControl();
  public formGroupRadioControlEmptyLabel: FormControl = this.factoryformControl();
  public formControlSwitchOn: FormControl = this.factoryformControl();
  public formControlSwitchOff: FormControl = this.factoryformControl();
  public formControlSwitchOffDesabled: FormControl = this.factoryformControl();
  public formControlSwitchOnDesabled: FormControl = this.factoryformControl();
  public formControlSwitchEmptyLabel: FormControl = this.factoryformControl();
  public formControlSwitchCustumizedLabel: FormControl = this.factoryformControl();
  public formControlSwitchEvent: FormControl = this.factoryformControl();
  public checkboxValueEvent: boolean = this.formControlCheckEvent.value;
  public switchValueEvent: boolean = this.formControlSwitchEvent.value;

  public inputFormControl = new FormControl('', [Validators.required]);
  public inputFormControlDefault = new FormControl('', [Validators.required]);
  public inputFormControlDisabled = new FormControl('', [Validators.required]);
  public inputFormControlSuccess = new FormControl('', [Validators.required]);
  public inputFormControlError = new FormControl('', [Validators.required]);

  public avatares: Array<string> = homeAvatarList;

  public formControlSignatureType = new FormControl('');
  @ViewChild('assinarDesenho') assinarDesenhoComponent!: AssinaturaDesenhoComponent;
  @ViewChild('assinarDigitar') assinarDigitarComponent!: AssinaturaDigitarComponent;

  constructor(
    private loadingSignatureService: LoadingSignatureService,
    private loadingService: LoadingService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.dataPickerControlError.markAsTouched();
    this.dataPickerControlDisabled.disable();
    this.dataPickerControlDisabledValue.disable();
    this.formSelectNormalDisabled.disable();
    this.formControlCheckSelect.setValue(false);
    this.formControlCheckSelectDesabled.setValue(false);
    this.formControlCheckSelectDesabled.disable();
    this.formControlCheckDesabled.disable();
    this.formGroupRadioControlSelect.setValue(false);
    this.formGroupRadioControlSelectDisabled.setValue(true);
    this.formGroupRadioControlSelectDisabled.disable();
    this.formGroupRadioControlDisabled.disable();
    this.formControlSwitchOn.setValue(true);
    this.formControlSwitchOff.setValue(false);
    this.formControlSwitchOffDesabled.setValue(false);
    this.formControlSwitchOffDesabled.disable();
    this.formControlSwitchOnDesabled.setValue(true);
    this.formControlSwitchOnDesabled.disable();

    this.inputFormControlDisabled.disable();
  }

  public getAssinaturaDesenharData(): void {
    console.log(this.assinarDesenhoComponent.getAssinaturaDados());
  }

  public getAssinaturaDigitarData(): void {
    console.log(this.assinarDigitarComponent.getAssinaturaDados());
  }
  public dataPickerOutput(pEvento: IDataPickerValues | null): void {
    this.toasterService.showSuccess(JSON.stringify(pEvento));
  }

  public showLoader(): void {
    this.loadingService.showLoader();
    setTimeout(() => this.loadingService.hideLoader(), 5000);
  }

  public showToasterInfo(message?: string): void {
    this.toasterService.showInfo(
      message || 'Info. Texto movido para destaque.',
    );
  }

  public showToasterSuccess(message?: string): void {
    this.toasterService.showSuccess(
      message || 'Pronto. Texto movido para destaque.',
    );
  }

  public showToasterDanger(message?: string): void {
    this.toasterService.showError(
      message || 'Aviso.Texto movido para destaque.',
    );
  }

  public factoryformControl(value?: ValueCheckboxRadioInput): FormControl {
    return new FormControl(value || '');
  }

  public onEventCheckbox(event: ValueCheckdEvent): void {
    this.checkboxValueEvent = event.checked;
  }

  public showLoaderSignature(): void {
    this.loadingSignatureService.showLoader();
    setTimeout(() => this.loadingSignatureService.hideLoader(), 5000);
  }

  public onEventSwitch(event: ValueCheckdEvent): void {
    this.switchValueEvent = event.checked;
  }
}
