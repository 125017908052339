export enum NewDocumentsOptionsEnum {
    UPLOAD_DOCUMENTO = 1,
    ADICIONAR_ASSINANTES = 2,
    ADD_INFORMATION = 3,
    LIST_SUBSCRIBERS = 4,
}

export function mapNewDocumentsOptionsTitle(newDocumentsOptionsEnum: NewDocumentsOptionsEnum): string {
    switch (newDocumentsOptionsEnum) {
        case NewDocumentsOptionsEnum.UPLOAD_DOCUMENTO:
            return 'Novo documento';
        case NewDocumentsOptionsEnum.ADICIONAR_ASSINANTES:
        case NewDocumentsOptionsEnum.LIST_SUBSCRIBERS:
            return 'Adicionar assinante';
        case NewDocumentsOptionsEnum.ADD_INFORMATION:
            return 'Informações';
        default:
            return mapNewDocumentsOptionsTitle(NewDocumentsOptionsEnum.UPLOAD_DOCUMENTO);
    }
}

export function returnToPreviousMenu(pCurrentMenu: NewDocumentsOptionsEnum): NewDocumentsOptionsEnum {
    switch (pCurrentMenu) {
        case NewDocumentsOptionsEnum.UPLOAD_DOCUMENTO:
        case NewDocumentsOptionsEnum.LIST_SUBSCRIBERS:
            return NewDocumentsOptionsEnum.UPLOAD_DOCUMENTO;
        case NewDocumentsOptionsEnum.ADICIONAR_ASSINANTES:
        case NewDocumentsOptionsEnum.ADD_INFORMATION:
            return NewDocumentsOptionsEnum.LIST_SUBSCRIBERS;
        default:
            return NewDocumentsOptionsEnum.UPLOAD_DOCUMENTO;
    }
}
