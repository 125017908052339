import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/shared/models/user.model';
import { ModalUsersComponent } from './modal-users.component';

@Injectable({
  providedIn: 'root'
})
export class ModalUsersService {

  constructor(
    private ngbModal: NgbModal
  ) { }

  public async open(): Promise<IUser | undefined> {
    const modalUsersRef = this.ngbModal.open(ModalUsersComponent, {
      centered: true,
      backdropClass: 'backdrop-blur-filter',
      modalDialogClass: 'modal-mobile-align-start modal-tablet-align-start app-modal-user-customer-template-tablet app-modal-user-customer-template-mobile',
      size: 'lg',
      keyboard: false,
    });
    return await modalUsersRef.result;
  }

  public async openEdit(dateUser: IUser): Promise<IUser | undefined> {
    const modalUsersRef = this.ngbModal.open(ModalUsersComponent, {
      centered: true,
      backdropClass: 'backdrop-blur-filter',
      size: 'lg',
      keyboard: false,
    });
    modalUsersRef.componentInstance.user = dateUser;
    modalUsersRef.componentInstance.modeEdit = true;
    return await modalUsersRef.result;
  }
}
