import { SignStatusEnum } from '../enum/signStatusEnum';
import { NSignatureHttpService } from './signatureHttpService.model';
import SignatureInvolved from './signatureInvolved';

export default class SignatureDocument {
    public readonly docGuid: string;
    public readonly involved: SignatureInvolved;
    public readonly nomeDocumento: string;
    public readonly status: SignStatusEnum;
    public readonly assinar: boolean;

    constructor(
        pDocGuid: string,
        pType: string,
        pStatus: SignStatusEnum,
        pAssinar: boolean,
        pInvolved: SignatureInvolved,
    ) {
        this.docGuid = pDocGuid;
        this.nomeDocumento = pType;
        this.status = pStatus;
        this.assinar = pAssinar;
        this.involved = pInvolved;
    }

    public gerarDocumentoEletronicoAssinar(): NSignatureHttpService.ISignatureEletronicPapers {
        return {
            docGuid: this.docGuid,
            involvedGuid: this.involved.guid,
            signatureId: this.involved.signatureId,
        };
    }
}
