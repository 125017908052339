<div class="send-papers">
  <header class="header">
    <div class="d-flex justify-content-between align-items-center">
      <div class="p-3 Heading20" [ngClass]="{'Heading20': !isVisaoMobile, 'Heading16': isVisaoMobile}">
        Documentos enviados
      </div>

      <div class="d-flex">
        <div class="search" ngbDropdown display="dynamic" placement="bottom-right" #dropSearch="ngbDropdown">
          <div class="
              inner-addon
              left-addon
              d-flex
              align-items-center
              input-search-addon
              two-addon-in-same-input
            ">
            <i class="np np-search" id="icon-input"></i>
            <input type="text" id="inputSearch" class="input wBody14 input-search" placeholder="Pesquisar por título"
              aria-describedby="icon-input" ngbDropdownToggle />
            <i *ngIf="dropSearch.isOpen()" class="np np-error wBody14"></i>
          </div>
          <div ngbDropdownMenu aria-labelledby="inputSearch" class="search-dropdown w-100">
            <span class="p-2 body14">Sugestões de busca </span>
            <button ngbDropdownItem class="p-2 d-flex align-items-center" *ngFor="let search of searchs">
              <i class="np-search icon-search-dropdown wBody14" id="icon-input"></i>
              <span class="wBody14 ml-2">{{ search }}</span>
            </button>
          </div>
        </div>
        <div [ngStyle]="{'margin-left': isVisaoMobile ? '7px' : '16px'}">
          <app-list-tag (eventTag)="filterTag($event)" (eventAtualizacaoTag)="this.getPapers('')"></app-list-tag>
        </div>
      </div>

    </div>

  </header>
  <div class="app-kanban-documentos d-flex">
    <div class="kanban-documents-desktop-tablet">
      <div *ngFor="let field of kanbanField">
        <app-kanban-documents-field [chamador]="'area-logged'" [situacaoPaper]="'enviados'" [title]="field.title" [color]="field.color" [colorDark]="field.colorDark"
          [isShippable]="field.isShippable" [papers]="field.papers" [imageEmpty]="field.imageEmpty"
          [messageEmpty]="field.messageEmpty" (eventCard)="eventCardEmitter($event)"
          (eventKanban)="eventKanbanEmitter($event)" class="flex-grow-1"></app-kanban-documents-field>
      </div>
    </div>
    <div class="mobile flex-grow-1">
      <div class="d-flex mx-2 mb-1 justify-content-around">
        <div class="selector d-flex align-items-center pl-1" *ngFor="let field of kanbanField"
          (click)="changeField(field)" [ngClass]="isFieldSelected(field) ? 'selected' : 'wBody10' ">
          <div class="mb-1">
            {{field.titleMobile}}
          </div>
          <div class="number-mobile mb-1 ml-2 d-flex justify-content-center border rounded">
            {{field.papers.length}}
          </div>
        </div>
      </div>
      <app-kanban-documents-field *ngIf="kanbanSelected !== undefined" [chamador]="'area-logged'" [situacaoPaper]="'enviados'" [title]="kanbanSelected.title"
        [color]="kanbanSelected.color" [colorDark]="kanbanSelected.colorDark" [isShippable]="kanbanSelected.isShippable"
        [papers]="kanbanSelected.papers" [imageEmpty]="kanbanSelected.imageEmpty"
        [messageEmpty]="kanbanSelected.messageEmpty" (eventCard)="eventCardEmitter($event)"
        (eventKanban)="eventKanbanEmitter($event)"></app-kanban-documents-field>
    </div>
  </div>
</div>
<app-modal-confirm-descart-paper (update)="updateList()"></app-modal-confirm-descart-paper>