<div class="app-auth-layout">
    <app-nav class="w-100"> </app-nav>
    <main class="row p-0 m-0">
        <div class="col-12 barrar-azul-background">
            <div class="row">
                <div style="z-index: 1" class="col-lg-3 pr-0">

                    <div *ngIf="isAccount" style="margin-top: 70px" class="content-profile Heading20">
                        <span class="d-flex justify-content-center mx-auto mb-2">
                            <app-avatar name="{{userAuth?.name}}" color="secondary"></app-avatar>
                        </span>
                        <div class="customer-name-perfil">
                            <span title={{userAuth?.name}} >
                                {{userAuth?.name}} 
                            </span>
                            <i class="ml-2 np-edit-2" (click)="editName(userId)"></i>
                        </div>
                        

                        <div ngbDropdown placement="bottom" class="w-100 mt-1 dropdown-user" #dropUser="ngbDropdown">
                            <button type="button" class="
                                  dropdown-toggle
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  mx-auto
                                  dropdown-user-button
                                  body14
                                " id="dropdownUser" ngbDropdownToggle>
                                <span class="mr-2">{{customerSelecionado}}</span>
                                <i class="iconBody18" [ngClass]="dropUser.isOpen() | angleUpDown"></i>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownUser" class="dropdown-user-content">
                                <span class="body14 d-block mb-1 p-1">Mudar para a conta</span>
                                <button (click)="trocarUser(i)" ngbDropdownItem class="itemDropdownUser" *ngFor="let customer of userAuth?.customers; let i=index">
                                    <span>{{customer.name}}</span>
                                    <i *ngIf="customer.name === customerSelecionado" class="ml-2 np-done"></i>
                                </button>
                            </div>
                        </div>

                        <div class="
                                content-profile-button
                                d-flex
                                align-items-center
                                justify-content-around
                              ">
                            <i class="iconBody22 np-settings-2" placement="top" ngbTooltip="Configurações da conta"
                                tooltipClass="btn-tooltip" [routerLink]="['configuracoes']"
                                routerLinkActive="router-profile-button-active"></i>
                            <i class="iconBody22 np-edit-text" placement="top" ngbTooltip="Cadastro de assinatura"
                                tooltipClass="btn-tooltip" [routerLink]="['assinatura']"
                                routerLinkActive="router-profile-button-active"></i>
                            <i class="iconBody22 np-add-user" placement="top" ngbTooltip="Assinantes"
                                tooltipClass="btn-tooltip" [routerLink]="['assinantes']"
                                routerLinkActive="router-profile-button-active"></i>
                            <i class="iconBody22 np-group" placement="top" ngbTooltip="Usuário da Conta"
                                tooltipClass="btn-tooltip" [routerLink]="['usuarios']"
                                routerLinkActive="router-profile-button-active"></i>
                            <i class="iconBody22 np-adm" placement="top" ngbTooltip="Área Administrativa"
                                tooltipClass="btn-tooltip" [routerLink]="['admin']"
                                routerLinkActive="router-profile-button-active" *ngIf="showAdmin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-3 d-none d-lg-inline">
            <app-sidebar></app-sidebar>
        </div>

        <div class="col-9 d-flex flex-column justify-content-start h-100">
            <div class="col-lg-12 container-router-npaper-auth">
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>

    <app-footer> </app-footer>
</div>