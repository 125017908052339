<div class="register-send-mail mt-3">
  <div class="table-responsive">
    <table class="table table-wrap border">
      <thead class="table-head Heading16">
        <tr>
          <th class="border-bottom-0 border-right" scope="col">
            Registro de e-mail enviados
          </th>
          <th class="border-bottom-0 border-right" scope="col">Enviado</th>
          <th class="border-bottom-0 border-right" scope="col">Recebido</th>
          <th class="border-bottom-0 border-right" scope="col">Aberto</th>
          <th class="border-bottom-0 border-right" scope="col">
            Clicou no link
          </th>
        </tr>
      </thead>
      <tbody class="wBody14">
        <tr *ngFor="let registrationSentEmail of registrationSentEmails">
          <td class="border-right" scope="col">
            {{ registrationSentEmail.email }}
          </td>
          <td class="border-right">
            <i *ngIf="registrationSentEmail.send" class="np-done wBody12"></i>
            {{ registrationSentEmail.send | date: 'dd MMM YYYY - hh:MM' }}
          </td>
          <td class="border-right">
            <i
              *ngIf="registrationSentEmail.received"
              class="np-done wBody12 wBody12"
            ></i>
            {{ registrationSentEmail.received | date: 'dd MMM YYYY - hh:MM' }}
          </td>
          <td class="border-right">
            <i
              *ngIf="registrationSentEmail.open"
              class="np-done wBody12 wBody12"
            ></i>
            {{ registrationSentEmail.open | date: 'dd MMM YYYY - hh:MM' }}
          </td>
          <td class="border-right">
            <i
              *ngIf="registrationSentEmail.clickOnLink"
              class="np-done wBody12 wBody12"
            ></i>
            {{
              registrationSentEmail.clickOnLink | date: 'dd MMM YYYY - hh:MM'
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
