import { AdminService } from './../../../../shared/services/admin/admin.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  accounts: string[] = ['Chrystian pessoal', ' Decisão Sistemas'];
  public showAdmin: boolean = false;

  constructor(
    private adminService: AdminService
  ) { }

  async ngOnInit(): Promise<void> {
    this.showAdmin = await this.adminService.isAdmin();
  }
}
