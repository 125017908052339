<div class="row">
    <div class="col-md-12 title-modal text-center">
        <p>Selecione a conta desejada!</p>
    </div>
</div>
<div class="row limitar-customer-mobile">
    <div *ngFor="let customer of customers" class="mb-3 {{numberOfColumnsInTheGrid()}}">
        <app-card-customer-select  
        [value]="customer"
            [formControlCardCustomer]="formControlCustomer">
        </app-card-customer-select>
    </div>
</div>
<div class="row">
    <div class="col-md-12 d-flex justify-content-center checkbox-modal">
        <app-checkbox label="Lembrar conta selecionada?" (checkedEvent)="rememberAccount($event)"
            [form]="formControlLembrarConta"></app-checkbox>
    </div>
</div>
<div class="row">
    <div class="col-md-12 d-flex justify-content-center text-center button-modal">
        <button class="btn btn-secondary" (click)="onCancel()">
            <i class="np-error badge-remove icon-font-size mr-2"></i>
            Cancelar
        </button>
        <button class="btn btn-primary" [disabled]="!formControlCustomer.valid" (click)="onConfirm()">
            <i class="np-done iconBody18 icon-font-size mr-2"></i> 
            Confirmar
        </button>
    </div>
</div>