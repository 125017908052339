import {
  Component, EventEmitter, Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LayoutVisaoEnum } from '../../enum/layout-visao.enum';
import { LayoutService } from '../../services/layout/layout.service';
import { BehaviorSelectComponent } from '../behavior-select/behavior-select.component';
import { ISelectOptionAssunto } from './../../models/input';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent
  extends BehaviorSelectComponent
  implements OnInit, OnChanges {

  public listOptionsAssunto: Array<ISelectOptionAssunto> = new Array();
  private isSelect: boolean = false;
  public isVisaoMobile: boolean = false;

  @Input() optionsAssunto: Array<ISelectOptionAssunto> = new Array();
  @Output() changeAfterSelect: EventEmitter<void> = new EventEmitter();

  constructor(
    private ngbDropdown: NgbDropdown,
    private layoutVisao: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);

    this.listOptionsAssunto = this.optionsAssunto;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionsAssunto && this.isSearchExternal) {
      this.optionsAssunto = changes.optionsAssunto.currentValue;
      this.listOptionsAssunto = this.optionsAssunto;
    }
  }

  public onKeyUp(): void {
    this.listOptionsAssunto = this.filterByTituloDescripctionIdentification(this.formSelect.value, this.optionsAssunto);
    if (this.listOptionsAssunto.length === this.optionsAssunto.length) {
      this.listOptionsAssunto = [];
    }
    this.onChangeAfterSelect();
    this.eventKeyUp.emit(this.formSelect.value);
  }

  public onChangeAfterSelect(): void {
    if (this.isSelect) {
      this.isSelect = false;
      this.changeAfterSelect.emit();
    }
  }

  public onClickSelect(option: ISelectOptionAssunto): void {
    this.formSelect.setValue(option.assunto);
    this.isSelect = true;
    this.eventSelectOption.emit({
      valor: String(option.id),
      label: option.assunto,
    });
  }

  public onFocusOut(): void {
    setTimeout(() => {
      if (this.ngbDropdown.isOpen() === false && this.isSelect === false) {
        this.eventFocus.emit(false);
      }
    }, 250);
  }

  private filterByTituloDescripctionIdentification(pFilter: string, selectOptionsFilter: ISelectOptionAssunto[]): Array<ISelectOptionAssunto> {
    const filterTratado = pFilter.toUpperCase();

    if (!!pFilter.trim() === false) {
      return [...selectOptionsFilter];
    }

    const optionFiltered = selectOptionsFilter.filter(selectOptionsFilter => {
      let isPossuiAssunto = false;

      if (selectOptionsFilter.assunto.toUpperCase().includes(filterTratado)) {
        isPossuiAssunto = true;
      }

      if (isPossuiAssunto) {
        return selectOptionsFilter;
      }
      return;
    });

    return optionFiltered;
  }

}
