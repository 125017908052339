import { Injectable } from '@angular/core';
import { CnpjCpfUtil } from '../../utils/cnpjCpfUtil';
import { replaceAllParamsUrl } from '../../utils/string-util';
import { RequestService } from '../request/request.service';
import { IChangePasswordBody, IChangePasswordResponse, IForgotResponse } from './../../models/loginModel';
import { UrlApiNpaper } from './../../models/url-api-npaper';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  public cnpjCpfUtil = new CnpjCpfUtil();

  constructor(
    private requestService: RequestService,
  ) { }

  public async logIn(data: { email: string; password: string }): Promise<any> {
    return await this.requestService.post(UrlApiNpaper.LOGIN, data);
  }

  public async logInCertificate(cpf: string, cnpj?: string): Promise<any> {

    let url: string = '';

    if (this.cnpjCpfUtil.validaCpf(cpf)) {
      if (cnpj && this.cnpjCpfUtil.validaCnpj(cnpj)) {
        url = replaceAllParamsUrl(UrlApiNpaper.LOGIN_CERTIFICATE_CPF_CNPJ, { cpf }, { cnpj });
      } else {
        url = replaceAllParamsUrl(UrlApiNpaper.LOGIN_CERTIFICATE_CPF, { cpf });
      }
      return await this.requestService.get(url);
    } else {
      throw new Error('CPF do certificado digital inválido');
    }
  }

  public sendPassword(pEmail: string): Promise<IForgotResponse> {
    return this.requestService.put(
      `${UrlApiNpaper.FORGOT}/${pEmail}`,
    );
  }

  public async tokenVerify(pToken: string): Promise<any> {
    return await this.requestService.get(`${UrlApiNpaper.VERIFY_TOKEN}/${pToken}`);
  }

  public async changePassword(pToken: string, pBody: IChangePasswordBody): Promise<IChangePasswordResponse> {
    return await this.requestService.put(`${UrlApiNpaper.LOGIN_CHANGE_PASSWORD}/${pToken}`, pBody);
  }
}
