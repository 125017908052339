import { AvatarColorRandomEnum } from './../../enum/avatar.enum';
export const AVATAR_COLORS_CLASS: string[] = [
  AvatarColorRandomEnum.COLOR_AVATAR_HOT_PINK_DARK,
  AvatarColorRandomEnum.COLOR_AVATAR_HOT_PINK_LIGHT,
  AvatarColorRandomEnum.COLOR_AVATAR_ORAGE_DARK,
  AvatarColorRandomEnum.COLOR_AVATAR_ORAGE_LIGHT,
  AvatarColorRandomEnum.COLOR_AVATAR_YELLOW_DARK,
  AvatarColorRandomEnum.COLOR_AVATAR_YELLOW_LIGHT,
  AvatarColorRandomEnum.COLOR_AVATAR_BLUE_DARK,
  AvatarColorRandomEnum.COLOR_AVATAR_BLUE_LIGHT,
  AvatarColorRandomEnum.COLOR_AVATAR_GREEN_WATER,
  AvatarColorRandomEnum.COLOR_AVATAR_GREEN_LIGHT,
  AvatarColorRandomEnum.COLOR_AVATAR_GREEN,
  AvatarColorRandomEnum.COLOR_AVATAR_GREEN_DARK,
  AvatarColorRandomEnum.COLOR_AVATAR_NAVY_BLUE_DARK,
  AvatarColorRandomEnum.COLOR_AVATAR_NAVT_BLUE_LIGHT ,
  AvatarColorRandomEnum.COLOR_AVATAR_PINK_LIGHT,
];
