import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Toaster } from 'src/app/shared/models/toaster';
import { NpaperTypographyType } from './../../../../types/npaper-typography.type';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit, OnDestroy {

  @Input() toaster: Toaster | undefined;
  @Input() btnClose: boolean = true;
  @Input() autoClose: boolean = true;
  @Input() textClassSize: NpaperTypographyType = 'body14';
  @Output() eventClose: EventEmitter<Toaster> = new EventEmitter<Toaster>();

  private timeOutClosedToaster: number = 5000;
  public progressBar: number = 0;
  public color: string;

  constructor() { }

  ngOnInit(): void {
    if (this.toaster === undefined) {
      throw new Error('Atributo toaster e obrigatório!');
    }
    if (this.autoClose) {
      this.closedTimeOut();
      this.calcProgressBar();
    }
    this.setProperties();
  }

  ngOnDestroy(): void {
    this.eventClose.subscribe();
  }

  public closed(): void {
    this.eventClose.emit(this.toaster);
  }

  private closedTimeOut(): void {
    setTimeout(() => this.closed(), this.timeOutClosedToaster);
  }

  private calcProgressBar(): void {
    const intervalProgressBar = setInterval(() => {
      if (this.progressBar <= 100) {
        this.progressBar++;
      } else {
        clearInterval(intervalProgressBar);
      }
    }, this.timeOutClosedToaster / 100);
  }

  public activeInputAnimation(): boolean {
    return this.progressBar <= 20;
  }

  public activeOutputAnuitaion(): boolean {
    return this.progressBar >= 90;
  }

  public animationToaster(): object | { [key: string]: string } {
    if (this.toaster === undefined) {
      return {};
    }
    return {
      'np-toaster-fade-out': this.activeOutputAnuitaion(),
      'np-toaster-fade-in': this.activeInputAnimation(),
    };
  }

  public setProperties(): void {
    switch (this.toaster?.type) {
      case 'danger':
        this.color = 'alert-danger';
        break;
      case 'info':
        this.color = 'alert-info';
        break;
      case 'success':
        this.color = 'alert-success';
        break;
      default:
        this.color = 'alert-danger';
    }
  }

}
