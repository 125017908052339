import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IValorLabel } from '../../models/valor-label';

@Component({
  template: '',
})
export class BehaviorSelectComponent implements OnDestroy {


  public listOptions: Array<IValorLabel> = [];

  @Input() formSelect: FormControl;
  @Input() id: string = '';
  @Input() options: IValorLabel[] = [];
  @Input() description: string = '';
  @Input() isRequired: boolean = false;
  @Input() placeholder: string = 'Selecione um Elemento';
  @Input() label: string = '';
  @Input() isSearchExternal: boolean = false;

  @Output() eventSelectOption: EventEmitter<IValorLabel> = new EventEmitter<IValorLabel>();
  @Output() eventKeyUp: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnDestroy(): void {
    this.eventSelectOption.unsubscribe();
    this.eventKeyUp.unsubscribe();
    this.eventFocus.unsubscribe();
  }

}
