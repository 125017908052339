<form [formGroup]="moveDocumentForm" novalidate (ngSubmit)="moveToFolder()">
  <div class="move-document d-flex justify-content-between flex-column">
    <div class="content mt-3">
      <p class="wBody12">
        Aqui você escolhe a pasta para onde deseja mover os documentos.
      </p>
      <div class="inner-addon left-addon d-flex align-items-center">
        <i class="np np-search wBody14"></i>
        <input
          type="text"
          name="searchMoveDocument"
          id="searchMoveDocument"
          class="input wBody14"
          placeholder="Buscar pasta"
        />
      </div>
      <div class="folderList mt-4">
        <div
          class="
            border-bottom
            d-flex
            px-2
            py-3
            flex-row
            justify-content-between
            align-items-center
          "
          *ngFor="let folder of folders; index as i"
          [ngClass]="{ 'border-top': i == 0 }"
        >
          <div class="d-flex flex-row m-0">
            <app-avatar size="small" icon="np-folder" color="primary"></app-avatar>
            <div class="d-flex flex-column ml-3">
              <span class="Heading16">{{ folder.name }}</span>
              <span class="wBody12">
                {{ folder.document > 0 ? folder.document : 'Nenhum' }}
                documentos - Criada em
                {{ folder.date | date: 'dd MMM yyyy - HH:mm' | titlecase }}
              </span>
            </div>
          </div>

          <div class="input-radio pt-2">
            <input
              type="radio"
              id="{{ i }}"
              class="radio-input"
              formControlName="folderSelect"
              [value]="folder.value"
            />
            <label for="{{ i }}"></label>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="d-flex justify-content-end">
        <button
          class="np btn btn-secondary d-flex align-items-center"
          (click)="onCancel()"
        >
          <i class="np-error iconBody18"></i>
          <span class="ml-2 wBody14">Fechar</span>
        </button>

        <button
          class="btn btn-primary ml-2 d-flex align-items-center"
          [disabled]="!moveDocumentForm.valid"
        >
          <i
            class="iconBody18"
            [ngClass]="{
              'np-move-folder': viewMoveDocument.view == 'moveDocument',
              'np-done': viewMoveDocument.view == 'addDocument'
            }"
          ></i>
          <span class="ml-2">{{ viewMoveDocument.btn }}</span>
        </button>
      </div>
      <span class="wBody12 mt-2 d-flex flex-row justify-content-end">
        <i class="np np-warning1 wBody12"></i>
        <div *ngIf="viewMoveDocument.view == 'moveDocument'">
          <i class="ml-1"
            >Os documentos serão movidos para a pasta "{{
              viewMoveDocument.nameFolder
            }}".</i
          >
        </div>
        <div *ngIf="viewMoveDocument.view == 'addDocument'">
          <i class="ml-1">
            Os documentos serão adicionados na pasta "{{
              viewMoveDocument.nameFolder
            }}".
          </i>
        </div>
      </span>
    </div>
  </div>
</form>
