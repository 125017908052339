import {
  AbstractControl, ValidationErrors
} from '@angular/forms';
import cnpjCpfUtil from './cnpjCpfUtil';

export class CpfCnpjValidator {

  static isInvalid(control: AbstractControl): ValidationErrors | null {

    if (control.value && cnpjCpfUtil.validaCnpjCpf(control.value)) {
      return null;
    }

    return {
      cpfCnpjInvalid: 'CNPJ/CPF inválido'
    };

  }

  static isInvalidCpf(control: AbstractControl): ValidationErrors | null {

    if (control.value && cnpjCpfUtil.validaCpf(control.value)) {
      return null;
    }
    return {
      cpfInvalid: 'CPF inválido',
    };
  }

  static isInvalidCnpj(control: AbstractControl): ValidationErrors | null {

    if (control.value && cnpjCpfUtil.validaCnpj(control.value)) {
      return null;
    }
    return {
      cnpjInvalid: 'CNPJ inválido',
    };
  }

}
