<div class="modal-send-detail">
  <div class="header d-flex align-items-center justify-content-between">
    <span class="Heading20">Detalhes do envio</span>
    <i class="np np-close btn-icon-close" (click)="onClose()" ngbAutofocus></i>
  </div>
  <div class="mt-3">
    <app-card-extract-signature-and-shipping-details
      [viewCardExtract]="'extractSignature'"
    >
    </app-card-extract-signature-and-shipping-details>
  </div>

  <div class="mt-3">
    <app-content-info-send-detail [dateSendDetail]="dateSendDetail">
    </app-content-info-send-detail>
  </div>
  <div class="mt-3">
    <app-register-send-detail-mail
      [registrationSentEmails]="registrationSentEmails"
    >
    </app-register-send-detail-mail>
  </div>
  <div class="mt-3">
    <app-register-send-document [documents]="documents">
    </app-register-send-document>
  </div>
</div>
