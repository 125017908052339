import { Component, OnInit } from '@angular/core';
import { ModalEditNameService } from 'src/app/shared/components/modal-edit-name/modal-edit-name.service';
import { KeyLocalStorage } from 'src/app/shared/models/key-local-storage';
import { IUserData, User } from 'src/app/shared/models/user.model';
import { AdminService } from 'src/app/shared/services/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SecretUtil } from 'src/app/shared/utils/secret-util';
import { AuthLayoutService } from './auth-layout.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})

export class AuthLayoutComponent implements OnInit {
  public showAdmin: boolean = false;

  public userAuth: IUserData;
  public isAccount = false;
  public customerSelecionado: string | undefined = '';

  public userId: string;

  constructor(
    public authLayoutService: AuthLayoutService,
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private userService: UserService,
    private modalEditNameUser: ModalEditNameService,

  ) { }

  async ngOnInit(): Promise<void> {
    this.showAdmin = await this.adminService.isAdmin();

    const salvaResultado = this.getUserLocal();
    if (salvaResultado === null) {
      throw new Error('Usuário não encontrado!');
    }
    this.userAuth = salvaResultado;
    this.getUserLocal();
    this.customerSelecionado = this.userAuth?.customer;

  }

  public getUserLocal(): IUserData | null {
    const userData = this.localStorageService.get(KeyLocalStorage.NPAPER_USER_DATA);
    if (userData === null) {
      return null;
    }

    this.isAccount = true;
    return JSON.parse(SecretUtil.decrypt(userData));
  }

  public async trocarUser(index: number) {
    this.customerSelecionado = this.userAuth?.customers[index].name;

    const resultDataUser = await this.userService.getDataUser(
      this.userAuth?.customers[index].token
    );

    const user = new User(resultDataUser.user)

    this.authService.setToken(user.token);
    this.authService.setUserLocal(user);

    window.location.reload();
  }

  public async editName(userId: string): Promise<void> {
    const resultModalEditNameUser = await this.modalEditNameUser.openEdit(userId);

    if (resultModalEditNameUser.confirm === false || resultModalEditNameUser.nome === null) {
      return;
    }
    this.userAuth.name = resultModalEditNameUser.nome;
  }

}
