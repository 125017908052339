import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Certificate } from 'src/app/shared/classes/certificate';
import { SignatureType } from './../../../../shared/types/SignatureType.type';
import { ModalSelectSignatureTypeComponent } from './modal-select-signature-type.component';

@Injectable()
export class ModalSelectSignatureTypeService {

  private optionModal: NgbModalOptions = {
    size: 'md',
    backdrop: 'static',
    keyboard: false,
    modalDialogClass: 'modal-mobile-align-start modal-tablet-align-start',
    backdropClass: 'backdrop-blur-filter',
    centered: true,
  };

  constructor(
    private ngbModal: NgbModal,
  ) { }

  public async open(acionador: 'new-document' | 'batch-sign', certificado: Certificate[] | null): Promise<SignatureType> {
    const modalSelectSignatureType = this.ngbModal.open(ModalSelectSignatureTypeComponent, this.optionModal);
    modalSelectSignatureType.componentInstance.acionador = acionador;
    modalSelectSignatureType.componentInstance.certificates = certificado;

    return await modalSelectSignatureType.result;
  }

  public async edit(signatureTypeSelected?: SignatureType): Promise<SignatureType> {
    const modalSelectSignatureType = this.ngbModal.open(ModalSelectSignatureTypeComponent, this.optionModal);
    modalSelectSignatureType.componentInstance.message = 'Após modificar o tipo de assinatura, todos os assinantes serão alterados para o novo tipo.';
    modalSelectSignatureType.componentInstance.signatureTypeSelected = signatureTypeSelected;
    return await modalSelectSignatureType.result;
  }

}
