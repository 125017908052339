import { Pipe, PipeTransform } from '@angular/core';
import cnpjCpfUtil from '../utils/cnpjCpfUtil';

@Pipe({
  name: 'cpfCnpjFormat',
})
export class CpfCnpjPipe implements PipeTransform {
  transform(value: string | number): string {
    let valueFormat = value + '';
    valueFormat = valueFormat.replace(/[^0-9]/g, '');

    if (valueFormat.length === 11 || valueFormat.length === 14) {
      return cnpjCpfUtil.formatarCnpjCpf(valueFormat);
    }

    return valueFormat;
  }
}
