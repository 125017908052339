<div class="modal-atualizar-assinatura m-4 d-flex justify-content-around flex-column">
    <div class="col-12 d-flex justify-content-end Heading16" (click)="cancelamento()">
        <i class="np np-close btn-icon-close"></i>
    </div>
    <div class="row mb-4">
        <div class="col-12 d-flex justify-content-center">
            <app-avatar [icon]="'np-edit'"></app-avatar>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12 justify-content-center">
            <h4>
                <span class="d-flex justify-content-center Heading20">
                    Atenção
                </span>
            </h4>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12 text-center">
            <span>
                Identificamos que a assinatura utilizada é diferente da assinatura salva. Deseja atualizar?
            </span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12 body14 d-flex justify-content-center">
            <button class="btn btn-transparent-white-border mr-4" (click)="cancelamento()"> <i class="np-error"></i> Não </button>
            <button class="btn btn-primary" (click)="confirmacao()"> <i class="np-done"></i> Sim </button>
        </div>
    </div>
</div>