<div class="mt-2 inner-addon right-addon app-input">
    <label for="label" class="wBody14 d-flex">{{label}} <span *ngIf="isRequired">*</span> </label>
    <i class="np" id="icon-input" [ngClass]="{
        'np-mail-locked': !checkValid(), 
        'np-warning1': checkInvalidTouched(), 
        'np-done': checkValid(), 
        'form-color-success': checkValidColor(), 
        'np-warning1Color': checkInvalidTouchedColor()
    }"></i>
    <input
        *ngIf="this.isCurrency"
        appCurrencyMask
        [options]="this.optionsCurrencyMask"
        [type]="type"
        [formControl]="inputControl"
        [id]="id"
        [name]="'name-'+id"
        class="input wBody14"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
        (focusout)="this.onEmitterEventFocusOut($event)"
    >
    <input
        *ngIf="this.isCurrency === false"
        [type]="type"
        [formControl]="inputControl"
        [id]="id"
        [name]="'name-'+id"
        class="input wBody14"
        [placeholder]="placeholder"
        [mask]="mask || ''"
        [autocomplete]="autocomplete"
        (focusout)="this.onEmitterEventFocusOut($event)"
    />
    <app-message-control-erro [control]="inputControl" [label]="labelErro"></app-message-control-erro>
</div>