<div *ngIf="loading" ngbDropdown #dropdownClosed="ngbDropdown" container="body" class="d-inline-block"
  [ngSwitch]="typeListTag" [autoClose]="false" placement="bottom-right">
  <div *ngSwitchCase="'listTag'">
    <button [ngClass]="{'mobile': isVisaoMobile, 'desktop-tablet': !isVisaoMobile}"
      class="btn btn-secondary box-buttom d-flex align-items-center justify-content-around" id="dropdownTag"
      ngbDropdownToggle ngbDropdownAnchor>
      <i class="np-tag" [ngStyle]="{'color': tagColor}"></i>
      <div *ngIf="!isVisaoMobile" class="ml-3 text-center body14">Marcadores</div>
      <span class="badge badge-info wBody12 ml-2">
        {{ currentTags.length }}
      </span>
      <i *ngIf="!isVisaoMobile" [ngClass]="dropdownClosed.isOpen() | angleUpDown" class="ml-2 iconBody18"></i>
    </button>
  </div>

  <div *ngSwitchCase="'onlySelectListTag'">
    <button class="btn-icon" ngbTooltip="Vincular marcador" tooltipClass="btn-tooltip" id="dropdownSelectListTag"
      ngbDropdownToggle ngbDropdownAnchor>
      <i class="np-tag iconBody18"></i>
    </button>
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdownTag" class="dropdown-menu-righ container-tag pl-3 pr-3 pt-2 pb-2 border mt-2">
    <div class="header d-flex justify-content-between align-items-center">
      <span class="Heading16">Selecionar marcador</span>
      <i class="np np-close btn-icon-close" (click)="selectItemTag(null); dropdownClosed.close()"></i>
    </div>
    <!-- listTag -->
    <div *ngSwitchCase="'listTag'">
      <div class="text-modal mt-2">
        <p class="wBody12 d-block m-0 p-0">
          Marcadores é uma forma de organizar e classificar seus envios por diferentes temas.
        </p>
      </div>
      <button class="btn btn-primary w-100 body14 mt-2 mb-2" (click)="onModalTagSign()">
        <span> Novo marcador</span>
        <i class="np np-plus ml-2 iconColor"></i>
      </button>

      <div class="row content-list-tag d-flex align-items-center m-0" *ngIf="currentTags.length > 0; else tagEmpty">
        <div class="border w-100 body14 py-1 px-2 rounded mt-1" *ngFor="let currentTag of currentTags; let i=index">
          <div class="row d-flex align-items-center">
            <div style="cursor: pointer;" class="col-8" (click)="selectItemTag(i)">
              <i class="np np-tag" [ngStyle]="{ color: currentTag.color }"></i>
              <span class="wBody12"> {{ currentTag.name }}</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
              <i style="cursor: pointer;" class="np-trash icon-car-user mr-2" (click)="onDelete(i)"></i>
              <i style="cursor: pointer;" class="np-edit-2 icon-car-user" (click)="onMoalTagEdit(i)"> </i>
            </div>
          </div>
        </div>

        <div class="d-flex mb-2 mt-2 justify-content-center align-item">
          <span class="link-limpar" (click)="selectItemTag(null); dropdownClosed.close()">Limpar marcadores</span>
        </div>
      </div>
    </div>
    <!-- onlySelectListTag -->
    <div *ngSwitchCase="'onlySelectListTag'">
      <form [formGroup]="formTag" (ngSubmit)="submitForm()">
        <div class="row content-list-tag d-flex align-items-center m-0" *ngIf="currentTags.length > 0; else tagEmpty">
          <div class="border w-100 body14 py-1 px-2 rounded mt-1" *ngFor="let currentTag of currentTags; let i = index">
            <div class="row d-flex align-items-center">
              <div class="col-8">
                <i class="np np-tag" [ngStyle]="{ color: currentTag.color }"></i>
                <span class="wBody14 ml-2">{{ currentTag.name }}</span>
              </div>
              <div class="col-4 input-checkbox d-flex flex-row-reverse">
                <input type="checkbox" [value]="currentTag.tagId" (change)="onCheckboxChange($event)"
                  id="{{ currentTag.tagId }}" />
                <label for="{{ currentTag.tagId }}" class="m-0"></label>
              </div>
            </div>
          </div>
          <button class="btn btn-primary w-100 body14 mt-2" type="submit" [disabled]="!formTag.valid" value="Submit">
            <i class="np np-done"> </i> Salvar
          </button>
        </div>
      </form>
    </div>
    <ng-template #tagEmpty>
      <div class="row content-list-tag d-flex align-items-center m-0 my-2">
        <div class="col-12 d-flex flex-column">
          <span class="body14 d-flex justify-content-center">
            Nenhum marcador adicionado ainda.
          </span>
          <span class="d-flex justify-content-center mt-4">
            <img src="../../../../assets/images/tagEmpty.svg" alt="image tag empty" class="imageTagEmpty" />
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>