

export class UserLocation {
    public readonly erro: boolean;
    public readonly errorDescription: string | null;
    public readonly location: Location | null;

    constructor(erro: boolean, errorDescription: string | null, location: Location | null) {
        this.erro = erro;
        this.errorDescription = errorDescription;
        this.location = location;
    }
}

export class Location {
    public readonly longitude: number;
    public readonly latitude: number;

    constructor(longitude: number, latitude: number) {
        this.longitude = longitude;
        this.latitude = latitude;
    }
}
