import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TypeEventCardEnum } from '../../enum/event-card.enum';
import { LayoutVisaoEnum } from '../../enum/layout-visao.enum';
import { TypeSignatureEnum } from '../../enum/type-signature';
import { ValueCheckdEvent } from '../../models/BehaviorCheckboxRadioInput';
import { IEventCard } from '../../models/Event-card.model';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'app-card-documents-lote',
  templateUrl: './card-documents-lote.component.html',
  styleUrls: ['./card-documents-lote.component.scss'],
  providers: [NgbDropdownConfig]
})

export class CardDocumentsLoteComponent implements OnInit {
  @Input() public id: number;
  @Input() public signatureType: string;
  @Input() public date: string;
  @Input() public subject: string;
  @Input() public event: EventEmitter<ValueCheckdEvent>;

  @Output() public checkboxEvent = new EventEmitter<{ id: number, values: ValueCheckdEvent }>();
  @Output() public eventCard = new EventEmitter<IEventCard>();

  public formCheck: FormControl;
  public isVisaoMobile: boolean = false;

  constructor(
    config: NgbDropdownConfig,
    private layoutVisao: LayoutService
  ) {
    config.placement = 'top-start';
  }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);

    this.formCheck = new FormControl(false);

    this.event.subscribe((values: any) => {
      this.formCheck.setValue(values.value);
      this.checkboxEmmiter(values);
    });
  }

  public onChangeCheckbox(values: ValueCheckdEvent): void {
    this.formCheck.setValue(values.value);
    this.checkboxEmmiter(values);
  }

  private checkboxEmmiter(values: ValueCheckdEvent): void {
    this.checkboxEvent.emit({ id: this.id, values });
  }

  public onNextClick(): void {
    this.eventCard.emit({ typeEvent: TypeEventCardEnum.NEXT, id: this.id });
  }
}