import { Injectable } from '@angular/core';
import { LoadingSignatureService } from 'src/app/shared/components/settings/loading-signature/loading-signature.service';
import { EletronicSignData } from 'src/app/shared/models/eletronicSignData';
import { SignatureEletronic } from 'src/app/shared/models/signature';
import SignatureDocument from 'src/app/shared/models/signatureDocument';
import { IpService } from '../../ip/ip.service';
import { LocationService } from '../../location/location.service';
import { SignatureHttpService } from '../signature.http.service';

@Injectable()
export class SignatureEletronicService {
    constructor(
        private ipService: IpService,
        private locationService: LocationService,
        private signatureHttpService: SignatureHttpService,
        private loadingSignatureService: LoadingSignatureService,
    ) { }

    public async execute(pDocuments: SignatureDocument[], pAssinatura: EletronicSignData): Promise<void> {
        try {
            this.loadingSignatureService.showLoader();
            const ip = await this.ipService.getUserIp();
            if (ip === null) {
                throw new Error('Não foi possível pegar o IP para realizar a assinatura.Tente novamente ou entre em contato com o suporte através do chat');
            }
            const location = await this.locationService.getLocation();
            const eletronic = new SignatureEletronic(pDocuments, pAssinatura, location, ip);
            const response = await this.signatureHttpService.assinarEletronico(eletronic.gerarRequest());
            this.loadingSignatureService.hideLoader();
            return Promise.resolve();
        } catch (error) {
            this.loadingSignatureService.hideLoader();
            console.log(error);
            return Promise.reject(error);
        }
    }
}
