import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomerDataResponse } from 'src/app/shared/models/customer';
import { ValueCheckdEvent } from './../../../../shared/models/BehaviorCheckboxRadioInput';

@Component({
  selector: 'app-modal-customer-select',
  templateUrl: './modal-customer-select.component.html',
  styleUrls: ['./modal-customer-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalCustomerSelectComponent implements OnInit {

  @Input() customers: ICustomerDataResponse[] = [];

  public formControlCustomer: FormControl = new FormControl('', [
    Validators.required,
    Validators.nullValidator,
  ]);

  public formControlLembrarConta = new FormControl(false);

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  public onConfirm(): void {
    this.confirmAndClose(this.formControlCustomer.value);
  }

  public onCancel(): void {
    this.confirmAndClose();
  }

  private confirmAndClose(customer?: ICustomerDataResponse): void {
    this.activeModal.close({
      rememberAccount: this.formControlLembrarConta.value,
      customer,
    });
  }

  public numberOfColumnsInTheGrid(): string {
    if (this.customers.length % 3 === 0) {
      return 'col-md-4';
    }
    return 'col-md-6';
  }

  public rememberAccount(event: ValueCheckdEvent): void {
    this.formControlLembrarConta.setValue(event.value);
  }

}
