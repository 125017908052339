<div ngbDropdown #dropdownCustomized="ngbDropdown" class="w-100 app-select-search-customized" autoClose="true">
  <div class="
        inner-addon
        left-addon
        d-flex
        align-items-center
        two-addon-in-same-input
      ">
    <i class="np color-icon" [class]="iconLeft" id="icon-input"></i>
    <input [formControl]="formSelect" (keyup)="onKeyUpSearch(); dropdownCustomized.open()" type="text"
      class="input w-100 color-input" [placeholder]="placeholder" aria-describedby="icon-input" ngbDropdownToggle />
    <i class="np color-icon" [class]="iconRight"></i>
  </div>

  <div *ngIf="!isDropdown" class="pl-0 pt-1 pb-1 pr-0 w-100 dropdown-menu-scroll">
    <div *ngFor="let item of listOptions" class="select-item"
      [ngClass]="{'desktop-tablet': !isVisaoMobile, 'mobile': isVisaoMobile, 'pl-2': !isVisaoMobile, 'pl-0': !isVisaoMobile}"
      (click)="onClickSelect(item)" [innerHtml]="item.label"></div>
    <div *ngIf="listOptions.length === 0" class="pl-2 select-item"
    [ngClass]="{'desktop-tablet': !isVisaoMobile, 'mobile': isVisaoMobile, 'pl-2': !isVisaoMobile, 'pl-0': !isVisaoMobile}">Não há elementos para busca</div>
  </div>

  <div *ngIf="isDropdown" ngbDropdownMenu class="pl-0 pt-1 pb-1 pr-0 w-100 dropdown-menu-scroll">
    <div *ngFor="let item of listOptions" ngbDropdownItem class="pl-2 select-item"
    [ngClass]="{'desktop-tablet': !isVisaoMobile, 'mobile': isVisaoMobile, 'pl-2': !isVisaoMobile, 'pl-0': !isVisaoMobile}" (click)="onClickSelect(item)"
      [innerHtml]="item.label"></div>
    <div *ngIf="listOptions.length === 0" class="pl-2 select-item"
    [ngClass]="{'desktop-tablet': !isVisaoMobile, 'mobile': isVisaoMobile, 'pl-2': !isVisaoMobile, 'pl-0': !isVisaoMobile}">Não há elementos para busca</div>
  </div>

</div>