import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Certificate } from 'src/app/shared/classes/certificate';

@Component({
  selector: 'app-modal-confirmar-inicio-assinatura',
  templateUrl: './modal-confirmar-inicio-assinatura.component.html',
  styleUrls: ['./modal-confirmar-inicio-assinatura.component.scss']
})
export class ModalConfirmarInicioAssinaturaComponent implements OnInit {
  @Input() certificadoSelecionado: Certificate | null = null;
  constructor(
    private readonly activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  public cancelamento(): void {
    this.activeModal.close(false);
  }
  public confirmacao(): void {
    this.activeModal.close(true);
  }
}
