import * as CryptoJS from 'crypto-js';

export class SecretUtil {

    public static encrypt(value: string): string {
        return btoa(value);
    }

    public static decrypt(value: string): string {
        return atob(value);
    }


    public static parseJwt(token: string): any {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

    public getSecret(email: string): string {
        const secretInit = email.split('@')[0];
        let secret = '';
        const hoje = new Date();
        if (secretInit.length > 3) {
            secret = secretInit.slice(0, 2);
            secret += secretInit.slice(secretInit.length - 2, secretInit.length);
        } else {
            secret = email.slice(0, 2);
            secret += email.slice(email.length - 2, email.length);
        }
        secret += `${hoje.getFullYear()}${hoje.getMonth()}${hoje.getDay()} `;
        return secret;
    }

    public encryptAes(text: string, secret: string): string {
        const ciphertext = CryptoJS.AES.encrypt(text, secret);
        return ciphertext.toString();
    }

    public decryptAes(text: string, secret: string): string | null {
        try {
            const bytes = CryptoJS.AES.decrypt(text, secret);
            const plaintext = bytes.toString(CryptoJS.enc.Utf8);
            return plaintext;
        } catch (err) {
            return null;
        }
    }

    public encryptMD5(text: string): string {
        return CryptoJS.MD5(text).toString();
    }
}
