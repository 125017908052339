import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Certificate } from 'src/app/shared/classes/certificate';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { SignatureTypeEnum } from './../../../../shared/enum/signature-type.enum';

@Component({
  selector: 'app-modal-select-signature-type',
  templateUrl: './modal-select-signature-type.component.html',
  styleUrls: ['./modal-select-signature-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalSelectSignatureTypeComponent implements OnInit {

  public formControlSignatureType: FormControl;
  public isVisaoMobile: boolean = false;

  @Input() signatureTypeSelected: SignatureTypeEnum | undefined;
  @Input() message: string | undefined;
  @Input() acionador: "new-document" | 'batch-sign';
  @Input() certificates: Certificate[] | null = [];

  constructor(
    private modalService: NgbActiveModal,
    private layoutVisao: LayoutService
  ) { }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
    this.createFormControl();
  }

  private createFormControl(): void {
    this.formControlSignatureType = new FormControl(this.signatureTypeSelected || '');
  }

  public onReturnCertificateSelected(event: any) {
    this.closeModal(this.formControlSignatureType.value, event);
  }

  public onClickSelectSignatureType(): void {
    if (this.acionador === 'new-document' || this.formControlSignatureType.value === 'E') {
      this.closeModal(this.formControlSignatureType.value, null);
    }
  }

  private closeModal(value: SignatureTypeEnum, pCertificado: Certificate | null): void {
    this.modalService.close({ tipo: value, certificado: pCertificado });
  }

}
