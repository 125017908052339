import { RegistrationSentEmails } from '../../../models/detail';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-send-detail-mail',
  templateUrl: './register-send-detail-mail.component.html',
  styleUrls: ['./register-send-detail-mail.component.scss'],
})
export class RegisterSendDetailMailComponent implements OnInit {
  @Input() registrationSentEmails: RegistrationSentEmails[];
  constructor() {}

  ngOnInit(): void {}
}
