import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmDescartPaperService {

  constructor() { }
  private subject = new Subject<{idPaper: number[]}>();

  public getObservable(): Observable<{idPaper: number[]}> {
    return this.subject.asObservable();
  }

  public showModalConfirm(idPaper: number[]): void {
    this.subject.next({idPaper});
  }
}
