import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { CnpjCpfUtil } from 'src/app/shared/utils/cnpjCpfUtil';
import { Certificate } from './../../../../shared/classes/certificate';
import { ToasterService } from './../../../../shared/components/toaster/toaster.service';
import { LogInUserService } from './../../login.service';

@Component({
  selector: 'app-login-certificate',
  templateUrl: './login-certificate.component.html',
  styleUrls: ['./login-certificate.component.scss'],
})
export class LoginCertificateComponent implements OnInit {
  public formControlCertificate = new FormControl('');
  private cnpjCpfUtil = new CnpjCpfUtil();
  public isVisaoMobile: boolean = false;

  @Input() certificates: Certificate[] = [];
  @Input() acionador: 'batch-sign' | 'login-certificate'
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eventCertificate: EventEmitter<Certificate> = new EventEmitter<Certificate>();

  constructor(
    private toasterService: ToasterService,
    private loginService: LoginService,
    private logInUserService: LogInUserService,
    private layoutVisao: LayoutService
  ) {}

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
  }

  public onClickSelectCertificate(event: Certificate): void {

    if(this.acionador === 'batch-sign') {
      this.eventCertificate.emit(event);
    } else {
      this.logIn(event);
    }
  }

  private async logIn(certificate: Certificate): Promise<void> {
    try {
      if (certificate.cpf && this.cnpjCpfUtil.validaCpf(certificate.cpf)) {
        this.logInUserService.validateLoginUser(
          await this.loginService.logInCertificate(
            certificate.cpf,
            certificate.cnpj,
          ),
        );
      } else {
        this.toasterService.showError('CPF do certificado inválido');
      }
    } catch (error: any) {
      this.toasterService.showError(error.message);
    }
  }

  public closed(): void {
    this.closedEvent.emit(true);
  }
}
