import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { IResponsibilityCreateResponse, IResponsibilityDeleteResponse, IResponsibilityGetAllResponse, IResponsibilityUpdateResponse } from '../../models/responsibility.interface';
import { UrlApiNpaper } from '../../models/url-api-npaper';
import { replaceAllParamsUrl } from '../../utils/string-util';

@Injectable({
    providedIn: 'root'
})
export class ResponsibilityHttpService {

    constructor(
        private httpResponseService: RequestService
    ) { }

    public async getResponsibilityByPage(page: number): Promise<IResponsibilityGetAllResponse> {
        return await this.httpResponseService
        .setHeaderToken()
        .post(
            UrlApiNpaper.GET_RESPONSIBILITY_BY_PAGE,
            {
                page
            }
        );
    }

    public async create(responsibility: string): Promise<IResponsibilityCreateResponse> {

        return this.httpResponseService
        .setHeaderToken()
        .post(
            UrlApiNpaper.CREATE_RESPONSIBILITY,
            {
                responsibility
            }
        );

    }

    public async update(id: number, responsibility: string): Promise<IResponsibilityUpdateResponse> {

        return await this.httpResponseService
        .setHeaderToken()
        .put(
            UrlApiNpaper.UPDATE_RESPONSIBILITY,
            {
                id,
                responsibility
            }
        );

    }

    public async delete(responsibilityId: number): Promise<IResponsibilityDeleteResponse> {

        return await this.httpResponseService
        .setHeaderToken()
        .delete(
            replaceAllParamsUrl(UrlApiNpaper.DELETE_RESPONSIBILITY, { responsibilityId }),
        );

    }

}
