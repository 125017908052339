<div class="app-modal-add-responsibility p-1 m-2">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center">
                <span class="Heading20">
                    <strong>{{title}}</strong>
                </span>
                <i (click)="this.closeModal()" class="iconBody22 np np-close-doc color-gray-300"></i>
            </div>
        </div>
        <div class="col-md-12">
            <app-input [inputControl]="this.formControlResponsibility" placeholder="Insira um título"
                label="Título responsabilidade" [isRequired]="true"
                labelErro="título é obrigatório">
            </app-input>
        </div>
        <div class="col-md-12 mt-2">
            <div class="d-flex justify-content-end py-2">
                <button (click)="this.closeModal()" class="btn btn-secondary mr-2"><i
                        class="np np-error mr-2"></i>Fechar</button>
                <button class="btn btn-primary" [disabled]="!isFormValid()" (click)="this.addOrEditResponsibility()"><i
                        class="np np-done mr-2"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>