import { SendDetailService } from '../send-detail.service';
import { DocumentsDetail } from '../../../models/detail';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-send-document',
  templateUrl: './register-send-document.component.html',
  styleUrls: ['./register-send-document.component.scss'],
})
export class RegisterSendDocumentComponent implements OnInit {
  isCollapsed: boolean[] = [];
  @Input() documents: DocumentsDetail[];

  constructor() {}

  ngOnInit(): void {}
}
