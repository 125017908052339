export enum SignatureTypeEnum {
    DIGITAL = 'P',
    ELECTRONICS = 'E',
    BOTH = 'PE',
}

export enum SignatureTypeTitleEnum {
    DIGITAL = 'Digital',
    ELECTRONICS = 'Eletrônica',
    BOTH = 'Ambas',
}

export function signatureTypeMap(signatureType: SignatureTypeEnum): SignatureTypeTitleEnum {
    switch (signatureType) {
        case SignatureTypeEnum.DIGITAL:
            return SignatureTypeTitleEnum.DIGITAL;
        case SignatureTypeEnum.ELECTRONICS:
            return SignatureTypeTitleEnum.ELECTRONICS;
        case SignatureTypeEnum.BOTH:
            return SignatureTypeTitleEnum.BOTH;
        default:
            throw new Error('SignatureType not found');
    }
}
