import { ModalFolderService } from '../modal-folder.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ModalFolder, userFolder } from 'src/app/shared/models/folder';

@Component({
  selector: 'app-shared-folder',
  templateUrl: './shared-folder.component.html',
  styleUrls: ['./shared-folder.component.scss'],
})
export class SharedFolderComponent implements OnInit {
  @Input() viewSharedFolder: ModalFolder;

  usersDate: userFolder[] = this.modalFolderService.getUsers();

  sharedFolderForm: FormGroup = this.formBuilder.group({
    users: this.formBuilder.array([]),
  });

  get users(): FormArray {
    return this.sharedFolderForm.get('users') as FormArray;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private modalFolderService: ModalFolderService,
  ) {}

  ngOnInit(): void {
    this.shared();
  }
  onCheckChange(id: number) {
    this.sharedFolderForm.markAsTouched();
    let index = this.users.controls.findIndex(user => user.value.id === id);
    if (index !== -1) {
      this.controlCheckBox(id, false);
      this.users.removeAt(index);
    } else {
      this.controlCheckBox(id, true);
      this.usersDate.map((value: userFolder) => {
        if (value.id == id) {
          this.users.push(new FormControl(value));
        }
      });
    }
  }
  private shared() {
    this.usersDate.map((value: userFolder, i) => {
      if (value.shared) {
        this.users.push(new FormControl(value));
      }
    });
  }

  private controlCheckBox(id: number, valueCheckbox: boolean) {
    this.usersDate.map((value: userFolder, i) => {
      if (value.id == id) {
        this.usersDate[i].shared = valueCheckbox;
      }
    });
  }

  onPermission() {}

  sharedFolderWith(): void {
    this.activeModal.close();
  }
  onCancel(): void {
    this.activeModal.close();
  }
}
