import { Injectable } from "@angular/core";
import { LayoutVisaoEnum } from "../../enum/layout-visao.enum";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor() { }

  public setLayoutTela(visao: LayoutVisaoEnum): boolean {
    switch (visao) {
      case LayoutVisaoEnum.TABLET:
        if (window.innerWidth <= 768) {
          return true;
        }
        break;
      case LayoutVisaoEnum.MOBILE:
        if (window.innerWidth <= 576) {
          return true;
        }
        break;
    }

    return false;
  }
}
