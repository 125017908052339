<form [formGroup]="sharedFolderForm" novalidate (ngSubmit)="sharedFolderWith()">
  <div class="shared-folder mt-4 d-flex flex-column">
    <span class="wBody14">E-mail dos convidados </span>
    <p class="wBody12">
      Aqui você seleciona e-mail das pessoas com quem deseja compartilhar a
      pasta.
    </p>

    <div
      ngbDropdown
      placement="bottom"
      [autoClose]="'outside'"
      #modalFolderdropdown="ngbDropdown"
    >
      <div
        formArrayName="users"
        class="mb-2 areaBadge d-flex flex-column"
        *ngIf="sharedFolderForm.touched"
        id="dropdownSearch"
        ngbDropdownToggle
      >
        <span
          class="badge badge-primary mr-2 mt-1 badgeInAreaBadge"
          *ngFor="let user of users.controls; index as i"
        >
          {{ user.value.email }}
          <div class="badge-remove">
            <i class="np-error" (click)="onCheckChange(user.value.id)"></i>
          </div>
        </span>
      </div>

      <button
        type="button"
        class="
          input
          dropdown-toggle
          d-flex
          justify-content-between
          align-items-center
        "
        id="dropdownSearch"
        ngbDropdownToggle
        *ngIf="!sharedFolderForm.touched"
      >
        <span class="wBody14">Selecionar e-mail</span>
        <i
          class="mt-1 ms-4 iconBody18"
          [ngClass]="modalFolderdropdown.isOpen() | angleUpDown"
        ></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownSearch" class="p-0 w-100">
        <div
          class="
            inner-addon
            left-addon
            d-flex
            align-items-center
            mb-1
            mx-3
            mt-3
            wBody14
          "
        >
          <i class="np np-search"></i>
          <input
            type="text"
            name="search"
            id="search"
            class="input wBody14"
            placeholder="Buscar"
          />
          <i class="np np-error rigth-addon-edit"></i>
        </div>
        <label
          ngbDropdownItem
          class="
            d-flex
            justify-content-between
            align-items-center
            p-3
            border-bottom
            mb-0
            selectUsers
          "
          id="sharedUsers"
          *ngFor="let user of usersDate; let i = index"
        >
          <div class="d-flex flex-column">
            <span class="Heading16"> {{ user.name }}</span>
            <span class="wBody14"> {{ user.email }}</span>

            <span class="wBody14"></span>
          </div>
          <input
            type="checkbox"
            class="check-input"
            (change)="onCheckChange(user.id)"
            [checked]="user.shared"
          />
        </label>

        <div class="current-folder">
          <i class="np-folder"></i>
          <span class="Heading16 ml-1">{{ viewSharedFolder.nameFolder }}</span>
        </div>
      </div>
    </div>
    <div class="ownerList my-3">
      <div
        class="border-bottom p-3 d-flex justify-content-between"
        *ngFor="let user of users.controls; index as i"
        [ngClass]="{ 'border-top': i == 0 }"
      >
        <div class="d-flex flex-row">
          <app-avatar size="small" icon="np-group" color="primary"></app-avatar>
          <div class="d-flex flex-column ml-3">
            <span class="body14">{{ user.value.email }}</span>
            <span class="wBody12">{{ user.value.permission }}</span>
          </div>
        </div>
        <div ngbDropdown display="dynamic" placement="bottom-right">
          <button
            type="button"
            class="
              dropdown-toggle
              btn-ellipsis
              d-flex
              align-items-center
              justify-content-center
            "
            ngbDropdownToggle
            id="dropdownOwnerList"
          >
            <i class="np np-other-2"></i>
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownOwnerList"
            class="dropdown-menu"
          >
            <button
              ngbDropdownItem
              class="p-2 d-flex align-items-center"
              (click)="onPermission()"
            >
              <i class="np-user iconBody18"></i>
              <span class="wBody14 ml-2">Tornar proprietário</span>
            </button>
            <button
              ngbDropdownItem
              class="p-2 d-flex align-items-center"
              (click)="onCheckChange(user.value.id)"
            >
              <i class="np-trash iconBody18"></i>
              <span class="wBody14 ml-2">Excluir compartilhamento</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-dropdown d-flex justify-content-end">
    <button
      class="np btn btn-secondary d-flex align-items-center"
      (click)="modalFolderdropdown.close()"
    >
      <i class="np-error iconBody18"></i>
      <span class="ml-2">Cancelar</span>
    </button>

    <button
      class="ml-2 d-flex align-items-center btn btn-primary"
      [disabled]="!sharedFolderForm.touched"
    >
      <i class="np-done iconBody18"></i>
      <span class="ml-2">{{ viewSharedFolder.btn }}</span>
    </button>
  </div>
</form>
