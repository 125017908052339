import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { TagService } from './../tag.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalTagService } from '../modal-tag/modal-tag.service';
import { ITagCreate, ITagUpdate, TagsModel } from 'src/app/shared/models/tagsModel';
import { ToasterService } from '../../toaster/toaster.service';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { ModalConfirmDeleteTagService } from '../../modal-confirm-delete-tag/modal-confirm-delete-tag.service';

@Component({
  selector: 'app-list-tag',
  templateUrl: './list-tag.component.html',
  styleUrls: ['./list-tag.component.scss'],
})
export class ListTagComponent implements OnInit {
  @ViewChild(NgbDropdown) private dropdown: NgbDropdown;
  @Output() eventTag = new EventEmitter<TagsModel | null>();
  @Output() eventAtualizacaoTag: EventEmitter<null> = new EventEmitter();

  typeListTag: string = 'listTag'; //or onlySelectListTag
  public currentTags: TagsModel[];
  public loading: boolean = false;
  public tagColor: string = '#334EAE';
  formTag: FormGroup;
  public isVisaoMobile: boolean = false;

  constructor(
    private tagService: TagService,
    private formBuilder: FormBuilder,
    private modalTag: ModalTagService,
    private toastrService: ToasterService,
    private layoutVisao: LayoutService,
    private modalConfirmDeleteTag: ModalConfirmDeleteTagService
  ) {
    this.formTag = this.formBuilder.group({
      checkTags: this.formBuilder.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);

    this.carregarTags();
  }

  private async carregarTags() {
    this.loading = false;
    this.currentTags = await this.tagService.getTags();
    this.loading = true;
  }

  public selectItemTag(index: number | null) {
    if (index !== null) {
      this.tagColor = this.currentTags[index].color;
      this.currentTags[index].status = 'selecao';
      this.eventTag.emit(this.currentTags[index]);
    } else {
      this.tagColor = '#334EAE';
      this.eventTag.emit(null);
    }
    this.dropdown.close();
  }

  public async onDelete(index: number) {
    const result = await this.modalConfirmDeleteTag.open();
    if (result) {
      this.onSubmitConfirmDelete(index);
    }
  }

  public onModalTagSign(): void {
    this.modalTag.modalTagSign().subscribe((result) => {
      if (result.success) {
        this.onSubmitConfirmInclusao(result.tag);
      }
    });
  }

  public onMoalTagEdit(index: number): void {
    this.modalTag.modalTagEdit(this.currentTags[index]).subscribe((result) => {
      if (result.success) {
        this.onSubmitConfirmAlteracao(result.tag, this.currentTags[index].tagId);
      }
    });
  }

  onCheckboxChange(event: any) {
    const checkTags: FormArray = this.formTag.get('checkTags') as FormArray;
    if (event.target.checked) {
      checkTags.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;
      checkTags.controls.forEach((item: any) => {
        if (item.value == event.target.value) {
          checkTags.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    this.dropdown.close();
  }

  private async onSubmitConfirmInclusao(tag: ITagCreate) {
    try {
      await this.tagService.postTag(tag);
      this.toastrService.showSuccess('Marcador cadastrado com sucesso!');

      this.carregarTags();
    } catch (error) {
      this.toastrService.showError(
        'Não foi possivel cadastrar o marcador',
      );
    }
  }

  private async onSubmitConfirmAlteracao(tag: ITagUpdate, tagId: number) {
    try {
      await this.tagService.putTag(tagId, tag);
      this.eventAtualizacaoTag.emit();
      this.toastrService.showSuccess('Marcador alterado com sucesso!');
      this.carregarTags();
    } catch (error) {
      this.toastrService.showError(
        'Não foi possivel alterar o marcador',
      );
    }
  }

  private async onSubmitConfirmDelete(index: number) {
    try {
      await this.tagService.deleteTag(this.currentTags[index].tagId);
      this.toastrService.showSuccess('Marcador excluído com sucesso!');
      this.currentTags[index].status = 'delete';
      this.eventTag.emit(this.currentTags[index]);
      this.eventAtualizacaoTag.emit();

      this.carregarTags();
    } catch (error) {
      this.toastrService.showError(
        'Não foi possivel excluir o marcador',
      );
    }
  }
} 
