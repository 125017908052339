import { Component, OnInit } from '@angular/core';
import { LayoutVisaoEnum } from 'src/app/shared/enum/layout-visao.enum';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';

@Component({
  selector: 'app-footer-not-logged',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isVisaoMobile: boolean = false;

  constructor(private layoutVisao: LayoutService) { }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
  }

}
