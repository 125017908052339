import { Component, Input, OnInit } from '@angular/core';
import { AvatarColorClassEnum, AvatarColorEnum, AvatarTypeFormatClassEnum, AvatarTypeFormatEnum } from '../../enum/avatar.enum';
import { AvatarColor, AvatarType } from '../../types/avatar.type';
import { NpaperIcon } from '../../types/npaper-icon.type';
import { AVATAR_COLORS_CLASS } from './behavior-avatar.constant.component';

@Component({
  template: '',
})
export class BehaviorAvatarComponent implements OnInit {

  private COLORS_CLASS: string[] = AVATAR_COLORS_CLASS;
  public classAvatar: string[] = ['avatar'];
  public initial: string | undefined = undefined;

  @Input() type: AvatarType;
  @Input() index: number;
  @Input() name: string;
  @Input() color: AvatarColor;
  @Input() icon: NpaperIcon;

  ngOnInit(): void {
    if ((!this.name || this.name === '') && !this.icon) {
      throw new Error('attribute name or icon not found in component avatar');
    }
  }

  protected generateInitial(nameUser: string): string {
    return nameUser.charAt(0).toUpperCase();
  }

  protected applyRandomColor(): void {
    this.addClass(this.COLORS_CLASS[this.index % this.COLORS_CLASS.length]);
  }

  protected applyTypeFormatAvatar(type: AvatarType): void {
    switch (type) {
      case AvatarTypeFormatEnum.SQUARE:
        this.addClass(AvatarTypeFormatClassEnum.SQUARE);
        break;
      case AvatarTypeFormatEnum.CIRCLE:
      default:
        this.addClass(AvatarTypeFormatClassEnum.CIRCLE);
        break;
    }
  }

  protected applyColor(color: AvatarColor): void {
    switch (color) {
      case AvatarColorEnum.SECONDARY:
        this.addClass(AvatarColorClassEnum.SECONDARY);
        break;
      case AvatarColorEnum.SUCCESS:
        this.addClass(AvatarColorClassEnum.SUCCESS);
        break;
      case AvatarColorEnum.DANGER:
        this.addClass(AvatarColorClassEnum.DANGER);
        break;
      case AvatarColorEnum.PRIMARY:
        this.addClass(AvatarColorClassEnum.PRIMARY);
        break;
      case AvatarColorEnum.WARNING:
        this.addClass(AvatarColorClassEnum.WARNING);
        break;
      default:
        this.addClass(AvatarColorClassEnum.DEFAULT);
        break;
    }
  }

  protected addClass(className: string): void {
    this.classAvatar.push(className);
  }

}
