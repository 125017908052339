import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IResponsibility } from '../../models/responsibility.interface';
import { ModalDeleteResponsibilityComponent } from './modal-delete-responsibility.component';

@Injectable()
export class ModalDeleteResponsibilityService {

  constructor(
    private ngbModal: NgbModal
  ) { }

  public open(responsibility: IResponsibility): Promise<boolean> {
    const modal = this.ngbModal.open(ModalDeleteResponsibilityComponent, {
      size: 'md',
      centered: true,
      modalDialogClass: 'modal-mobile-align-start modal-tablet-align-start modal-dialog-delete-responsibility',
      backdropClass: 'backdrop-blur-filter',
    });
    modal.componentInstance.responsibility = responsibility;
    return modal.result;
  }

}
