import { Component, OnInit } from '@angular/core';
import { NpaperTypographyEnum } from './../../enum/npaper-typography.enum';
import { BehaviorAvatarComponent } from './../behavior-avatar/behavior-avatar.component';

@Component({
  selector: 'app-avatar-one-letter',
  templateUrl: './avatar-one-letter.component.html',
  styleUrls: ['./avatar-one-letter.component.scss']
})
export class AvatarOneLetterComponent extends BehaviorAvatarComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.name) {
      this.initial = this.generateInitial(this.name);
    }

    this.applyTypeFormatAvatar(this.type);
    this.addClass(NpaperTypographyEnum.Heading16);

    if (typeof this.index === 'number') {
      this.applyRandomColor();
      return;
    }

    this.applyColor(this.color);

  }

}
