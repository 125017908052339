import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from './currency-mask.config';
import { CurrencyMaskDirective } from './currency-mask.directive';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [CurrencyMaskDirective],
    exports: [CurrencyMaskDirective]
})
export class CurrencyMaskModule {
    static forRoot(config: CurrencyMaskConfig): ModuleWithProviders<CurrencyMaskModule> {
        return {
            ngModule: CurrencyMaskModule,
            providers: [{
                provide: CURRENCY_MASK_CONFIG,
                useValue: config,
            }
            ]
        };
    }
}
