import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomerDataResponse } from 'src/app/shared/models/customer';
import { ModalCustomerSelectComponent } from './modal-customer-select.component';

@Injectable({
  providedIn: 'root',
})
export class ModalCustomerSelectService {
  constructor(private modal: NgbModal) {}

  public async open(customers: ICustomerDataResponse[]): Promise<{customer: ICustomerDataResponse | undefined, rememberAccount: boolean}> {
    const modalRef = this.modal.open(ModalCustomerSelectComponent, {
      centered: true,
      backdropClass: 'backdrop-blur-filter',
      windowClass: 'modal-customer-select',
      backdrop: 'static',
    });
    modalRef.componentInstance.customers = customers;
    return await modalRef.result;
  }
}
