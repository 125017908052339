import { Injectable } from '@angular/core';

@Injectable()
export class AuthLayoutService {

    private _HIDDEN_SECTION_ACTION_AUTH_LAYOUT: boolean = false;

    public get IsMenuActionAuthLayoutHidden(): boolean {
        return this._HIDDEN_SECTION_ACTION_AUTH_LAYOUT;
    }

    public hiddenMenuAction(): void {
        this._HIDDEN_SECTION_ACTION_AUTH_LAYOUT = true;
    }

    public showMenuAction(): void {
        this._HIDDEN_SECTION_ACTION_AUTH_LAYOUT = false;
    }

}
