import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IBodySignature } from '../../models/bodySignature.modal';
import { UrlApiNpaper } from '../../models/url-api-npaper';
import { IUserCreateUpdateSignRequest, IUserGetSignResponse } from '../../models/userHttpService.model';
import { RequestService } from '../request/request.service';
import {
  ICreateUserDTO,
  IUpdateNameUserDTO,
  IUpdateUserDTO,
  IUser,
  IUserCreateResponse,
  IUserCreateUserAdminResponse, IUserDataLoginResponse,
  IUserHttpResponse,
  IUserResponseData,
  IUsersAdminResponse,
  IUserSearchByEmailResponse
} from './../../models/user.model';
import { replaceAllParamsUrl } from './../../utils/string-util';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private requestService: RequestService) { }

  public async getDataUser(token?: string): Promise<IUserDataLoginResponse> {
    return await this.requestService
      .setHeaderToken(token)
      .get(UrlApiNpaper.GET_DATA_USER);
  }

  public async sendSettings(body: IBodySignature): Promise<void> {
    await this.requestService
      .setHeaderToken()
      .put(UrlApiNpaper.PUT_DATA_USER, body);
  }

  public async searchUserByEmail(email: string): Promise<IUserSearchByEmailResponse> {
    return await this.requestService
      .setHeaderToken()
      .get(
        replaceAllParamsUrl(UrlApiNpaper.SEARCH_USER_BY_EMAIL, { search: email }),
      );
  }

  public async createUserAdmin(userId: number): Promise<IUserCreateUserAdminResponse> {
    return await this.requestService
      .setHeaderToken()
      .post(
        UrlApiNpaper.CREATE_USER_ADMIN, { userId }
      );
  }

  public async getAllUsersAdmin(): Promise<IUsersAdminResponse> {
    return await this.requestService
      .setHeaderToken()
      .get(UrlApiNpaper.GET_ALL_USERS_ADMIN);
  }

  public async removeCustomerAdmin(userId: number): Promise<any> {
    return await this.requestService
      .setHeaderToken()
      .delete(
        UrlApiNpaper.REMOVE_USER_ADMIN,
        { userId }
      );
  }

  // ---------

  public async getUsers(): Promise<IUser[]> {
    try {
      const { users } = await this.requestService
        .setHeaderToken()
        .get(UrlApiNpaper.GET_ALL_USERS);

      return users?.map((user: IUserResponseData) => ({
        id: user.userId,
        name: user.name,
        email: user.email,
        cpf: user.identification,
        customerId: user.customerUsers[0].customerId,
        isBlocked: user.customerUsers[0].isBlocked,
        isPrimary: user.customerUsers[0].isPrimary,
        viewAllDocs: user.customerUsers[0].viewAllDocs,
      }));

    } catch (err) {
      return [];
    }
  }

  public async updateUser(user: IUpdateUserDTO | IUpdateNameUserDTO): Promise<IUserHttpResponse> {
    return await this.requestService
      .setHeaderToken()
      .put(replaceAllParamsUrl(UrlApiNpaper.UPDATE_USER, { id: user.userId }),
       user
      );
  }

  public async createUser(user: ICreateUserDTO): Promise<IUserCreateResponse> {
    return await this.requestService
      .setHeaderToken()
      .post(UrlApiNpaper.CREATE_USER, user);
  }

  public async deleteUser(idUser: number): Promise<void> {
    return await this.requestService
      .setHeaderToken()
      .delete(replaceAllParamsUrl(UrlApiNpaper.DELETE_USER, { id: idUser }));
  }

  public async deleteUserAndTransferenceToOuterUser(idUser: number, idUserToTransference: number): Promise<IUserHttpResponse> {
    return await this.requestService
      .setHeaderToken()
      .delete(replaceAllParamsUrl(UrlApiNpaper.DELETE_USER_AND_TRANSFERENCE, { idUser }, {idUserToTransference}));
  }

  public async getUserSign(): Promise<IUserGetSignResponse> {
    return this.requestService
      .setHeaderToken()
      .get(UrlApiNpaper.GET_USER_SIGNATURE);
  }

  public async createUpdateUserSign(pSignData: IUserCreateUpdateSignRequest): Promise<IUserGetSignResponse> {
    return this.requestService
      .setHeaderToken()
      .post(environment.baseUrlAPi + '/user/signature', pSignData);
  }

}
