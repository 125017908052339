import { Injectable } from '@angular/core';
import { SignatureTypeEnum } from 'src/app/shared/enum/signature-type.enum';
import { IFileNewDocumentsRedux, INewDocumentsSend } from 'src/app/shared/models/new-documents';
import { ISubscriberCompanyNewDocuments, ISubscriberCompanyOrderCompanyListSubscriber, ISubscriberNewDocuments, ISubscriberNewDocumentsSend } from 'src/app/shared/models/subscribers.model';
import { environment } from 'src/environments/environment';
import { CalculateByteService } from './../../shared/services/calculate-byte/calculate-byte';

@Injectable({
    providedIn: 'root'
})
export class NewDocumentsReduxService {

    private files: Array<IFileNewDocumentsRedux>;
    private sizeOfAllFiles: number = 0;
    public SIZE_LIMIT_PDF: number = environment.limitSizePdfInMb;
    public subscribers: Array<ISubscriberNewDocuments>;
    public subscribersCompany: Array<ISubscriberCompanyNewDocuments>;
    public signatureType: SignatureTypeEnum;
    public userHasTwoSignatureType: boolean;
    public bookmarks: Array<any>;
    public subject: string = '';
    public message: string = '';

    constructor(
        private calculateByteService: CalculateByteService,
    ) {
        this.resetReduxNewDocuments();
    }

    public resetReduxNewDocuments(): void {
        this.files = new Array();
        this.signatureType = SignatureTypeEnum.BOTH;
        this.userHasTwoSignatureType = true;
        this.subscribers = new Array();
        this.subscribersCompany = new Array();
        this.bookmarks = new Array();
    }

    public sizeExceededLimit(): boolean {
        return this.sizeOfAllFiles >= this.SIZE_LIMIT_PDF;
    }

    public getAmountSubscribers(): number {
        return (
            this.subscribers.length
            + this.subscribersCompany.length
        );
    }

    public getAmountFile(): number {
        return this.files.length;
    }

    public addFile(file: IFileNewDocumentsRedux): void {
        this.files.push(file);
        this.addFileSize(file);
    }

    private addFileSize(file: IFileNewDocumentsRedux): void {
        this.sizeOfAllFiles += this.calculateByteService.calculeByteInMegaByte(file.size);
    }

    private subtractFileSize(file: IFileNewDocumentsRedux): void {
        this.sizeOfAllFiles -= this.calculateByteService.calculeByteInMegaByte(file.size);
    }

    public getFiles(): Array<IFileNewDocumentsRedux> {
        return this.files;
    }

    public addSubscribersCompany(subscribersCompany: Array<ISubscriberCompanyNewDocuments> | ISubscriberCompanyNewDocuments): void {
        const subscribersCompanyArray: Array<ISubscriberCompanyNewDocuments> = Array.isArray(subscribersCompany) ? subscribersCompany : [subscribersCompany];

        for (const subscriberCompany of subscribersCompanyArray) {
            const subscriberCompanyExists = this.subscribersCompany.find(subscriberCompanyFind =>
                subscriberCompanyFind.subscriber.cpf === subscriberCompany.subscriber.cpf
                && subscriberCompanyFind.company.cnpj === subscriberCompany.company.cnpj
            );

            if (!subscriberCompanyExists) {
                this.subscribersCompany.push(subscriberCompany);
            }
        }
    }

    public addSubscribers(subscribers: Array<ISubscriberNewDocuments> | ISubscriberNewDocuments): void {
        const subscribersArray: Array<ISubscriberNewDocuments> = Array.isArray(subscribers) ? subscribers : [subscribers];

        for (const subscriber of subscribersArray) {
            const subscriberExists = this.subscribers.find(subscriberFind => subscriberFind.cpf === subscriber.cpf);

            if (!subscriberExists) {
                this.subscribers.push(subscriber);
            }
        }
    }

    public subscriberExists(cpf: string): boolean {
        return this.subscribers.find(subscriber => subscriber.cpf === cpf) !== undefined;
    }

    public subscriberCompanyExists(cpf: string, cnpj: string): boolean {
        return this.subscribersCompany.find(subscriberCompany => subscriberCompany.subscriber.cpf === cpf && subscriberCompany.company.cnpj === cnpj) !== undefined;
    }

    public removeSubscribers(subscribers: ISubscriberNewDocuments): void {
        this.subscribers = this.subscribers.filter(subscriber => subscriber !== subscribers);
    }

    public removeSubscribersCompany(cpf: string, cnpj: string): void {
        this.subscribersCompany = this.subscribersCompany.filter(subscriberCompany => subscriberCompany.subscriber.cpf !== cpf && subscriberCompany.company.cnpj !== cnpj);
    }

    public removeFile(pFile: IFileNewDocumentsRedux): void {
        this.files = this.files.filter(file => file.id !== pFile.id);
        this.subtractFileSize(pFile);
    }

    public getSubscriberCompanySortingCompanyWithSubscriberList(): Array<ISubscriberCompanyOrderCompanyListSubscriber> {
        const subscriberCompanySortingCompanyWithSubscriberList: Array<ISubscriberCompanyOrderCompanyListSubscriber> = new Array();

        for (const companySubscriberInteraction of this.subscribersCompany) {
            const companySubscriber = subscriberCompanySortingCompanyWithSubscriberList.find(companySubscriberInteractionFind =>
                companySubscriberInteractionFind.cnpj === companySubscriberInteraction.company.cnpj
            );

            if (companySubscriber === undefined) {
                subscriberCompanySortingCompanyWithSubscriberList.push({
                    cnpj: companySubscriberInteraction.company.cnpj,
                    razaoSocial: companySubscriberInteraction.company.razaoSocial,
                    companySubscriber: new Array(companySubscriberInteraction),
                });
            }

            if (companySubscriber !== undefined) {
                companySubscriber.companySubscriber.push(companySubscriberInteraction);
            }
        }

        return subscriberCompanySortingCompanyWithSubscriberList;
    }

    public setTypeSignature(signatureType: SignatureTypeEnum, skipInjectionWhenUserWhenHaveTwoSubscriptionTypes?: boolean): void {
        this.signatureType = signatureType;

        if (!skipInjectionWhenUserWhenHaveTwoSubscriptionTypes) {
            this.userHasTwoSignatureType = signatureType === SignatureTypeEnum.BOTH;
        }
    }

    public async getDataSendNewDocuments(): Promise<INewDocumentsSend> {
        const subscribersForSend: Array<ISubscriberNewDocumentsSend> = new Array();

        this.subscribers.map(subscribers => {
            const dataSubscribersSend: ISubscriberNewDocumentsSend = {
                name: subscribers.name,
                email: subscribers.email,
                cpf: subscribers.cpf,
                useECPF: subscribers.useECPF,
                useCNPJ: subscribers.useCNPJ,
                responsibility: subscribers.responsibility,
                title: subscribers.responsibility.map(resp => resp.responsibility).join(', '),
            };

            if (subscribers.cnpj !== undefined) {
                dataSubscribersSend.cnpj = subscribers.cnpj;
            }

            subscribersForSend.push(dataSubscribersSend);
        });

        this.subscribersCompany.map(subscribersCompany => {
            subscribersForSend.push({
                name: subscribersCompany.subscriber.name,
                email: subscribersCompany.subscriber.email,
                cpf: subscribersCompany.subscriber.cpf,
                cnpj: subscribersCompany.company.cnpj,
                useECPF: subscribersCompany.subscriber.useECPF,
                useCNPJ: subscribersCompany.subscriber.useCNPJ,
                responsibility: subscribersCompany.subscriber.responsibility,
                title: subscribersCompany.subscriber.responsibility.map(resp => resp.responsibility).join(', '),
            });
        });

        const documentsForSend: Array<{
            file: string,
            type: string,
            involveds: Array<ISubscriberNewDocumentsSend>;
        }> = [];

        for (const file of this.files) {
            documentsForSend.push({
                file: file.fileContentBase64,
                type: file.name,
                involveds: subscribersForSend,
            });
        }

        const dataSendNewDocuments: INewDocumentsSend = {
            message: this.message,
            subject: this.subject,
            tagId: this.bookmarks,
            signatureType: this.signatureType,
            documents: documentsForSend,
        };

        return Promise.resolve(dataSendNewDocuments);
    }

}
