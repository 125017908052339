import { TipoPessoaEnum } from './../enum/tipoPessoaEnum';
import { LISTA_CNPJ_INVALIDOS, LISTA_CPF_INVALIDOS } from './CnpjCpfConstant';

export class CnpjCpfUtil {

    private LISTA_CNPJ_INVALIDOS: Array<string> = LISTA_CNPJ_INVALIDOS;
    private LISTA_CPF_INVALIDOS: Array<string> = LISTA_CPF_INVALIDOS;

    public iniciaCnpjCpf(pCnpjCpf: string): string {
        try {
            const cnpjCpfLimpo = this.limpaCnpjCpf(pCnpjCpf);
            if (cnpjCpfLimpo.length <= 11) {
                const cpfPadronizado = this.padronizaCpf(cnpjCpfLimpo);
                if (this.validaCpf(cpfPadronizado) === true) {
                    return cpfPadronizado;
                }
            }
            /** Se for maior que 11 ou a válidação do cpf falhar tenta como CNPJ */
            const cnpjPadronizado = this.padronizaCnpj(cnpjCpfLimpo);
            if (this.validaCnpj(cnpjPadronizado) === false) {
                throw new Error('Cnpj ou Cpf com formato inválido');
            }

            return cnpjPadronizado;
        } catch (error) {
            throw error;
        }
    }

    public validaCnpjCpf(pCnpjCpf: string): boolean {
        const cpfCnpjLimpo: string = this.limpaCnpjCpf(pCnpjCpf);

        if (cpfCnpjLimpo.length <= 11) {
            const cpfPadronizado = this.padronizaCpf(cpfCnpjLimpo);
            if (this.validaCpf(cpfPadronizado) === true) {
                return true;
            }
        }
        const cnpjPadronizado = this.padronizaCnpj(cpfCnpjLimpo);
        if (this.validaCnpj(cnpjPadronizado) === true) {
            return true;
        }

        return false;
    }

    public identificaTipoDePessoa(pCnpjCpf: string): TipoPessoaEnum {
        try {
            if (pCnpjCpf.length === 11) {
                return TipoPessoaEnum.pessoaFisica;
            }
            if (pCnpjCpf.length === 13 || pCnpjCpf.length === 14) {
                return TipoPessoaEnum.pessoaJuridica;
            }
            throw new Error('Cnpj ou Cpf com formato inválido');
        } catch (err) {
            throw err;
        }
    }
    public padronizarCnpjCpf(pCnpjCpf: string): string {
        const cpfCnpjLimpo: string = this.limpaCnpjCpf(pCnpjCpf);
        const tipoPessoa: TipoPessoaEnum = this.identificaTipoDePessoa(cpfCnpjLimpo);

        let padronizado;
        if (tipoPessoa === TipoPessoaEnum.pessoaFisica) {
            padronizado = this.padronizaCpf(pCnpjCpf);
        } else {
            padronizado = this.padronizaCnpj(pCnpjCpf);
        }

        return padronizado;
    }

    public limpaCnpjCpf(pCnpjCpf: string): string {
        let cpfCnpj: string = pCnpjCpf;
        cpfCnpj = cpfCnpj.replace(/\./g, '');
        cpfCnpj = cpfCnpj.replace(/-/g, '');
        cpfCnpj = cpfCnpj.replace(/\//g, '');
        cpfCnpj = cpfCnpj.replace(/[A-Za-z]/g, '');
        return cpfCnpj;
    }

    public padronizaCnpj(pCnpj: string): string {
        return pCnpj.padStart(14, '0');
    }

    public padronizaCpf(pCpf: string): string {
        return pCpf.padStart(11, '0');
    }

    public possuiFormatoValidoCnpjCpf(pCnpjCpf: string): boolean {
        if (pCnpjCpf.length === 11 || pCnpjCpf.length === 13 || pCnpjCpf.length === 14) {
            return true;
        }
        return false;
    }

    public validaCpf(pCpf: string): boolean {

        if (LISTA_CPF_INVALIDOS.includes(pCpf)) {
            return false;
        }

        let dv1: number;
        let dv2: number;
        let soma: number = 0;
        let resto: number;
        let pos: number;

        const cpfCnpjDv1 = parseInt(pCpf[9], 10);
        const cpfCnpjDv2 = parseInt(pCpf[10], 10);

        /**
         * Calcula o primeiro digito validador.
         */
        soma = 0;
        pos = 10;

        for (let i = 0; i < 9; i += 1) {
            soma += Number(pCpf[i]) * pos;
            pos -= 1;
        }

        resto = soma % 11;
        dv1 = resto < 2 ? 0 : 11 - resto;
        if (dv1 !== cpfCnpjDv1) {
            return false;
        }

        /**
         * Calcula o segundo digito validador.
         */
        soma = 0;
        pos = 11;
        for (let i = 0; i < 10; i += 1) {
            soma += Number(pCpf[i]) * pos;
            pos -= 1;
        }

        resto = soma % 11;
        dv2 = resto < 2 ? 0 : 11 - resto;

        if (dv2 !== cpfCnpjDv2) {
            return false;
        }

        // Cpf válido.
        return true;
    }

    public validaCnpj(pCnpj: string): boolean {

        if (LISTA_CNPJ_INVALIDOS.includes(pCnpj)) {
            return false;
        }

        let dv1: number;
        let dv2: number;
        let soma: number = 0;
        let resto: number;
        let pos: number;

        const cpfCnpjDv1 = parseInt(pCnpj[12], 10);
        const cpfCnpjDv2 = parseInt(pCnpj[13], 10);

        pos = 5;
        soma = 0;

        // Calcula o primeiro digito validador.

        for (let i = 0; i < 12; i += 1) {
            soma += Number(pCnpj[i]) * pos;
            pos -= 1;

            if (pos < 2) {
                pos = 9;
            }
        }

        resto = soma % 11;
        dv1 = resto < 2 ? 0 : 11 - resto;

        if (dv1 !== cpfCnpjDv1) {
            return false;
        }

        // Calcula o segundo digito validador.

        pos = 6;
        soma = 0;

        for (let i = 0; i < 13; i += 1) {
            soma += Number(pCnpj[i]) * pos;
            pos -= 1;
            if (pos < 2) {
                pos = 9;
            }
        }
        resto = soma % 11;
        dv2 = resto < 2 ? 0 : 11 - resto;

        if (dv2 !== cpfCnpjDv2) {
            return false;
        }

        return true;
    }

    public formatarCnpjCpf(pCnpjCpf: string | number): string {
        let cnpjCpf: string;
        if (typeof pCnpjCpf === 'number') {
            cnpjCpf = pCnpjCpf.toString();
        } else {
            cnpjCpf = pCnpjCpf;
        }

        cnpjCpf = this.limpaCnpjCpf(cnpjCpf);
        if (cnpjCpf.length > 11) {
        } else {
            let cpfCompleto = cnpjCpf;
            while (cpfCompleto.length < 11) {
                cpfCompleto = `0${cpfCompleto}`;
            }

            return `${cpfCompleto.slice(0, 3)}.${cpfCompleto.slice(3, 6)}.${cpfCompleto.slice(6, 9)}-${cpfCompleto.slice(9, 11)}`;
        }

        let cnpjCompleto = cnpjCpf;
        while (cnpjCompleto.length < 14) {
            cnpjCompleto = `0${cnpjCompleto}`;
        }
        return `${cnpjCompleto.slice(0, 2)}.${cnpjCompleto.slice(2, 5)}.${cnpjCompleto.slice(5, 8)}/${cnpjCompleto.slice(8, 12)}-${cnpjCompleto.slice(12, 14)}`;
    }
}

export default new CnpjCpfUtil();
