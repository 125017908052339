<div class="registration-sent-document border mt-3 mb-5">
  <div class="border-bottom p-2">
    <span class="Heading16">Registro de documentos enviados</span>
  </div>
  <div *ngFor="let document of documents; let i = index">
    <div
      class="
        btn-collapse
        d-flex
        justify-content-between
        align-items-center
        border-bottom
        p-2
      "
      (click)="isCollapsed[i] = !isCollapsed[i]"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample"
    >
      <span class="Heading16">{{ document.nameDocument }}</span>
      <i class="iconBody18 pr-3" [ngClass]="isCollapsed[i] | angleUpDown"></i>
    </div>
    <div [ngbCollapse]="!isCollapsed[i]">
      <div
        class="border-bottom px-3 py-2 info-document"
        *ngFor="let subscriber of document.subscribers"
      >
        <div class="d-flex align-items-center">
          <i
            class="icon-desktop iconBody30"
            [ngClass]="{
              'np-digital-signature': subscriber.typeSignature == 'digital',
              'np-eletronic-signature': subscriber.typeSignature == 'eletronic'
            }"
          ></i>
          <span class="badge badge-primary icon-mobile">
            <span *ngIf="subscriber.typeSignature == 'digital'">Digital</span>
            <span *ngIf="subscriber.typeSignature == 'eletronic'">
              Eletrônica
            </span>
          </span>
        </div>
        <div class="wBody14">
          <span class="d-block">
            {{ subscriber.name }} - {{ subscriber.email }} - CPF/CNPJ:
            {{ subscriber.identification | cpfCnpjFormat}}
          </span>
          <span class="d-block"
            >Papel: {{ subscriber.paper }} - Situação:
            <strong>
              <span *ngIf="subscriber.situation == null; else situation">
                Aguardando assinatura
              </span>
              <ng-template #situation>
                Assinado {{ subscriber.situation }} em
                {{ subscriber.date | date: 'dd MMM YYYY - hh:MM' }}
              </ng-template>
            </strong>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
