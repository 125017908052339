<div class="m-4">
  <div class="header d-flex justify-content-between align-items-center">

    <div class="d-flex align-items-center">
      <app-avatar [icon]="'np-tag'" color="primary" size="small" class="d-md-block"></app-avatar>
      <span class="ml-3 Heading20 titulo">{{ title }}</span>
    </div>

    <i class="np np-close btn-icon-close" (click)="onConfirmarAcao(false)"></i>
  </div>
  <div class="text-modal">
    <span class="d-block sub-titulo">Nome do marcador<span class="required">*</span></span>
  </div>
  <form [formGroup]="tagUserForm" autocomplete="off">
    <div class="mt-2">
      <div class="inner-addon" [ngClass]="{ 'left-addon': tagUserForm.value.color }">
        <i *ngIf="tagUserForm.value.color" class="np np-tag" [ngStyle]="{ color: tagUserForm.value.color }"
          id="icon-tag"></i>
        <input placeholder="Escolha um nome para o seu marcador" type="text" id="inputTag" class="input wBody14" aria-describedby="input-tag" formControlName="name" />
      </div>
    </div>
  </form>
  <div class="mt-4 text-modal">
    <span class="d-block fs-14 titulo">Cor do marcador</span>
    <p class="fs-12 sub-titulo mb-1">Esta é a cor para os envios marcados com esta etiqueta. Evite etiquetas
      com cores similares.</p>
  </div>
  <div class="mt-1 border rounded pl-2 pt-2 pr-0 pb-0 grid-colors">
    <div class="border d-inline-block icon-color" *ngFor="let item of arrayCores" [ngStyle]="{ background: item }"
      (click)="selectColor(item)"></div>
  </div>

  <div class="d-flex justify-content-end mt-4 mb-2">
    <button class="btn btn-secondary mr-2" (click)="onConfirmarAcao(false)">
      <i class="np-error mr-2"></i>
      Fechar
    </button>
    <button type="button " (click)="onConfirmarAcao(true)" class="body14 btn btn-primary"
      [disabled]="disabilitarFormulario()">
      <i class="np-done mr-2"></i>
      {{ labelBotao }}
    </button>
  </div>
</div>