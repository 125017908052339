export class DownloadUtil {
    static downloadFile(Dados: Blob, name: string): void {
        const url = window.URL.createObjectURL(Dados);

        const elementA = document.createElement('a');

        elementA.href = url;

        elementA.download = name;

        elementA.click();
    }
}
