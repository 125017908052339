<div class="modal-assinatura m-4">
    <div class="row header padding-assinatura">   
        <div class="d-flex align-items-center">
            <app-avatar [icon]="'np-edit'" [size]="'small'" color="primary"></app-avatar>
            <span class="space">Como deseja assinar os documentos</span>
        </div>
        <div class="d-flex" (click)="cancelamento()">
            <i class="np np-close btn-icon-close"></i>
        </div>
    </div>

    <div class="row">
        <div class="col-12 p-0">
            <ul ngbNav #nav="ngbNav" [(activeId)]="tipoDesenhoAssinatura" class="menu-list-administrative"
                class="nav-tabs d-flex">
                <li [ngbNavItem]="modeloAssinaturas.carregada" *ngIf="!!assinaturaCarregada!== false">
                    <a ngbNavLink>Assinatura</a>
                    <ng-template ngbNavContent>
                        <div>
                            <img class="assinatura-cadastrada" [src]="assinaturaCarregadaTratada">
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="modeloAssinaturas.escrita" class="ml-3">
                    <a ngbNavLink>Digitar</a>
                    <ng-template ngbNavContent>
                        <app-assinatura-digitar #assinarDigitar></app-assinatura-digitar>
                    </ng-template>
                </li>
                <li [ngbNavItem]="modeloAssinaturas.desenho" class="ml-3">
                    <a ngbNavLink>Desenhar</a>
                    <ng-template ngbNavContent>
                        <div class="mt-3">
                            <app-assinatura-desenho #assinarDesenho></app-assinatura-desenho>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-7 col-sm-8 fs-12">
            <span>Ao clicar em assinar, você concorda com <a class="termos">nossos termos</a>.</span>
        </div>
        <div class="col-5 col-sm-4 body14 d-flex justify-content-center">
            <button class="btn btn-transparent-white-border mr-4 btn-assinar-fechar" (click)="cancelamento()"> <span><i class="np-error"></i>
                Fechar </span></button>
            <button class="btn btn-primary" (click)="confirmacao()"><span><i class="np-done"></i> Assinar</span></button>
        </div>
    </div>
</div>