import { Component, OnInit } from '@angular/core';
import { NavNService } from 'src/app/core/auth-layout/components/nav/nav.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit {

  searchs: string[] = this.navbarService.getSearch();

  constructor(private navbarService: NavNService) { }

  ngOnInit(): void {
  }

}
