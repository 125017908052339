import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmarSair } from '../../models/modal-confirmar-sair.model';
import { IResponsibility } from '../../models/responsibility.interface';
import { ModalConfirmLogoutComponent } from './modal-confirm-logout.component';

@Injectable()
export class ModalConfirmLogoutService {

  constructor(
    private ngbModal: NgbModal
  ) { }

  public open(): Promise<boolean> {
    const modal = this.ngbModal.open(ModalConfirmLogoutComponent, {
      size: 'md',
      centered: true,
      modalDialogClass: 'modal-mobile-align-start modal-tablet-align-start modal-dialog-delete-responsibility',
      backdropClass: 'backdrop-blur-filter',
    });

    return modal.result;
  }

}
