import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormValidations } from '../../classes/form-validations';

@Component({
  selector: 'app-message-control-erro',
  templateUrl: './message-control-erro.component.html',
  styleUrls: ['./message-control-erro.component.scss']
})
export class MessageControlErroComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return FormValidations.getErrorMsg(this.label, propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }
}
