import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Toaster } from '../../models/toaster';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'app-toaster-layout',
  templateUrl: './toaster-layout.component.html',
  styleUrls: ['./toaster-layout.component.scss'],
})
export class ToasterLayoutComponent implements OnInit {
  public toasters: Toaster[] = [];
  private toasterSubscription!: Subscription;

  constructor(private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.toasterSubscription = this.toasterService
      .onToaster()
      .subscribe((toaster: Toaster) => {
        this.toasters.push(toaster);
      });
  }

  public closed(toaster: Toaster): void {
    this.toasters = this.toasters.filter(t => t.id !== toaster.id);
  }

}
