import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { ICustomerDataResponse } from 'src/app/shared/models/customer';
import {
    IUserData,
    IUserDataLoginResponse,
    User
} from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ModalCustomerSelectService } from './components/modal-cunstomer-select/modal-customer-select.service';

@Injectable({
  providedIn: 'root',
})
export class LogInUserService {
  constructor(
    private toasterService: ToasterService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private modalCustomerSelectService: ModalCustomerSelectService,
  ) {}

  public async validateLoginUser(
    resultLogIn: IUserDataLoginResponse,
  ): Promise<void> {
    try {
      if (!this.validWasTheLoginSuccessful(resultLogIn)) {
        this.toasterService.showError(`${resultLogIn.message}`);
        return;
      }

      if (resultLogIn.user?.customers.length === 0) {
        this.setLocalStorageDataAndGoToHome(new User(resultLogIn.user));
        return;
      }

      const customerResult = await this.checkForDefaultAccountFfNotOpenModal(
        resultLogIn.user.customers,
      );

      

      if (customerResult.customer !== undefined) {
        const resultDataUser = await this.userService.getDataUser(
          customerResult.customer.token,
        );

        this.setLocalStorageDataAndGoToHome(
          new User(resultDataUser.user),
          customerResult,
        );
      }
    } catch (error: any) {
      this.toasterService.showError(
        'Não foi possivel realizar o login ' + error.message ||
          error.error?.message,
      );
    }
  }

  public validWasTheLoginSuccessful(
    resultLogIn: IUserDataLoginResponse,
  ): boolean {
    return resultLogIn.success;
  }

  private setLocalStorageDataAndGoToHome(
    user: IUserData,
    resultModalCostumer?: {
      customer: ICustomerDataResponse | undefined;
      rememberAccount: boolean;
    },
  ): void {
    if (resultModalCostumer?.rememberAccount && resultModalCostumer?.customer) {
      this.authService.setCustomerDefault(resultModalCostumer.customer);
    }
    this.authService.setToken(user.token);
    this.authService.setUserLocal(user);
    this.router.navigate(['/auth/enviados']);
    this.toasterService.showSuccess('Login efetuado com sucesso!');
  }

  public async checkForDefaultAccountFfNotOpenModal(
    customer: ICustomerDataResponse[],
  ): Promise<{
    customer: ICustomerDataResponse | undefined;
    rememberAccount: boolean;
  }> {
    const customerDefault = this.authService.getCustomerDefault();

    if (customerDefault) {
      return {
        customer: customer.filter(
          c => c.customerId === customerDefault.customerId,
        )[0],
        rememberAccount: true,
      };
    }

    const resultModalCustomer = await this.modalCustomerSelectService.open(
      customer,
    );

    if (resultModalCustomer.customer) {
      return resultModalCustomer;
    } else {
      return {
        customer: undefined,
        rememberAccount: false,
      };
    }
  }
}
