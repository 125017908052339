import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ITagCreate, ITagUpdate } from 'src/app/shared/models/tagsModel';

@Component({
  selector: 'app-modal-tag',
  templateUrl: './modal-tag.component.html',
  styleUrls: ['./modal-tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalTagComponent implements OnInit {
  @Input() title: string = 'Adicionar marcador';
  @Input() labelBotao: string = 'Salvar';
  @Input() acao: 'inclusao' | 'edicao';
  @Input() tagEdit: ITagUpdate | null;
  confirmResult$: Subject<{ success: boolean, acao: string, tag: ITagCreate }> = new Subject();

  tagUserForm: FormGroup;
  arrayCores: string[] = [
    '#D32F2F',
    '#7B1FA2',
    '#303F9F',
    '#00A1F9',
    '#00796B',
    '#84AD3D',
    '#FBC02D',
    '#F57C00',
    '#ED805E',
    '#5D4037',
    '#455A64',

    '#C2185B',
    '#512DA8',
    '#1976D2',
    '#0097A7',
    '#388E3C',
    '#AFB42B',
    '#FFA000',
    '#E64A19',
    '#1D292F',
    '#616161',
    '#638A9C',
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.tagUserForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      color: ['#B9C0C7', [Validators.required]],
    });

    if (this.acao === 'edicao' && this.tagEdit) {
      this.tagUserForm.setValue({
        name: this.tagEdit.name,
        color: this.tagEdit.color
      })
    }
  }

  selectColor(color: string) {
    this.tagUserForm.controls['color'].setValue(color);
  }

  cadastrarTag() {
    if (!this.tagUserForm.valid) return;
    this.activeModal.close();
  }

  public onConfirmarAcao(status: boolean) {
    this.confirmResult$?.next({ success: status, acao: this.acao, tag: this.tagUserForm.value });
    this.activeModal.close();
  }

  public disabilitarFormulario(): boolean {
    let isValido: boolean = this.tagUserForm.valid;

    if (this.tagUserForm.controls['color'].value === '#B9C0C7') {
      isValido = false;
    }

    return !isValido;
  }
}
