import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-navegacao-mobile',
  templateUrl: './menu-navegacao-mobile.component.html',
  styleUrls: ['./menu-navegacao-mobile.component.scss']
})
export class MenuNavegacaoMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
