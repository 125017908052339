import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodHttpEnum } from '../../models/method-http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { KeyLocalStorage } from './../../models/key-local-storage';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public localStorageService: LocalStorageService,
  ) {
    this.headers = new HttpHeaders();
  }

  public async get(urlRequest: string, body?: any, params?: any, headers?: any): Promise<any> {
    return await this.request(MethodHttpEnum.GET, urlRequest, body, params, headers);
  }

  public async post(urlRequest: string, body?: any, params?: {}, headers?: any): Promise<any> {
    return await this.request(MethodHttpEnum.POST, urlRequest, body, params, headers);
  }

  public async put(urlRequest: string, body?: any, params?: {}, headers?: any): Promise<any> {
    return await this.request(MethodHttpEnum.PUT, urlRequest, body, params, headers);
  }

  public async delete(urlRequest: string, body?: any, params?: {}, headers?: any): Promise<any> {
    return await this.request(MethodHttpEnum.DELETE, urlRequest, body, params, headers);
  }

  private async request(
    method: MethodHttpEnum,
    urlRequest: string,
    body?: any,
    params?: {},
    headers?: {},
  ): Promise<any> {
    return await this.http
      .request(method, urlRequest, {
        headers: headers ?? this.headers,
        body,
        params,
      })
      .toPromise();
  }
  public async downladApplicationPdfContentToBase64(pUrl: string): Promise<string | null> {
    try {
      const response = await this.http.get(pUrl, {
        responseType: 'arraybuffer',
        observe: 'response',
      }).toPromise();
      if (response.body) {
        let binary: string = '';
        const bytes = new Uint8Array(response.body);
        for (let i = 0; i < bytes.length; i += 1) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }
      return null;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async requestBlob(urlRequest: string): Promise<Blob>{
    return this.http.request('GET', urlRequest, {
      headers: this.headers,
      responseType: 'blob'
    })
    .toPromise();
  }

  public setHeaderToken(token?: string): this {
    const localStorageToken = this.localStorageService.get(KeyLocalStorage.NPAPER_AUTH_TOKEN);
    if (token === undefined && localStorageToken === null) {
      return this;
    }
    const tokenAplicar = token !== undefined
      ? token
      : localStorageToken;

    if (tokenAplicar === null) {
      return this;
    }

    this.headers = this.headers.set('Authorization', tokenAplicar);
    return this;
  }

  public setHeader(chave: string, valor: string): this {
    this.headers = this.headers.set(chave, valor);
    return this;
  }
}
