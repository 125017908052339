import { ButtonsDetailService } from '../button-detail.service';
import { Injectable } from '@angular/core';
import { ButtonDetail } from '../../../models/detail';

@Injectable({
  providedIn: 'root',
})
export class ContentInfoSendDetailService {
  constructor(private buttonsDetailService: ButtonsDetailService) {}

  getButtons(progressSignature: number, signatures: number, type: string) {
    const types: any = {
      send: () => this.getButtonsSend(progressSignature, signatures),
      received: () => this.getButtonsReceived(progressSignature, signatures),
      discarded: () => this.getButtonsDiscarded(),
    };
    return types[type]();
  }

  private getButtonsSend(progressSignature: number, signatures: number) {
    if (progressSignature >= signatures) {
      return this.receivedFullSigned();
    }
    return this.sendPartiallySigned();
  }

  private getButtonsReceived(progressSignature: number, signatures: number) {
    if (progressSignature >= signatures) {
      return this.sendFullSigned();
    }
    return this.receivedPartiallySigned();
  }
  private getButtonsDiscarded(): ButtonDetail[] {
    return this.buttonsDetailService.selectTypeButton(['restoreButton']);
  }

  private sendFullSigned(): ButtonDetail[] {
    return this.buttonsDetailService.selectTypeButton([
      'saveButton',
      'trashButton',
      'tagButton',
    ]);
  }

  private sendPartiallySigned(): ButtonDetail[] {
    return this.buttonsDetailService.selectTypeButton([
      'resendingButton',
      'trashButton',
      'tagButton',
    ]);
  }

  private receivedFullSigned(): ButtonDetail[] {
    return this.buttonsDetailService.selectTypeButton([
      'saveButton',
      'trashButton',
    ]);
  }

  private receivedPartiallySigned(): ButtonDetail[] {
    return this.buttonsDetailService.selectTypeButton([
      'tagButton',
      'signButton',
    ]);
  }
}
