import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalTermosDeUsoService } from 'src/app/shared/components/modal-termos-de-uso/modal-termos-de-uso.service';
import { LoadingService } from 'src/app/shared/components/settings/loading/loading.service';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { LoginService } from 'src/app/shared/services/login/login.service';

@Component({
  selector: 'app-form-recover-password',
  templateUrl: './form-recover-password.component.html',
  styleUrls: ['./form-recover-password.component.scss']
})
export class FormRecoverPasswordComponent implements OnInit {
  public emailControl: FormControl;
  public formulario: FormGroup;

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private loginService: LoginService,
    private modalTermosDeUsoService: ModalTermosDeUsoService,
  ) {
    this.resetForm();
  }

  public ngOnInit(): void { }

  public showTermos(): void {
    this.modalTermosDeUsoService.showTermos();
  }

  public resetForm(): void {
    this.formulario = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.maxLength(60), Validators.email, Validators.nullValidator]),
    });
    this.emailControl = this.formulario.get('email') as FormControl;
  }

  async onSubmit(): Promise<void> {
    try {
      this.loadingService.showLoader();

      const resposta = await this.loginService.sendPassword(this.emailControl.value);
      if (resposta.success === false) {
        // Mostrar toast de erro e nao mudar de tela
        this.toasterService.showError(resposta.message || 'Erro ao tentar enviar email de recuperação');
        return Promise.resolve();
      }

      this.router.navigate(['/login']);
      this.toasterService.showSuccess('Link enviado com sucesso, acesse seu e-mail para recuperar a senha. ');
    } catch (err) {
      this.toasterService.showError('Erro ao tentar enviar email de recuperação');
    } finally {
      this.loadingService.hideLoader();
    }
  }

  public goRegister(): void {
    this.router.navigate(['/register/']);
  }
}
