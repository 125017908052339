import { IDescDesktopMobile } from './../models/DescDesktopMobile.model';
export enum StatusSignedEnum {
    SIGNED = 'A',
    UNSIGNED = 'N',
    PARTIALLY_SIGNED = 'P',
}

export function getStatusDesc(status: StatusSignedEnum): IDescDesktopMobile{
    switch (status) {
        case StatusSignedEnum.SIGNED:
            return { descDesktop: 'Totalmente assinado', descMobile: 'Total. assina.'};
        case StatusSignedEnum.UNSIGNED:
            return { descDesktop: 'Não assinado', descMobile: 'Não assina.'};
        case StatusSignedEnum.PARTIALLY_SIGNED:
            return { descDesktop: 'Parcialmente assinado', descMobile: 'Parc. assina.'};
    }
}
