import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalUsersService } from './../../../modules/users/components/modal-users/modal-users.service';
import { IUser } from './../../models/user.model';

@Component({
  selector: 'app-select-user-with-search',
  templateUrl: './select-user-with-search.component.html',
  styleUrls: ['./select-user-with-search.component.scss']
})
export class SelectUserWithSearchComponent implements OnInit, OnDestroy {

  @Input() users: Array<IUser>;
  @Input() formControlSelectUserWithSearch: FormControl;
  @Output() changeUserSelect: EventEmitter<IUser> = new EventEmitter<IUser>();

  public usersFilter: Array<IUser>;
  public formControlInputSearch: FormControl = new FormControl('');
  public userSelect: IUser | undefined;

  constructor(
    private modalUserService: ModalUsersService
  ) { }

  ngOnInit(): void {
    this.onClearDropDown();
  }

  ngOnDestroy(): void {
    this.changeUserSelect.unsubscribe();
  }

  public onClearDropDown(): void {
    this.formControlInputSearch.setValue('');
    this.usersFilter = this.users;
  }

  public onKeyUp(): void {
    this.filterUserByName();
  }

  public onSelectUser(user: IUser): void {
    this.userSelect = user;
    this.formControlSelectUserWithSearch.setValue(user.id);
    this.changeUserSelect.emit(user);
  }

  private filterUserByName(): void {
    this.usersFilter = this.users.filter(user => {
      return `${user.name}${user.email}`.indexOf(this.formControlInputSearch.value.trim()) >= 0;
    });
  }

  public async onAddNewUser(): Promise<void> {
    const resultModal = await this.modalUserService.open();

    if (resultModal === undefined) {
      return;
    }

    if (this.users.length === 0) {
      this.onSelectUser(resultModal);
    }

    this.users.push(resultModal);
    this.onClearDropDown();
  }

}
