import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';
import { signatureDefaultSize } from '../../constants/signatureDefaultSize';

@Component({
  selector: 'app-assinatura-desenho',
  templateUrl: './assinatura-desenho.component.html',
  styleUrls: ['./assinatura-desenho.component.scss']
})
export class AssinaturaDesenhoComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Width da canvas de desenho em px.
   */
  @Input() width: number | '100%' | 'default' = 'default';
  /**
   * Heigth da canvas de desenho em px.
   */
  @Input() heigth: number | '100%' | 'default' = 'default';
  @ViewChild('canvasAssinarDesenho') public canvas: ElementRef;

  private cx: CanvasRenderingContext2D;
  private canvasEl: HTMLCanvasElement;
  private canvasWidth: number;
  private canvasHeight: number;
  private canvasDiv: HTMLElement | null;

  public divWidth: string;
  public divHeight: string;

  constructor() {

  }

  ngOnInit(): void {
    this.canvasDiv = document.getElementById('assinar-desenho');
    this.setHeigth();
    this.setWidth();
  }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void {


    setTimeout(() => {
      this.canvasEl = this.canvas.nativeElement;
      const canvasContext = this.canvasEl.getContext('2d');
      if (canvasContext === null) {
        throw new Error('Erro ao carregar o modulo de desenho de assinatura');
      }

      this.cx = canvasContext;
      this.canvasEl.width = this.canvasWidth;
      this.canvasEl.height = this.canvasHeight;

      this.captureEvents(this.canvasEl);
    }, 50);
  }

  private captureEvents(canvasEl: HTMLCanvasElement): void {
    // Captura dos eventos de touch.
    fromEvent(canvasEl, 'touchstart')
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, 'touchmove')
            .pipe(
              takeUntil(fromEvent(canvasEl, 'touchend')),
              takeUntil(fromEvent(canvasEl, 'touchleave')),
              pairwise()
            );
        })
      ).subscribe((res: [any, any]) => {
        const response = res as [TouchEvent, TouchEvent];
        const rect = canvasEl.getBoundingClientRect();

        const prevPos = {
          x: response[0].touches[0].clientX - rect.left,
          y: response[0].touches[0].clientY - rect.top
        };

        const currentPos = {
          x: response[1].touches[0].clientX - rect.left,
          y: response[1].touches[0].clientY - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos);
      });

    // Captura de mouse
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              pairwise()
            );
        })
      ).subscribe((res: [any, any]) => {
        const response = res as [MouseEvent, MouseEvent];
        const rect = canvasEl.getBoundingClientRect();

        const prevPos = {
          x: response[0].clientX - rect.left,
          y: response[0].clientY - rect.top
        };

        const currentPos = {
          x: response[1].clientX - rect.left,
          y: response[1].clientY - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number, y: number },
    currentPos: { x: number, y: number }
  ): void {
    if (!this.cx) { return; }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y);
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  private setWidth(): void {
    switch (this.width) {
      case 'default':
        this.canvasWidth = signatureDefaultSize.width;
        break;
      case '100%':
        if (this.canvasDiv !== null) {
          setTimeout(() => {
            this.divWidth = `100%`;
            this.canvasWidth = this.canvasDiv?.offsetWidth ?? 0;
          }, 50);
        } else {
          this.canvasWidth = signatureDefaultSize.width;
          this.divWidth = `${this.canvasWidth}.px`;
        }
        break;
      default: this.canvasWidth = this.width; break;
    }
  }

  private setHeigth(): void {
    switch (this.heigth) {
      case 'default': this.canvasHeight = signatureDefaultSize.height; break;
      case '100%':
        if (this.canvasDiv !== null) {
          this.canvasHeight = 300;
        } else {
          this.canvasHeight = signatureDefaultSize.height;
        }
        break;
      default: this.canvasHeight = this.heigth; break;
    }
  }

  public getAssinaturaDados(): string {
    return this.canvasEl.toDataURL('image/png');
  }
}
