import { Progress } from '../../../models/progress';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() public progress: Progress = {
    loading: 8,
    total: 10,
  };
  public progressOpacity: number = 0.5;

  private loadingSubscription!: Subscription;
  public isShowLoading: boolean = false;

  constructor(
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.getObservable()
    .subscribe(({ isStart }) => {
      this.isShowLoading = isStart;
      });

    this.progressOpacity = this.onVisibilityChanged(
      this.progress.loading,
      this.progress.total,
    );
  }

  private onVisibilityChanged(loading: number, total: number): number {
    const percentage = (loading * 100) / total;
    if (percentage >= 50) {
      return percentage / 100;
    }
    return 0.5;
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
