<div class="card-documents row">
    <div class="col-md-12">
        <div class="card pl-3 pr-3 border"
            [ngClass]="{'pb-3': !isVisaoMobile, 'pb-2': isVisaoMobile, 'pt-3': !isVisaoMobile, 'pt-2': isVisaoMobile}">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center my-2">
                    <div *ngIf="situacaoPaper !== 'descartados'">
                        <app-checkbox [form]="formCheck" (checkedEvent)="onChangeCheckbox($event)"></app-checkbox>
                    </div>
                    <div
                        class="border-secondary rounded mx-2 px-2 background-gray color-400 d-flex align-items-center ">
                        <i class="np-edit iconBody12 mr-1 "></i>
                        <span class="wBody10 my-1">{{typeSignature}}</span>
                    </div>
                </div>
                <div class="d-flex align-itens-center my-2">
                    <div class="border-secondary rounded px-2 background-gray color-400 d-flex align-items-center">
                        <i class="np-clock iconBody12 mr-1"></i>
                        <span class="wBody10 my-1">{{date}}</span>
                    </div>
                </div>
            </div>
            <div class="w-100">
                <div *ngIf="chamador === 'area-logged'" [ngClass]="{'mt-2': !isVisaoMobile}"
                    [ngStyle]="{'margin-top': isVisaoMobile ? '-8px' : '0px'}">
                    <span class="color-400 wBody12">
                        {{signed}} de {{totalSign}} assinaturas
                    </span>
                </div>
                <hr [ngClass]="{'mt-2': isVisaoMobile}">
                <div class="quebra-texto" ngbTooltip="{{subject}}">
                    <span class="body14">
                        {{subject}}
                    </span>
                </div>

                <app-bagde-tag *ngIf="chamador === 'area-logged'" [tags]="tags" [situacaoPaper]="situacaoPaper"
                    (deleteTag)="onDeleteTag($event.idTag, false)"></app-bagde-tag>

                <hr [ngClass]="{'mb-2': isVisaoMobile}">
                <div class="d-flex justify-content-between">
                    <div *ngIf="situacaoPaper === 'descartados'" class="icon-group mb-2 d-flex">
                        <div>
                            <button class="btn btn-icon hover-blue mx-1" (click)="onRestoreClick()"
                                ngbTooltip="Restaurar">
                                <i class="np-update iconBody18"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="situacaoPaper === 'enviados'" class="icon-group mb-2 d-flex">
                        <div *ngIf="!fullySigned && chamador === 'area-logged'">
                            <button class="btn btn-icon border mx-1 color-400" (click)="onSendClick()"
                                ngbTooltip="Enviar">
                                <i class="np-sending iconBody18"></i>
                            </button>
                        </div>
                        <button *ngIf="chamador === 'area-logged'" class="btn btn-icon border mx-1 color-400"
                            (click)="onTrashClick()" ngbTooltip="Descartar">
                            <i class="np-trash iconBody18"></i>
                        </button>

                        <div ngbDropdown #dropdownMarcador="ngbDropdown" *ngIf="chamador === 'area-logged'"
                            class="d-inline-block container-tag" [autoClose]="true">

                            <button type="button" class="btn btn-icon border mx-1 color-400 justify-content-around"
                                id="dropdownConfig" ngbDropdownToggle ngbDropdownAnchor ngbTooltip="Marcador" (click)="selecionarTags()"><i
                                    class="np-tag iconBody18"></i></button>

                            <div ngbDropdownMenu aria-labelledby="dropdownConfig"
                                class="container-tag-dropdown-menu p-3"
                                [ngClass]="{'totalmente': titulo === 'Totalmente assinados', 'parcial': titulo !== 'Totalmente assinados'}">
                                <div class="header d-flex justify-content-between align-items-center">
                                    <span class="Heading16">Selecionar marcador</span>
                                    <i class="np np-close btn-icon-close" (click)="dropdownMarcador.close()"></i>
                                </div>

                                <div class="scroll-tela">
                                    <div *ngFor="let currentTag of currentTags; let i=index"
                                        class="border w-100 body14 pl-3 pt-2 pb-2 rounded mt-1">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <i class="np np-tag" [ngStyle]="{ color: currentTag.color }"></i>
                                                <span class="wBody12"> {{ currentTag.name }}</span>
                                            </div>
                                            <div class="input-checkbox">
                                                <app-checkbox [form]="formCheck" [checked]="currentTag.isChecked"
                                                    (checkedEvent)="isCheckedItem(!currentTag.isChecked, i)"></app-checkbox>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="d-flex mb-3 justify-content-center">
                                    <button class="body14 btn btn-primary salvar-tags"
                                        [disabled]="tagsSelected.length === 0"
                                        (click)="salvarTags(); dropdownMarcador.close();">
                                        <i class="np-plus iconBody18 mr-2"></i>
                                        Salvar
                                    </button>
                                </div>

                                <div *ngIf="tagsSelected.length >0" class="d-flex mb-2 justify-content-center">
                                    <span class="link-limpar" (click)="onDeleteTag(0, true)">Limpar marcadores</span>
                                </div>
                            </div>
                        </div>

                        <button *ngIf="chamador === 'area-logged'" class="btn btn-icon border mx-1 color-400"
                            (click)="onFolderClick()" ngbTooltip="Mover">
                            <i class="np-move-folder iconBody18"></i>
                        </button>
                        <div *ngIf="fullySigned && chamador === 'area-logged'">
                            <button class="btn btn-icon border mx-1 color-400" (click)="onDownLoadClick()"
                                ngbTooltip="Download">
                                <i class="np-download iconBody18"></i>
                            </button>
                        </div>
                    </div>
                    <div class="icon-group mb-2">
                        <button class="btn btn-icon hover-blue mx-1" (click)="onNextClick()" ngbTooltip="Detalhes">
                            <i class="np-angle-right iconBody18"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>