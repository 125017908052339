import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor() {}

  public checkInvalidTouched(form: FormGroup | FormControl, field: string): boolean | undefined {
    const getField = form.get(field);
    return getField?.invalid && getField?.touched;
  }
  public checkValid(form: FormGroup | FormControl, field: string): boolean | undefined {
    return form.get(field)?.valid;
  }

}
