<div class="app-not-auth-layout">
    <app-nav-not-logged class="w-100"> </app-nav-not-logged>

    <main class="row p-0 m-0">
        <div class="col-12 barrar-azul-background">
            <div class="row">
                <div style="z-index: 1" class="col-lg-3 pr-0">

                    <div style="margin-top: 70px" class="content-profile Heading20">
                        <span class="d-flex justify-content-center mx-auto mb-2">
                            <app-avatar [name]="stateService.sessionCertificteAnonymous.get(LocalizeCertificateConstantes.SESSION_CERTIFICATE_ANONYMOUS_NOME)"></app-avatar>
                        </span>
                        <span
                            class="d-block mb-3">{{stateService.sessionCertificteAnonymous.get(LocalizeCertificateConstantes.SESSION_CERTIFICATE_ANONYMOUS_NOME)}}</span>

                        <div placement="bottom" class="w-100 mt-1 dropdown-user">
                            <button type="button" class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mx-auto
                              dropdown-user-button
                              body14
                            " id="dropdownUser" (click)="onLocalizeCertificate()">
                                <span class="mr-2">Alterar certificado</span>
                                <i class="iconBody18 np-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-3 d-none d-lg-inline">
            <app-sidebar-not-logged></app-sidebar-not-logged>
        </div>
        <div class="col-9 d-flex flex-column justify-content-start h-100">
            <div style="z-index: 2" class="col-lg-12 pl-0 pr-0 container-router-npaper-auth">
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>

    <app-footer-not-logged> </app-footer-not-logged>
</div>