import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/components/settings/loading/loading.service';
import { ToasterService } from 'src/app/shared/components/toaster/toaster.service';
import { IChangePasswordBody } from 'src/app/shared/models/loginModel';
import { LoginService } from 'src/app/shared/services/login/login.service';

@Component({
  selector: 'app-form-new-password',
  templateUrl: './form-new-password.component.html',
  styleUrls: ['./form-new-password.component.scss']
})
export class FormNewPasswordComponent implements OnInit {

  formulario: FormGroup;
  public email: string = '';
  public senhaControl: FormControl;
  public confirmarSenhaControl: FormControl;

  private tokenId: string;
  private token: string;
  @ViewChild('confirmPasswordInput') private confirmPasswordInput: any;

  public showPassword = 'password';
  public disabledButton: boolean = false;

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
  ) {
    this.resetForm();
  }

  async ngOnInit(): Promise<void> {
    try {
      const token = this.activatedRoute.snapshot.paramMap.get('token');
      if (token === null) {
        this.router.navigate(['/login']);
        return Promise.resolve();
      }

      this.token = token;
    } catch (err) {
      this.loadingService.hideLoader();
      console.log(err);
    }
  }

  private resetForm(): void {
    this.senhaControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.confirmarSenhaControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.formulario = new FormGroup({
      senha: this.senhaControl,
      confirmarSenha: this.confirmarSenhaControl,
    });
  }

  async submit(): Promise<void> {
    try {
      if (this.formulario.valid === false) {
        return;
      }

      const novasSenhas: IChangePasswordBody = {
        password: this.senhaControl.value,
        passwordConfirm: this.confirmarSenhaControl.value,
      };

      const response = await this.loginService.changePassword(this.token, novasSenhas);
      if (response.success === false) {
        this.toasterService.showSuccess('Sua senha foi alterada com sucesso. ');
        return Promise.resolve();
      }

      this.loadingService.hideLoader();
      this.toasterService.showSuccess('Sua senha foi alterada com sucesso. ');
      this.router.navigate(['/login']);
    } catch (err) {
      this.loadingService.hideLoader();
      console.log(err);
    }
  }

  public checkConfirmationIqual(): void {
    if (!!this.senhaControl.value === false || !!this.confirmarSenhaControl.value === false) {
      return;
    }

    if (this.senhaControl.value !== this.confirmarSenhaControl.value) {
      this.confirmarSenhaControl.setErrors({ confirmPasswordNotMatch: true });
    }
  }

  public isFormPasswordValid(): boolean | undefined {
    return this.senhaControl.valid;
  }
  public isFormPasswordValid2(): boolean | undefined {
    return this.confirmarSenhaControl.valid;
  }

  public clearFields(): void {
    this.formulario.reset();
  }

}
