<form
  [formGroup]="addFolderForm"
  novalidate
  (ngSubmit)="addNewFolder()"
  class="d-flex flex-column justify-content-between"
>
  <div class="add-folder-and-edit-name-folder mt-4">
    <div class="row">
      <div class="col-12">
        <label for="inputNameFolder" class="wBody14">Nome da pasta</label>
        <div class="inner-addon right-addon">
          <i
            class="np"
            [ngClass]="{
              'np-warning1': formValidationService.checkInvalidTouched(
                addFolderForm,
                'nameFolder'
              ),
              'np-done': formValidationService.checkValid(
                addFolderForm,
                'nameFolder'
              )
            }"
          >
          </i>
          <input
            type="text"
            id="inputNameFolder"
            formControlName="nameFolder"
            aria-describedby="nameFolder"
            class="input wBody14 w-100"
          />
        </div>

        <app-validation-error-message
          [message]="' Por favor, digite o nome da pasta.'"
          *ngIf="
            formValidationService.checkInvalidTouched(
              addFolderForm,
              'nameFolder'
            )
          "
        >
        </app-validation-error-message>
      </div>
    </div>
  </div>
  <div class="footer d-flex justify-content-end m-2">
    <button
      class="np btn btn-secondary d-flex align-items-center"
      ngbAutofocus
      (click)="onCancel()"
    >
      <i class="np-error iconBody18"></i>
      <span class="ml-2"> Fechar</span>
    </button>

    <button
      class="btn btn-primary ml-2 d-flex align-items-center"
      [disabled]="!addFolderForm.valid"
      (click)="addNewFolder()"
    >
      <i class="np-done iconBody18"></i>
      <span class="ml-2"> {{ addFolderAndEditNameFolder.btn }}</span>
    </button>
  </div>
</form>
