import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { AuthLayoutRoutingModule } from './auth-layout-routing.module';
import { AuthLayoutComponent } from './auth-layout.component';
import { AuthLayoutService } from './auth-layout.service';
import { FooterComponent } from './components/footer/footer.component';
import { MenuNavegacaoMobileComponent } from './components/menu-navegacao-mobile/menu-navegacao-mobile.component';
import { NavComponent } from './components/nav/nav.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';



@NgModule({
  declarations: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    AuthLayoutComponent,
    MenuNavegacaoMobileComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthLayoutRoutingModule,
    NgbModule
  ],
  exports: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    BreadcrumbComponent,
    MenuNavegacaoMobileComponent
  ],
  providers: [
    AuthLayoutService
  ]
})
export class AuthLayoutModule { }
