import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Toaster } from '../../models/toaster';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private subject = new Subject<Toaster>();

  public onToaster(): Observable<Toaster> {
    return this.subject.asObservable();
  }

  public showInfo(text: string): void {
    this.open({
      text,
      type: 'info',
      id: this.generateIdToaster(),
      icon: 'np-warning-2',
    });
  }

  public showSuccess(text: string): void {
    this.open({
      text,
      type: 'success',
      id: this.generateIdToaster(),
      icon: 'np-done-line',
    });
  }

  public showError(text: string): void {
    this.open({
      text,
      type: 'danger',
      id: this.generateIdToaster(),
      icon: 'np-info',
    });
  }

  private open(alert: Toaster): void {
    this.subject.next(alert);
  }

  private generateIdToaster(): string {
    return Math.random().toString(36).substring(2, 9);
  }

}
