import { ContentInfoSendDetailService } from './content-info-send-detail.service';
import { Progress } from '../../../models/progress';
import { ButtonDetail } from '../../../models/detail';
import { SendDetailService } from '../send-detail.service';
import { Component, Input, OnInit } from '@angular/core';
import { SendDetail } from '../../../models/detail';

@Component({
  selector: 'app-content-info-send-detail',
  templateUrl: './content-info-send-detail.component.html',
  styleUrls: ['./content-info-send-detail.component.scss'],
})
export class ContentInfoSendDetailComponent implements OnInit {
  @Input() dateSendDetail: SendDetail;
  statusSignature: Progress = this.sendDetailService.getProgress();
  buttons: ButtonDetail[];

  constructor(
    private contentInfoSendDetailService: ContentInfoSendDetailService,
    private sendDetailService: SendDetailService,
  ) {}

  ngOnInit(): void {
    this.buttons = this.contentInfoSendDetailService.getButtons(
      this.statusSignature.loading,
      this.statusSignature.total,
      this.dateSendDetail.type,
    );
  }
  onButton(type: string) {
    console.log(type);
  }
}
