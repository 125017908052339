import { TypeSignatureEnum } from '../enum/type-signature';
import { ITags } from '../models/paper.model';
import { getTagColor, Tag } from '../models/tag';

export default class TagUtil {
    public static formatTag(tags: ITags[]): Tag[] {
        return tags.map(tag => ({
            id: tag.tagId,
            name: tag.name,
            color: getTagColor(tag.color),
        }));
    }

    public static formatTypeSignature(type: string): TypeSignatureEnum {
        if (type === 'E') {
            return TypeSignatureEnum.ELETRONICA;
        } else {
            return TypeSignatureEnum.DIGITAL;
        }
    }

}
