import { Component, OnInit } from '@angular/core';
import { BehaviorCheckboxRadioInputComponent } from '../behavior-checkbox-radio-input/behavior-checkbox-radio-input.component';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent extends BehaviorCheckboxRadioInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onChecked(): void {
    super.onChecked(!this.form.value);
  }

}
