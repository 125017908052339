import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICustomerDataResponse } from 'src/app/shared/models/customer';

@Component({
  selector: 'app-card-customer-select',
  templateUrl: './card-customer-select.component.html',
  styleUrls: ['./card-customer-select.component.scss'],
})
export class CardCustomerSelectComponent implements OnInit {
  @Input() formControlCardCustomer: FormControl;
  @Input() value: ICustomerDataResponse;

  constructor() {}

  ngOnInit(): void {}

  public select(value: ICustomerDataResponse): void {
    this.formControlCardCustomer.setValue(value);
  }

  public custumerIsSelected(): any {
    return {
      selected: this.formControlCardCustomer.value?.customerId === this.value?.customerId,
    };
  }
}
