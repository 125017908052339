export enum AccountTypeIdentifier {
    MonthlyPayment = '1',
    PrePaid = '2',
    Test = '3',
}

export enum AccountTypeName {
    MonthlyPayment = 'Mensalidade',
    PrePaid = 'Pré Pago',
    Test = 'Teste',
}
