export enum TipoPessoaEnum {
    pessoaJuridica = 'Pessoa Jurídica',
    pessoaFisica = 'Pessoa Física',
    todos = 'todos',
}

export enum TipoPessoaLetraEnum {
    pessoaFisica = 'F',
    pessoaJuridica = 'J',
}

export const tipoPessoaEnumDb: string[] = [
    'Pessoa Jurídica',
    'Pessoa Física',
    'todos',
];
