<div ngbDropdown #appSelectUserWithSearchDropdown="ngbDropdown" class="app-select-user-with-search my-1"
    [autoClose]="true">
    <div ngbDropdownToggle class="card p-2" (click)="onClearDropDown()" id="appSelectUserWithSearchDropdown">
        <div *ngIf="this.userSelect === undefined" class="d-flex flex-row justify-content-center align-items-center">
            <span class="ml-auto">Transferir histórico para outro usuário!</span>
            <i class="np ml-auto"
                [ngClass]="appSelectUserWithSearchDropdown.isOpen() ? 'np-angle-up' : 'np-angle-down' "></i>
        </div>
        <div *ngIf="this.userSelect !== undefined" class="d-flex flex-row justify-content-start">
            <i class="iconBody14 np np-user mx-2"></i>
            <strong class="body14 color-gray-300">{{userSelect.name}}</strong>
        </div>
    </div>
    <div ngbDropdownMenu aria-labelledby="appSelectUserWithSearchDropdown" class="w-100">
        <ng-container *ngIf="this.usersFilter.length > 0">
            <div class="inner-addon left-addon d-flex align-items-center two-addon-in-same-input">
                <i class="np np-search"></i>
                <input class="input w-100" placeholder="Buscar" [formControl]="formControlInputSearch" (keyup)="this.onKeyUp()">
            </div>
            <div class="menu-with-user">
                <div *ngFor="let user of usersFilter"
                    class="item-dropdown-user d-flex flex-row justify-content-start align-items-center py-2 my-1"
                    (click)="onSelectUser(user); appSelectUserWithSearchDropdown.close()">
                    <i class="iconBody18 np np-user mx-2"></i>
                    <div class="d-flex flex-column text-truncate">
                        <strong class="body14 color-gray-500 text-nowrap text-truncate">{{user.name}}</strong>
                        <span class="wBody12 color-gray-400 text-nowrap text-truncate">{{user.email}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="menu-no-user"  *ngIf="this.usersFilter.length === 0" (click)="onAddNewUser()">
            <div class="d-flex flex-row justify-content-start align-items-center">
                <i class="iconBody18 np np-plus mx-2"></i>
                <strong class="body14 color-gray-500">Novo usuário</strong>
            </div>
        </div>
    </div>
</div>