<header *ngIf="isVisaoMobile || isVisaoTablet" class="header">
  <div style="color: #ffffff" class="pl-0 pr-2"
      [ngClass]="{'Heading20': !isVisaoMobile, 'Heading16': isVisaoMobile, 'mb-4': !isVisaoMobile, 'mb-3': isVisaoMobile}">
      Usuários
  </div>
</header>

<app-container-header>
  <span class="Heading20 Heading16-sm">Usuários</span>
</app-container-header>
<div class="app-users scroll-content-page container-fluid px-4 pt-2">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 ml-0 p-1 p-md-2 card-use-and-add">
      <app-card-dotted-edges (click)="addUser()">
        <div>
          <h5>
            <i class="np np-add-user mr-3"></i>
            <strong> Adicionar novo usuário</strong>
          </h5>
        </div>
      </app-card-dotted-edges>
    </div>
    <div class="col-12 col-md-6 col-lg-4 ml-0 p-1 p-md-2 card-use-and-add card-w-h-livre" *ngFor="let user of users">
      <app-card-users [user]="user" [userIdAuth]="idUserAuth" (eventDeleteUser)="onDeleteUser($event)">
      </app-card-users>
    </div>
  </div>
</div>