export enum TypeModalConfirm {
    BLOCK = 'BLOCK',
    UNBLOCK = 'UNBLOCK',
    DELETE = 'DELETE',
    TRANSFER = 'TRANSFER'
}

export enum TypeModalConfirmSubscribers {
    UNLINK = 'UNLINK',
    DELETE_SUBSCRIBERS = 'DELETE',
    DELETE_COMPANY = 'DELETE',
}
