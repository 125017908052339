export enum AvatarSizeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XSMALL = 'xsmall'
}

export enum AvatarSizeClassEnum {
    SMALL = 'avatar-small',
    XSMALL = 'avatar-xsmall',
    MEDIUM = 'avatar-medium',
    LARGE = 'avatar-large',
}

export enum AvatarColorEnum {
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    PRIMARY = 'primary',
    WARNING = 'warning',
    DEFAULT = 'default'
}

export enum AvatarColorClassEnum {
    SECONDARY = 'avatar-secundary',
    SUCCESS = 'avatar-success',
    DANGER = 'avatar-danger',
    PRIMARY = 'avatar-primary',
    DEFAULT = 'avatar-color-default',
    WARNING = 'avatar-warning',
}

export enum AvatarTypeFormatEnum {
    SQUARE = 'square',
    CIRCLE = 'circle',
}

export enum AvatarTypeFormatClassEnum {
    SQUARE = 'avatar-square',
    CIRCLE = 'avatar-circle',
}

export enum AvatarColorRandomEnum {
    COLOR_AVATAR_HOT_PINK_DARK = 'color-avatar-hot-pink-dark',
      COLOR_AVATAR_HOT_PINK_LIGHT = 'color-avatar-hot-pink-light',
      COLOR_AVATAR_ORAGE_DARK = 'color-avatar-orage-dark',
      COLOR_AVATAR_ORAGE_LIGHT = 'color-avatar-orage-light',
      COLOR_AVATAR_YELLOW_DARK = 'color-avatar-yellow-dark',
      COLOR_AVATAR_YELLOW_LIGHT = 'color-avatar-yellow-light',
      COLOR_AVATAR_BLUE_DARK = 'color-avatar-blue-dark',
      COLOR_AVATAR_BLUE_LIGHT = 'color-avatar-blue-light',
      COLOR_AVATAR_GREEN_WATER = 'color-avatar-green-water',
      COLOR_AVATAR_GREEN_LIGHT = 'color-avatar-green-light',
      COLOR_AVATAR_GREEN = 'color-avatar-green',
      COLOR_AVATAR_GREEN_DARK = 'color-avatar-green-dark',
      COLOR_AVATAR_NAVY_BLUE_DARK = 'color-avatar-navy-blue-dark',
      COLOR_AVATAR_NAVT_BLUE_LIGHT = 'color-avatar-navt-blue-light',
      COLOR_AVATAR_PURPLE_LIGHT = 'color-avatar-purple-light',
      COLOR_AVATAR_PINK_DARK = 'color-avatar-pink-dark',
      COLOR_AVATAR_PINK_LIGHT = 'color-avatar-pink-light',
}
