<div ngbDropdown #selectSearchDropDown="ngbDropdown" class="w-100 select-search-with-avatar" autoClose="true"
    (focusout)="onFocusOut()">
    <div class="
          inner-addon
          left-addon
          d-flex
          align-items-center
          two-addon-in-same-input
        ">
        <i class="np np-search color-gray-200 size-icon"></i>
        <input [formControl]="formSelect" type="text" class="input color-gray-300 input-custom-icon-reset size-input"
            [placeholder]="placeholder" id="select-search-with-avatar" name="select-search-with-avatar"
            ngbDropdownToggle (keyup)="onKeyUp();selectSearchDropDown.open()" autocomplete="off"
            (focus)="this.eventFocus.emit(true)" />
    </div>
    <div ngbDropdownMenu class="p-1 w-100 dropdown-menu-scroll">
        <div ngbDropdownItem *ngFor="let optionAssunto of listOptionsAssunto" class="pl-2 select-item d-flex"
            (click)="onClickSelect(optionAssunto)">
            <div class="pl-2">
                <div class="select-item-label Heading16-sm body14-md">
                    <div class="text-ellipse color-gray-500">{{optionAssunto.assunto}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="listOptionsAssunto.length === 0" class="pl-2 select-item font-text"
        [ngClass]="{'mobile': isVisaoMobile}">Não há
            elementos para busca</div>
    </div>
</div>