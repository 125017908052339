import { Injectable } from "@angular/core";
import { LacunaWebPkiService } from "../../components/lacuna-web-pki/lacuna-web-pki.service";
import { ModalLocalizeCertificateService } from "../../components/modal-localize-certificate/modal-localize-certificate.service";
import { LoadingService } from "../../components/settings/loading/loading.service";
import { ToasterService } from "../../components/toaster/toaster.service";
import { StateService } from "../state/state.service.spec";
import { LocalizeCertificateConstantes } from "./localize-certificate-constantes";

@Injectable({
  providedIn: 'root'
})

export class LocalizeCertificateService {
  public MESSAGE_CASE_NOT_WEB_PKI_INSTALLED: string = 'Para realizar o login com certificado é preciso a extensão Web PKI em seu navegador. Ao clicar em instalar você será redirecionado para a tela com o passo a passo da instalação.';

  constructor(
    private loadingService: LoadingService,
    private lacunaWebPkiService: LacunaWebPkiService,
    private modalLocalizeCertificateSubscribers: ModalLocalizeCertificateService,
    private stateService: StateService,
    private toasterService: ToasterService
  ) {

  }

  public async onLocalizeCertificate(): Promise<void> {
    this.loadingService.showLoader();
    this.lacunaWebPkiService.messageModalNotInstalled = this.MESSAGE_CASE_NOT_WEB_PKI_INSTALLED;
    try {
      const certificates = await this.lacunaWebPkiService.getCertificates();
      this.loadingService.hideLoader();
      const resultModalDeleteSubscribers: any = await this.modalLocalizeCertificateSubscribers.openLocalizeCertificate(certificates);

      this.stateService.sessionCertificteAnonymous.set(LocalizeCertificateConstantes.SESSION_CERTIFICATE_ANONYMOUS, resultModalDeleteSubscribers.certificado);
      this.stateService.sessionCertificteAnonymous.set(LocalizeCertificateConstantes.SESSION_CERTIFICATE_ANONYMOUS_NOME, resultModalDeleteSubscribers.certificado.subjectName);
      this.stateService.sessionCertificteAnonymous.set(LocalizeCertificateConstantes.SESSION_CERTIFICATE_ANONYMOUS_CPF, resultModalDeleteSubscribers.certificado.cpf);
      this.stateService.sessionCertificteAnonymous.set(LocalizeCertificateConstantes.SESSION_CERTIFICATE_ANONYMOUS_CNPJ, resultModalDeleteSubscribers.certificado.cnpj);
    } catch (error: any) {
      this.toasterService.showInfo('Certificado não selecionado!');
    } finally {
      this.loadingService.hideLoader();
    }
  }

}