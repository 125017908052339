import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LayoutVisaoEnum } from '../../enum/layout-visao.enum';
import { LayoutService } from '../../services/layout/layout.service';
import { IValorLabel } from './../../models/valor-label';
import { BehaviorSelectComponent } from './../behavior-select/behavior-select.component';

@Component({
  selector: 'app-select-search-customized',
  templateUrl: './select-search-customized.component.html',
  styleUrls: ['./select-search-customized.component.scss']
})
export class SelectSearchCustomizedComponent extends BehaviorSelectComponent implements OnInit, OnChanges {

  @Input() iconLeft: string = 'np-search';
  @Input() iconRight: string = 'np-error';
  @Input() isDropdown: boolean = false;

  public isVisaoMobile: boolean = false;

  constructor(
    private layoutVisao: LayoutService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.listOptions = this.options;
    }
  }

  ngOnInit(): void {
    this.isVisaoMobile = this.layoutVisao.setLayoutTela(LayoutVisaoEnum.MOBILE);
    this.listOptions = this.options;
  }

  public onKeyUpSearch(): void {
    this.listOptions = this.options.filter((option: IValorLabel) => {
      return option.label.indexOf(this.formSelect.value) !== -1;
    });
    this.eventKeyUp.emit(this.formSelect.value);
  }

  public onClickSelect(option: IValorLabel): void {
    this.formSelect.setValue(option.valor);
    this.eventSelectOption.emit(option);
  }

}
