import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CalculateByteService {

    public calculeByteInByte(byte: number): number {
        return byte;
    }

    public calculeByteInKiloByte(byte: number): number {
        return byte / 1024;
    }

    public calculeByteInMegaByte(byte: number): number {
        return byte / 1024 / 1024;
    }

    public calculeByteInGigaByte(byte: number): number {
        return byte / 1024 / 1024 / 1024;
    }

    public calculeByteInTeraByte(byte: number): number {
        return byte / 1024 / 1024 / 1024 / 1024;
    }

    public calculeByteInPetaByte(byte: number): number {
        return byte / 1024 / 1024 / 1024 / 1024 / 1024;
    }

}
