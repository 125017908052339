import { ModalFolderComponent } from './modal-folder.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  userFolder,
  Folder,
  ModalFolder,
} from './../../../../shared/models/folder';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalFolderService {
  constructor(private modalService: NgbModal) {}

  private modalFolderView(viewModalFolder: ModalFolder) {
    const modalRef = this.modalService.open(ModalFolderComponent);
    modalRef.componentInstance.viewModal = viewModalFolder;
  }

  getFolders(): Folder[] {
    return [
      {
        name: 'Administrativo',
        document: 0,
        date: new Date(2020, 11, 25, 17, 16, 0),
        value: 'administrativo',
      },
      {
        name: 'Recursos humanos',
        document: 5,
        date: new Date(2020, 11, 25, 17, 16, 0),
        value: 'recursos humanos',
      },
    ];
  }
  getUsers(): userFolder[] {
    return [
      {
        id: 0,
        name: 'Camila Batista',
        email: 'camila.batista@decisaosistemas.com.br',
        shared: true,
        permission: 'Proprietário',
      },
      {
        id: 1,
        name: 'Eduardo Sene',
        email: 'eduardo@decisaosistemas.com.br ',
        shared: false,
        permission: 'Convidado',
      },
      {
        id: 2,
        name: 'Tomás Machado',
        email: 'tomas.machado@decisaosistemas.com.br ',
        shared: true,
        permission: 'Convidado',
      },
    ];
  }

  addFolder() {
    return this.modalFolderView({
      view: 'addFolder',
      icon: 'np-folder-plus',
      title: 'Criar nova pasta',
      btn: 'Salvar',
      nameFolder: '',
    });
  }
  editNameFolder(nameFolder: string) {
    return this.modalFolderView({
      view: 'editNameFolder',
      icon: 'np-folder',
      title: 'Renomear pasta',
      btn: 'Salvar alterações',
      nameFolder: nameFolder,
    });
  }
  shareFolder(nameFolder: string) {
    return this.modalFolderView({
      view: 'shareFolder',
      icon: 'np-user-folder',
      title: 'Compartilhar pasta ',
      btn: 'Compartilhar',
      nameFolder: nameFolder,
    });
  }
  moveDocument(nameFolder: string) {
    return this.modalFolderView({
      view: 'moveDocument',
      icon: 'np-move-folder',
      title: 'Mover documentos da pasta ',
      btn: 'Mover para a pasta',
      nameFolder: nameFolder,
    });
  }
  addDocument(nameFolder: string) {
    return this.modalFolderView({
      view: 'addDocument',
      icon: 'np-move-folder',
      title: 'Adicionar documentos',
      btn: 'Salvar',
      nameFolder: nameFolder,
    });
  }
}
