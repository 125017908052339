import { ModelosAssinaturasEletronicasEnum } from '../enum/modelos-assinaturas-eletronica.enum';
import { UserLocation } from '../services/location/location.model';
import { EletronicSignData } from './eletronicSignData';
import SignatureDocument from './signatureDocument';
import { NSignatureHttpService } from './signatureHttpService.model';



export class SignatureEletronic {
    private documents: SignatureDocument[];
    private location: UserLocation;
    private ip: string;
    private assinatura: EletronicSignData;

    constructor(pDocuments: SignatureDocument[], pAssinaturaEletronica: EletronicSignData, pLocation: UserLocation, pIpAddr: string) {
        this.documents = pDocuments;
        this.location = pLocation;
        this.ip = pIpAddr;
        this.assinatura = pAssinaturaEletronica;
    }

    public gerarRequest(): NSignatureHttpService.ISignatureEletronicRequest {
        const positionError = this.location.errorDescription === null
            ? false
            : this.location.errorDescription;
        const position: NSignatureHttpService.ISignaturePosition | null = this.location.location === null
            ? null
            : { longitude: this.location.location.longitude, latitude: this.location.location.latitude };

        return {
            position,
            positionError,
            ip: this.ip,
            papers: this.documents.map(pDoc => pDoc.gerarDocumentoEletronicoAssinar()),
            signature: this.assinatura.base64,
            dataUrl: this.assinatura.base64,
            draw: this.assinatura.modelo === ModelosAssinaturasEletronicasEnum.desenho,
        };
    }
}

