export enum TypeEventCardEnum {
    DELETETAG,
    SEND,
    TRASH,
    TAG,
    FOLDER,
    NEXT,
    DOWNLOAD,
    RESTORE
}

export enum TypeEventKanbanEnum{
    SEND,
    TRASH,
    TAG,
}
