import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CertificateModel, LacunaWebPKI } from 'web-pki';
import { environment } from '../../../../environments/environment';
import { NLacuna } from '../../models/lacuna';
import { Certificate } from './../../classes/certificate';
import { LoadingService } from './../settings/loading/loading.service';
import { ModalInstallLacunaWebPkiComponent } from './components/modal-install-lacuna-web-pki/modal-install-lacuna-web-pki.component';

@Injectable({
  providedIn: 'root',
})
export class LacunaWebPkiService {

  private TOKEN_LACUNA_WEB_PKI: string = environment.lacunaWebPkiToken;
  public pki: LacunaWebPKI = new LacunaWebPKI(this.TOKEN_LACUNA_WEB_PKI);
  public certificates: Certificate[];
  public isLacunaWebPkiInstalled: boolean = false;
  public isInitialized: boolean = false;
  public messageModalNotInstalled: string = '';

  constructor(
    private modalNgb: NgbModal,
    private loadingService: LoadingService
  ) { }

  private async init(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.pki
        .init({
          ready: () => {
            console.log('LacunaWebPKI is ready');
          },
          notInstalled: await this.onInstallLacunaWebPKI.bind(this),
        })
        .success(async (success: any) => {
          this.isLacunaWebPkiInstalled = success.isInstalled;
          const resultInstall = await this.onInstallLacunaWebPKI();

          if (resultInstall) {
            this.isInitialized = true;
          }

          resolve(resultInstall);
        })
        .fail((fail: any) => {
          reject(fail);
        });
    });
  }

  public async initLacunaWebPki(): Promise<void> {
    await this.getInstanceLacunaWebPKI();
  }

  private filterCertificate(certificates: CertificateModel[]): Certificate[] {

    const certitifcatesFiltered: Certificate[] = [];

    certificates.forEach((certificate: CertificateModel) => {
      if (certificate.pkiBrazil.isPessoaFisica
        || certificate.pkiBrazil.isPessoaJuridica) {
        certitifcatesFiltered.push(new Certificate(certificate));
      }
    });

    return certitifcatesFiltered;

  }

  public async getCertificates(): Promise<Certificate[]> {
    return new Promise(async (resolve, reject) => {
      try {
        (await this.getInstanceLacunaWebPKI())
          .listCertificates()
          .success((certificates: CertificateModel[]) => {
            this.certificates = this.filterCertificate(certificates);
            resolve(this.certificates);
          })
          .fail((fail: any) => {
            reject(fail);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private async onInstallLacunaWebPKI(): Promise<boolean> {
    if (!this.isLacunaWebPkiInstalled) {

      this.loadingService.hideLoader();

      const modalRef = this.modalNgb.open(
        ModalInstallLacunaWebPkiComponent,
        {
          backdrop: 'static',
          keyboard: false,
          backdropClass: 'backdrop-blur-filter',
          centered: true,
        }
      );

      modalRef.componentInstance.message = this.messageModalNotInstalled;

      const userCofirmInstalation = await modalRef.result;

      if (!userCofirmInstalation) {
        return false;
      }

      this.pki.redirectToInstallPage();
      return true;

    }
    return true;
  }

  private async getInstanceLacunaWebPKI(): Promise<LacunaWebPKI> {
    if (!this.isInitialized && this.pki) {
      const resultInstall = await this.init();
      if (!resultInstall) {
        throw new Error('Instalação da extensão cancelada pelo usuário');
      }
    }
    return this.pki;
  }

  public async iniciaPreAutorizacaoAssinatura(pCertificado: Certificate, pQtdTotalAssinatura: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.pki.preauthorizeSignatures({
        certificateThumbprint: pCertificado.thumbprint,
        signatureCount: pQtdTotalAssinatura,
      }).success(() => {
        resolve();
      }).fail((err) => {
        if (err.code === LacunaWebPKI.ErrorCodes.USER_CANCELLED) {
          return reject(new Error('É necessário autorização para poder continuar com a assinatura.'));
        }
        return reject(err.message);
      });
    });
  }

  public async retornaCertificadoEncoded(pCertificado: Certificate): Promise<string> {
    return new Promise((resolve, reject) => {
      this.pki.readCertificate({ thumbprint: pCertificado.thumbprint }).success((pCertEncoded) => {
        resolve(pCertEncoded);
      }).fail((err) => {
        return reject(err.message);
      });
    });
  }

  public async assinarDocumento(pCertificade: Certificate, pDataHash: NLacuna.IStartSignData): Promise<NLacuna.IAssinarDocumentoResponse> {
    return new Promise((resolve) => {
      this.pki.signHash({
        thumbprint: pCertificade.thumbprint,
        hash: pDataHash.toSignHash,
        digestAlgorithm: pDataHash.digestAlgorithm
      }).success((pAssinatura: string) => {
        resolve({ sucesso: true, assinatura: pAssinatura });
      }).fail((pErr) => {
        resolve({ sucesso: false, err: pErr.message });
      });
    });
  }
}
