import { IKanbanField } from 'src/app/shared/models/kanban-documents.model';

export const kanbanField: IKanbanField[] = [
    {
        title: 'Não assinados',
        titleMobile: 'Não assinado',
        color: '#EAEEF6',
        colorDark: '#667AC2',
        isShippable: true,
        papers: [],
        imageEmpty: 'EmptyPaperN',
        messageEmpty: 'Nenhum documento não assinado',
    },
    {
        title: 'Parcialmente assinados',
        titleMobile: 'Parc. assina.',
        color: '#F9F4E5',
        colorDark: '#FFCC40',
        isShippable: true,
        papers: [],
        imageEmpty: 'EmptyPaperP',
        messageEmpty: 'Nenhum documento parcialmente assinado',
    },
    {
        title: 'Totalmente assinados',
        titleMobile: 'Total. assina.',
        color: '#EAF4F0',
        colorDark: '#60C02B',
        isShippable: false,
        papers: [],
        imageEmpty: 'EmptyPaperT',
        messageEmpty: 'Nenhum documento totalmente assinado',
    }
];
