import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { ISubscribers } from 'src/app/shared/models/subscribers.model';
import { ModalLocalizeCertificateComponent } from './modal-localize-certificate.component';
import { Certificate } from '../../classes/certificate';

@Injectable({
  providedIn: 'root'
})
export class ModalLocalizeCertificateService {

  constructor(
    private ngModal: NgbModal,
  ) { }

  public async openLocalizeCertificate(certificado: Certificate[]): Promise<ISubscribers> {
    const modalLocalizeCertificateSubscribers = this.ngModal.open(ModalLocalizeCertificateComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      backdropClass: 'backdrop-blur-filter',
    });

    modalLocalizeCertificateSubscribers.componentInstance.subscribers = certificado;
    return await modalLocalizeCertificateSubscribers.result;
  }

  public async openUnlinkSubscriber(): Promise<{success: boolean}> {
    const modalLocalizeCertificateSubscribers = this.ngModal.open(ModalLocalizeCertificateComponent, {
      size: 'md',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      backdropClass: 'backdrop-blur-filter',
    });

    modalLocalizeCertificateSubscribers.componentInstance.unlinkSubscriber = true;
    return await modalLocalizeCertificateSubscribers.result;
  }

}
