import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeModalConfirm } from 'src/app/shared/enum/tipoModalEnum';
import { IUser } from 'src/app/shared/models/user.model';
import { IValorLabel } from 'src/app/shared/models/valor-label';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ModalUsersService } from '../modal-users/modal-users.service';

@Component({
  selector: 'app-modal-confirm-blocker-or-delete',
  templateUrl: './modal-confirm-blocker-or-delete.component.html',
  styleUrls: ['./modal-confirm-blocker-or-delete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalConfirmBlockerOrDeleteComponent implements OnInit {

  public typeModal: TypeModalConfirm;
  /**
   * Usuário a ser excluido.
   */
  public usuarioExcluir: IUser;
  public formValues: IValorLabel[];
  /**
   * Lista de usuários passiveis a receberem a transferencia.
   */
  public usuariosTransferir: Array<IUser>;

  public formControlSelectUserWithSearch: FormControl;

  constructor(
    private userService: UserService,
    private modalUsersService: ModalUsersService,
    private modalActive: NgbActiveModal
  ) { }

  async ngOnInit(): Promise<void> {
    const usersDb = await this.userService.getUsers();
    this.usuariosTransferir = usersDb.filter(pUser => pUser.email !== this.usuarioExcluir.email);
    this.formControlSelectUserWithSearch = new FormControl(null, Validators.required);
  }

  public isModalBlock(): boolean {
    return this.typeModal === TypeModalConfirm.BLOCK;
  }

  public isModalUnBlock(): boolean {
    return this.typeModal === TypeModalConfirm.UNBLOCK;
  }

  public isModalDelete(): boolean {
    return this.typeModal === TypeModalConfirm.DELETE;
  }

  public isModalTransfer(): boolean {
    return this.typeModal === TypeModalConfirm.TRANSFER;
  }

  private close(value: boolean, idUserTransference?: number): void {
    this.modalActive.close({ confirm: value, idUserTransference });
  }

  public async confirm(): Promise<void> {
    if (this.isModalDelete()) {
      this.typeModal = TypeModalConfirm.TRANSFER;
      return;
    }
    if (this.isModalTransfer()) {
      this.close(true, this.formControlSelectUserWithSearch.value);
    }
    this.close(true);
  }

  public cancel(): void {
    this.close(false);
  }

  public openFormNewUser(): void {
    this.modalUsersService.open();
  }
}
