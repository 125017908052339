import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITagUpdate } from 'src/app/shared/models/tagsModel';
import { ModalTagComponent } from './modal-tag.component';

@Injectable({
  providedIn: 'root',
})
export class ModalTagService {
  constructor(private modalService: NgbModal) {}

  private modalTagView(title: string, labelButton: string, acao: string, tag: ITagUpdate | null) {
    const modalRef = this.modalService.open(ModalTagComponent, {
      centered: true,
      windowClass: 'windowModal',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.labelBotao = labelButton;
    modalRef.componentInstance.acao = acao;
    modalRef.componentInstance.tagEdit = tag;
    return (<ModalTagComponent>modalRef.componentInstance).confirmResult$;
  }

  modalTagSign() {
    return this.modalTagView('Adicionar marcador', 'Salvar', 'inclusao', null);
  }
  modalTagEdit(tag: ITagUpdate) {
    return this.modalTagView('Editar marcador', 'Salvar alterações', 'edicao', tag);
  }
}
