import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteComponent } from './pages/site/site.component';


const routes: Routes = [
  {
    path: '',
    component: SiteComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./core/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
  },
  {
    path: 'not-auth',
    loadChildren: () => import('./core/not-auth-layout/not-auth-layout.module').then(m => m.NotAuthLayoutModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./core/login-layout/login-layout.module').then(m => m.LoginLayoutModule),
  },
  {
    path: 'signature',
    loadChildren: () => import('./core/signature-layout/signature-layout.module').then(m => m.SignatureLayoutModule),
  },
  {
    path: 'document/paper',
    loadChildren: () => import('./core/document-layout/document-layout.module').then(m => m.DocumentLayoutModule),
  },
  {
    path: 'expired',
    loadChildren: () => import('./modules/assinar-expirado/assinar-expirado.module').then(m => m.AssinarExpiradoModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
