<div class="w-100 h-100 app-card-with-icon-title-radio" [ngClass]="{
    currentSelected:
      this.formControl.value === this.value
  }" (click)="this.selectOption(this.value)">
    <div class="row py-3 px-2">
        <div class="col-2 col-md-9 pr-0 d-flex d-md-inline align-items-center justify-content-center">
            <i *ngFor="let icon of this.icons" class="app-icon-card np" [ngClass]="[icon]"></i>
        </div>
        <div class="col-7 d-inline d-md-none ">
            <div class="d-flex flex-column">
                <span class="Heading16">{{this.title}}</span>
                <span class="wBody12">{{this.description}}</span>
            </div>
        </div>
        <div class="col-3">
            <app-radio class="ml-auto" [form]="this.formControl" [group]="this.group" [value]="this.value">
            </app-radio>
        </div>
        <div class="col-12 d-none d-md-inline">
            <div class="d-flex flex-column">
                <span class="Heading16">{{this.title}}</span>
                <span class="wBody12">{{this.description}}</span>
            </div>
        </div>
    </div>
</div>