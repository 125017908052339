<div class="modal-termos-de-uso"
     *ngIf="showModalTermos">
  <div class="body">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center mb-2">
        <app-avatar icon="np-Icon-file"
                    size="small"
                    color="primary"></app-avatar>
        <span class="Heading20 mx-4">Termos e condições de uso</span>
      </div>
      <i class="np-close iconBody22 color-400"
         (click)="closeModalTermos()"></i>
    </div>
    <div class="termos">
      <ol>
        <li><strong>APRESENTA&Ccedil;&Atilde;O E DEFINI&Ccedil;&Otilde;ES</strong>
          <ul>
            <li>O dom&iacute;nio eletrônico <em>https://www.npaper.com.br</em>, hospeda p&aacute;ginas de
              divulgaç&atilde;o e publicidade e d&aacute; acesso
              ao software para assinaturas eletrônicas, <strong>NPAPER</strong>. O mesmo &eacute;
              mantido e operado por <strong>DECIS&Atilde;O INFORM&Aacute;TICA LTDA-EPP</strong>, sua
              &uacute;nica propriet&aacute;ria, com sede administrativa na Rua 137, No 556, Quadra 50,
              Lote 01, Sala 101, 1&ordm; Andar, Edif&iacute;cio Alvorada, Setor Marista, Goi&acirc;nia,
              Goi&aacute;s, CEP: 74170-120, inscrita no CNPJ/MF sob
              o n&ordm; 25.076.589/0001-32.</li>
            <li>Para os fins destes Termos e Condi&ccedil;&otilde;es de Uso, as palavras abaixo ter&atilde;o
              os seguintes significados:
              <ul>

                <li><u>Plataforma</u>: &eacute; o <em>website</em> desenvolvido, mantido e operado por
                  <strong>DECIS&Atilde;O INFORM&Aacute;TICA LTDA-EPP</strong> e todas as
                  p&aacute;ginas nele
                  compreendidas, hospedado no dom&iacute;nio <em>www.npaper.com.br</em>;
                </li>
                <li><u>Contratantes</u>: as pessoas, seja f&iacute;sica ou jur&iacute;dica, que tenham
                  firmado
                  Contrato
                  de Licen&ccedil;a de Uso de Software com a desenvolvedora do <strong>NPAPER</strong>
                  e envia
                  aos
                  seus clientes e/ou parceiros, os <em>links</em> de acesso ao painel de assinaturas;
                </li>
                <li><u>Usu&aacute;rios</u>: quaisquer pessoas que acessem a Plataforma, sejam os
                  Contratantes,
                  as partes
                  envolvidas nos documentos a serem assinados ou visitantes an&ocirc;nimos;</li>
                <li><u>Conta</u>: dados de identifica&ccedil;&atilde;o de Usu&aacute;rio (Nome, CPF ou
                  CNPJ e
                  e-mail)
                  para acesso &agrave; Plataforma;</li>
                <li><u>Assinatura digital</u>: &eacute; um tipo de assinatura eletr&ocirc;nica gerada
                  por uma
                  autoridade
                  certificadora (ICP-Brasil) e armazenada em dispositivo eletr&ocirc;nico (certificado
                  digital);</li>
                <li><u>Assinatura eletr&ocirc;nica:</u> &eacute; a assinatura de pr&oacute;prio punho
                  colhida em
                  dispositivos eletr&ocirc;nicos e admitida consensualmente pelas partes como
                  v&aacute;lida e
                  aceita
                  pela a quem for oposto o documento;</li>
                <li><u>Certificado digital:</u> &eacute; um arquivo eletr&ocirc;nico gerado por uma
                  autoridade
                  certificadora (ICP-Brasil) que cont&eacute;m a identidade virtual para uma pessoa
                  f&iacute;sica ou
                  jur&iacute;dica, e por ele se pode fazer transa&ccedil;&otilde;es com garantia de
                  autenticidade;
                </li>
                <li><u>Carimbo do tempo:</u> &eacute; um selo que atesta a data e a hora oficial do
                  Brasil e
                  &eacute;
                  emitido por uma Autoridade de Carimbo do Tempo credenciada pelo Instituto da
                  Tecnologia da
                  Informa&ccedil;&atilde;o;</li>
                <li><u>ICP-Brasil</u>: autoridade brasileira gestora das pol&iacute;ticas para
                  emiss&atilde;o e
                  controle
                  de certificados digitais.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <ul>
        <ul>
          <li>Este documento relaciona os termos e condi&ccedil;&otilde;es que devem ser observados pelo
            <strong>NPAPER</strong> e pelos Usu&aacute;rios (conforme defini&ccedil;&atilde;o abaixo) na
            utiliza&ccedil;&atilde;o da Plataforma, de suas ferramentas e de suas funcionalidades.
          </li>
          <li>O acesso &agrave; Plataforma ou o uso das fun&ccedil;&otilde;es dispon&iacute;veis implicam
            em concord&acirc;ncia com estes Termos e Condi&ccedil;&otilde;es de Uso, raz&atilde;o pela
            qual deve o Usu&aacute;rio l&ecirc;-lo com aten&ccedil;&atilde;o antes de prosseguir com o
            acesso ou iniciar o uso da Plataforma.</li>
          <li>O <strong>NPAPER</strong> &eacute; uma Plataforma <em>online</em> que registra assinatura
            digital e/ou eletr&ocirc;nica e, opcionalmente, registra Carimbo do Tempo com hora oficial
            do Brasil em documentos eletr&ocirc;nicos no formato .PDF, al&eacute;m de
            armazen&aacute;-los e disponibiliz&aacute;-los para consulta e impress&atilde;o.</li>
          <li>O <strong>NPAPER</strong> n&atilde;o atua como prestador de servi&ccedil;os de consultoria
            ou qualquer forma de intermedia&ccedil;&atilde;o ou participa&ccedil;&atilde;o nos
            neg&oacute;cios realizados entre seus usu&aacute;rios. Em raz&atilde;o disso, a Plataforma
            n&atilde;o possui qualquer responsabilidade decorrente da rela&ccedil;&atilde;o existente
            entre seus Contratantes e Usu&aacute;rios.</li>
          <li>Para utilizar funcionalidades do <strong>NPAPER,</strong> o usu&aacute;rio declara ter pleno
            conhecimento destes Termos e Condi&ccedil;&otilde;es de Uso e da <a
               href="https://decisaosistemas.com.br/politica-de-privacidade/"
               target="_blank">Pol&iacute;tica de
              Privacidade</a>, a qual cont&eacute;m informa&ccedil;&otilde;es claras e completas sobre
            coleta,
            uso, armazenamento, tratamento e prote&ccedil;&atilde;o dos dados pessoais do
            Usu&aacute;rio.</li>
        </ul>
      </ul>
      <p>&nbsp;</p>
      <ol start="2">
        <li><strong>REGRAS DE UTILIZA&Ccedil;&Atilde;O DA PLATAFORMA</strong>
          <ul>
            <li>O Usu&aacute;rio se obriga a utilizar a Plataforma respeitando e observando estes Termos e
              Condi&ccedil;&otilde;es de Uso, a <a href="https://decisaosistemas.com.br/politica-de-privacidade/"
                 target="_blank">Pol&iacute;tica de Privacidade</a>, a legisla&ccedil;&atilde;o
              vigente, os costumes e a ordem p&uacute;blica.</li>
            <li>Determinados atos que incluem, mas n&atilde;o se limitam, a usos com prop&oacute;sitos
              ilegais, corrup&ccedil;&atilde;o de informa&ccedil;&otilde;es ou dados,
              viola&ccedil;&atilde;o de direitos de propriedade industrial ou autoral,
              viola&ccedil;&atilde;o de direitos de terceiros (inclusive crian&ccedil;as), envio de
              mensagens de cunho amea&ccedil;ador, racista ou pornogr&aacute;fico, que interfiram ou
              interrompam o funcionamento da Plataforma e/ou qualquer tentativa de
              propaga&ccedil;&atilde;o de v&iacute;rus ou programa n&atilde;o desejado, poder&atilde;o
              imediatamente resultar no encerramento de qualquer rela&ccedil;&atilde;o entre o
              <strong>NPAPER</strong> e o Usu&aacute;rio.
            </li>
            <li>O <strong>NPAPER</strong> poder&aacute;, a seu exclusivo crit&eacute;rio, bloquear,
              restringir, desabilitar ou impedir o acesso de qualquer Usu&aacute;rio &agrave; Plataforma,
              total ou parcialmente, sem qualquer aviso pr&eacute;vio, sempre que for detectada uma
              conduta contr&aacute;ria ao disposto nestes Termos e Condi&ccedil;&otilde;es de Uso ou na
              <a href="https://decisaosistemas.com.br/politica-de-privacidade/"
                 target="_blank">Pol&iacute;tica de Privacidade</a> da Plataforma, sem preju&iacute;zo das
              medidas
              administrativas, extrajudiciais e judiciais que julgar convenientes.
            </li>
            <li>O <strong>NPAPER</strong>, bem como seus Contratantes, estar&aacute; isento de qualquer
              responsabilidade, n&atilde;o devendo lhes ser imposta qualquer reclama&ccedil;&atilde;o,
              notifica&ccedil;&atilde;o, intima&ccedil;&atilde;o judicial ou extrajudicial decorrente de
              eventuais danos que os Usu&aacute;rios possam ter causado por m&aacute;
              utiliza&ccedil;&atilde;o da Plataforma ou por viola&ccedil;&atilde;o a estes Termos e
              Condi&ccedil;&otilde;es de Uso ou &agrave;s <a
                 href="https://decisaosistemas.com.br/politica-de-privacidade/"
                 target="_blank">Pol&iacute;ticas de Privacidade</a>, sejam estas
              cometidas por eles ou qualquer pessoa agindo em seu nome, com ou sem seu consentimento.</li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="3">
        <li><strong>CADASTRO DE USU&Aacute;RIOS</strong>
          <ul>
            <li>Para obter acesso ao conte&uacute;do completo e a todas as ferramentas e funcionalidades da
              Plataforma, os Usu&aacute;rios da Contratante dever&aacute; criar uma Conta, na qual
              preencher&aacute; de forma correta e completa todos os dados solicitados, inclusive os de
              pagamento, os quais ser&atilde;o utilizados para sua identifica&ccedil;&atilde;o e
              armazenamentos dos documentos eletr&ocirc;nicos carregados para assinatura.</li>
            <li>Para os demais Usu&aacute;rios, o acesso &agrave; Plataforma ser&aacute; feito a partir de
              <em>link</em> que lhes ser&atilde;o enviados pelos Contratantes que solicitam as assinaturas
              e esse <em>link</em> os levar&aacute; &agrave; p&aacute;gina em que ser&aacute; feita a
              assinatura digital, a partir do uso de certificado digital pr&oacute;prio ou a assinatura
              eletr&ocirc;nica, de pr&oacute;prio punho com ou sem caneta <em>touch</em>.
            </li>
            <li>A cria&ccedil;&atilde;o de cada Conta e a apresenta&ccedil;&atilde;o do certificado digital
              pressup&otilde;em o consentimento expresso sobre coleta, uso, armazenamento e tratamento de
              dados pessoais pelo <strong>NPAPER</strong> para realizar qualquer procedimento ou processo
              relacionado &agrave; assinatura digital, inclusive processamento de pagamentos,
              armazenamento de documentos eletr&ocirc;nicos e respectiva impress&atilde;o.</li>
            <li>A cada Usu&aacute;rio Contratante &eacute; permitida a cria&ccedil;&atilde;o de apenas uma
              Conta de acesso, identificada pela inscri&ccedil;&atilde;o do usu&aacute;rio no Cadastro de
              Pessoas F&iacute;sicas (CPF) ou Cadastro Nacional de Pessoas Jur&iacute;dicas (CNPJ) e o
              <strong>NPAPER</strong> se reserva ao direito de suspender ou cancelar quaisquer Contas de
              acesso em duplicidade.
            </li>
            <li>Ao completar a Conta de acesso, o Usu&aacute;rio Contratante declara que as
              informa&ccedil;&otilde;es fornecidas s&atilde;o completas, verdadeiras, atuais e precisas,
              sendo de sua total responsabilidade a atualiza&ccedil;&atilde;o de seus dados sempre que
              houver modifica&ccedil;&atilde;o de <em>e-mail</em>, endere&ccedil;o ou qualquer outra
              informa&ccedil;&atilde;o relevante.</li>
            <li>O <strong>NPAPER</strong> poder&aacute; recusar, suspender ou cancelar a Conta de acesso de
              um Usu&aacute;rio sempre que suspeitar que as informa&ccedil;&otilde;es fornecidas
              s&atilde;o falsas, incompletas, desatualizadas ou imprecisas.</li>
            <li>O Usu&aacute;rio, no momento da cria&ccedil;&atilde;o de sua Conta de acesso,
              determinar&aacute; seu nome de usu&aacute;rio (<em>login</em>) e sua senha de acesso, cuja
              responsabilidade e manuten&ccedil;&atilde;o pelo sigilo ser&aacute; de sua exclusiva
              responsabilidade, devendo este comunicar imediatamente ao <strong>NPAPER</strong> em caso de
              perda, divulga&ccedil;&atilde;o, roubo ou uso n&atilde;o autorizado de sua Conta.</li>
            <li>Menores de 18 anos n&atilde;o poder&atilde;o utilizar a Plataforma, a menos que sejam
              representados ou assistidos por seus pais ou respons&aacute;veis legais.</li>
            <li>A cria&ccedil;&atilde;o e utiliza&ccedil;&atilde;o de uma Conta em nome de um menor
              pressup&otilde;e sua representa&ccedil;&atilde;o por uma pessoa maior de 18 anos, que
              ser&aacute; reputada respons&aacute;vel civil e criminalmente por qualquer ato realizado,
              viola&ccedil;&atilde;o cometida ou declara&ccedil;&atilde;o falsa, incompleta, desatualizada
              ou imprecisa prestada pelo menor Usu&aacute;rio.</li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="4">
        <li><strong>DADOS PESSOAIS, PRIVACIDADE E SEGURAN&Ccedil;A</strong>
          <ul>
            <li>O <strong>NPAPER</strong> disp&otilde;e de uma <a
                 href="https://decisaosistemas.com.br/politica-de-privacidade/"
                 target="_blank">Pol&iacute;tica de Privacidade</a>, contrato no
              qual regula a coleta, guarda e utiliza&ccedil;&atilde;o de dados pessoais, bem como a
              seguran&ccedil;a destes.</li>
            <li>A <a href="https://decisaosistemas.com.br/politica-de-privacidade/"
                 target="_blank">Pol&iacute;tica de Privacidade</a> integra inseparavelmente estes Termos
              e
              Condi&ccedil;&otilde;es de Uso, ressaltando-se que os dados de utiliza&ccedil;&atilde;o da
              Plataforma ser&atilde;o arquivados conforme os termos da legisla&ccedil;&atilde;o em vigor.
            </li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="5">
        <li><strong>OFERTA DE FUNCIONALIDADES</strong>
          <ul>
            <li>O <strong>NPAPER</strong> disponibilizar&aacute; fun&ccedil;&atilde;o para registro de
              assinatura digital e/ou eletr&ocirc;nica e, opcionalmente, Carimbo do Tempo com a hora
              oficial do Brasil, em documentos firmados por Contratantes e seus clientes e/ou parceiros.
            </li>
            <li>A assinatura digital conferida pelo <strong>NPAPER</strong> est&aacute; dentro dos
              padr&otilde;es definidos pela Infraestrutura de Chaves P&uacute;blicas Brasileira
              (ICP-Brasil), o que garante a autenticidade, a integridade e a validade jur&iacute;dica de
              documentos em forma eletr&ocirc;nica, bem como das aplica&ccedil;&otilde;es de suporte e do
              uso de certificados digitais.</li>
            <li>Opcionalmente as assinaturas digitais poder&atilde;o contar com a certifica&ccedil;&atilde;o
              do Carimbo de Tempo, selo que atesta a data e a hora exatas em que um documento foi criado
              e/ou recebeu a assinatura digital.</li>
            <li>Ser&aacute; gerado pela Plataforma, sempre que um documento for devidamente assinado por
              todos os indicados pelos Usu&aacute;rios Contratantes, uma vers&atilde;o final para
              impress&atilde;o, a qual contar&aacute; com o c&oacute;digo de autentica&ccedil;&atilde;o,
              possibilitando que qualquer pessoa acesse o arquivo original e verifique, por conta
              pr&oacute;pria, a autenticidade do documento e das assinaturas digitais.</li>
            <li>Para verificar a validade, autenticidade e integridade de assinaturas digitais com
              padr&atilde;o ICP-Brasil, acessar o <em>website</em> <a
                 href="https://verificador.iti.gov.br/">https://verificador.iti.gov.br/</a>.</li>
            <li>Os documentos eletr&ocirc;nicos assinados digitalmente com certificados digitais ou
              assinados eletronicamente permanecer&atilde;o armazenados e dispon&iacute;veis para acesso
              por 5 anos. Expirado esse prazo, os documentos s&atilde;o definitivamente eliminados, sem
              aviso pr&eacute;vio.</li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="6">
        <li><strong>PRE&Ccedil;OS E PAGAMENTOS</strong>
          <ul>
            <li>S&atilde;o respons&aacute;veis pelo pagamento do uso da Plataforma apenas os Usu&aacute;rios
              Contratantes, que firmaram Contrato de Licen&ccedil;a de Uso de Software com a
              desenvolvedora do <strong>NPAPER</strong>, no qual constam todas as
              informa&ccedil;&otilde;es acerca dos pre&ccedil;os e condi&ccedil;&otilde;es de pagamento.
            </li>
            <li>Os demais Usu&aacute;rios, que acessam a Plataforma a partir de <em>link</em> para
              Assinatura digital e/ou Eletr&ocirc;nica, poder&atilde;o apenas acessar os documentos
              eletr&ocirc;nicos que lhes foram encaminhados para assinar, al&eacute;m de poder imprimir a
              vers&atilde;o final. Caso queiram contratar as demais funcionalidades, seja para assinar
              documentos pr&oacute;prios, envi&aacute;-los para que sejam assinados ou verificar a
              autenticidade de alguma assinatura, devem entrar em contato com a desenvolvedora do
              <strong>NPAPER</strong> para obter maiores informa&ccedil;&otilde;es de
              contrata&ccedil;&atilde;o.
            </li>
          </ul>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="7">
        <li><strong>PROPRIEDADE INTELECTUAL, DIREITOS AUTORAIS E MARCAS</strong>
          <ul>
            <li>Todo o material do <strong>NPAPER</strong>, incluindo sua apresenta&ccedil;&atilde;o,
              <em>layout</em>, marcas, logotipos, produtos, sistemas, denomina&ccedil;&otilde;es de
              funcionalidades, programas, bases de dados, imagens, arquivos, etc, &eacute; protegido pela
              legisla&ccedil;&atilde;o de Propriedade Intelectual, sendo de titularidade da desenvolvedora
              da Plataforma, sendo vedada sua reprodu&ccedil;&atilde;o, distribui&ccedil;&atilde;o e
              transmiss&atilde;o sem consentimento expresso.
            </li>
            <li>O uso indevido dos materiais protegidos por propriedade intelectual &eacute; caracterizado
              como infra&ccedil;&atilde;o legal e sujeita o infrator &agrave;s a&ccedil;&otilde;es
              judiciais cab&iacute;veis para indeniza&ccedil;&atilde;o por eventuais danos causados, seja
              &agrave; Plataforma, a sua desenvolvedora ou seus Usu&aacute;rios, sem preju&iacute;zo de
              perdas e danos e eventuais honor&aacute;rios advocat&iacute;cios.</li>
          </ul>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="8">
        <li><strong>COMUNICA&Ccedil;&Atilde;O</strong>
          <ul>
            <li>O <strong>NPAPER</strong> disponibilizar&aacute; uma Central de Atendimento para realizar
              comunica&ccedil;&atilde;o com os Usu&aacute;rios, a qual ser&aacute; operada pelos canais
              abaixo:</li>
            <ul>
              <li>Pelo <em>chat</em> dispon&iacute;vel na Plataforma 24h/dia, 7 dias/semana, com atendentes de segunda
                a sexta-feira, exceto feriados, das 08:00 &agrave;s 18:00hr;</li>
              <li>Pelo telefone <strong>(062) 4013-9300</strong>, de segunda a sexta-feira, exceto feriados, das 08:00
                &agrave;s 18:00hr;</li>
              <li>Por <em>e-mail</em>, pelo endere&ccedil;o <strong>npaper@decisaosistemas.com.br</strong>.</li>
            </ul>
          </ul>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="9">
        <li><strong>DURA&Ccedil;&Atilde;O E ATUALIZA&Ccedil;&Atilde;O</strong>
          <ul>
            <li>Este instrumento de Termos e Condi&ccedil;&otilde;es de Uso entra em vigor no momento em que
              o Usu&aacute;rio declarar que o aceita e ter&aacute; dura&ccedil;&atilde;o enquanto a
              Plataforma estiver ativa e em funcionamento.</li>
            <li>Este instrumento de Termos e Condi&ccedil;&otilde;es de Uso poder&aacute; ser revisado,
              aprimorado, modificado e atualizado de forma unilateral e a qualquer momento.</li>
            <li>A continuidade de acesso ou utiliza&ccedil;&atilde;o da Plataforma, depois de divulgada
              qualquer modifica&ccedil;&atilde;o, valer&aacute; como aceite para as novas
              condi&ccedil;&otilde;es aplicadas aos Termos e Condi&ccedil;&otilde;es de Uso.</li>
            <li>Caso algum Usu&aacute;rio n&atilde;o concorde com as modifica&ccedil;&otilde;es realizadas,
              poder&aacute; cancelar seu cadastro a partir de pedido de exclus&atilde;o da Conta, o que
              n&atilde;o excluir&aacute; sua responsabilidade perante a aceita&ccedil;&atilde;o da
              vers&atilde;o anterior dos Termos e Condi&ccedil;&otilde;es de Uso.</li>
          </ul>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="10">
        <li><strong>DISPOSI&Ccedil;&Otilde;ES GERAIS</strong>
          <ul>
            <li>Para as Assinaturas Digitais realizadas, ser&aacute; considerado o hor&aacute;rio oficial de
              Bras&iacute;lia, inclusive nos per&iacute;odos de hor&aacute;rio de ver&atilde;o.</li>
            <li>Nem a Plataforma e nem os Usu&aacute;rios Contratantes poder&atilde;o ser responsabilizados
              quando o descumprimento de obriga&ccedil;&atilde;o prevista nestes Termos e
              Condi&ccedil;&otilde;es de Uso for causado por caso fortuito ou for&ccedil;a maior.</li>
            <li>
              O <strong>NPAPER</strong> atua em &acirc;mbito virtual sempre respeitando seus Usuários, agindo em
              conformidade com
              as disposições do Código de Defesa do Consumidor (Lei nº 8.078/90), do Marco Civil da
              Internet (Lei nº 12.965/14), da LGPD e das demais normas pertinentes.
            </li>
          </ul>
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <ol start="11">
        <li><strong>FORO DE ELEI&Ccedil;&Atilde;O</strong>
          <ul>
            <li>A Plataforma &eacute; controlada e operada pela <strong>DECIS&Atilde;O INFORM&Aacute;TICA
                LTDA-EPP</strong>, situada em Goi&acirc;nia, Goi&aacute;s, podendo ser
              acessada de qualquer dispositivo conectado &agrave; internet, independentemente de sua
              localiza&ccedil;&atilde;o geogr&aacute;fica.</li>
            <li>Ao acessar a Plataforma, o Usu&aacute;rio concorda que a legisla&ccedil;&atilde;o
              aplic&aacute;vel para reger estes Termos e Condi&ccedil;&otilde;es de Uso, bem como a
              <a href="https://decisaosistemas.com.br/politica-de-privacidade/"
                 target="_blank">Pol&iacute;tica de Privacidade</a>, &eacute; aquela vigente no
              territ&oacute;rio da
              Rep&uacute;blica Federativa do Brasil.
            </li>
            <li>O Usu&aacute;rio concorda em eleger o foro da Comarca de Goi&acirc;nia,
              Goi&aacute;s, como &uacute;nico competente para dirimir qualquer quest&atilde;o ou
              controv&eacute;rsia oriunda de uso da Plataforma, renunciando expressamente a qualquer
              outro, por mais privilegiado que seja.</li>
          </ul>
        </li>
      </ol>
    </div>
    <div class="d-flex justify-content-end"
         (click)="closeModalTermos()">
      <button class="btn btn-icon color-400 my-2">
        <i class="np-error m-1"></i>
        <span class="body14">
          Fechar
        </span>
      </button>
    </div>
  </div>
</div>
