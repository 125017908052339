import { take, switchMap } from 'rxjs/operators';
import { Folder } from './../../../../shared/models/folder';
import { ModalFolderService } from '../modal-folder/modal-folder.service';
import { ModalDeleteService } from './../../../../shared/components/modal-delete/modal-delete.service';
import { CardFolderService } from './card-folder.service';
import { Component, Input, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-card-folder',
  templateUrl: './card-folder.component.html',
  styleUrls: ['./card-folder.component.scss'],
})
export class CardFolderComponent implements OnInit {
  @Input() viewCard: string; //createCardFolder | cardFolder
  dateCardFolder: Folder = this.cardFolderService.getDateCardFolder();
  sharedUsers = this.cardFolderService.getSharedUsers();

  constructor(
    private cardFolderService: CardFolderService,
    private modalFolderService: ModalFolderService,
    private modalDeleteService: ModalDeleteService,
  ) {}

  ngOnInit(): void {}

  onCreateNewFolder() {
    this.modalFolderService.addFolder();
  }
  onEditNameFolder() {
    this.modalFolderService.editNameFolder(this.dateCardFolder.name);
  }
  onShareFolder() {
    this.modalFolderService.shareFolder(this.dateCardFolder.name);
  }
  onMoveDocument() {
    this.modalFolderService.moveDocument(this.dateCardFolder.name);
  }
  onDelete() {
    const result$ = this.modalDeleteService.confirmationDeleteTag();
    result$
      .asObservable()
      .pipe(
        take(1),
        switchMap((result: any) => (result ? ['deletado'] : EMPTY)),
      )
      .subscribe((result: any) => console.log(result));
  }
}
