import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotAuthLayoutComponent } from './not-auth-layout.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { NotAuthLayoutRoutingModule } from './not-auth-layout-routing.module';
import { NotAuthLayoutService } from './not-auth-layout.service';
import { NavComponent } from './componentes/nav/nav.component';
import { SidebarComponent } from './componentes/sidebar/sidebar.component';
import { FooterComponent } from './componentes/footer/footer.component';

@NgModule({
  declarations: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    NotAuthLayoutComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NotAuthLayoutRoutingModule
  ],
  exports: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    BreadcrumbComponent,
  ],
  providers: [
    NotAuthLayoutService
  ]
})
export class NotAuthLayoutModule { }
