import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValueCheckdEvent } from '../../models/BehaviorCheckboxRadioInput';
import { ValueCheckboxRadioInput } from './../../models/BehaviorCheckboxRadioInput';

@Component({
  template: '',
})
export class BehaviorCheckboxRadioInputComponent implements OnInit {

  public id: string = 'radio-default-' + this.generateStringId();
  public name: string = 'nameDefault' + this.generateStringId();

  @Input() public label: string = '';
  @Input() public form: FormControl;
  @Input() public value: ValueCheckboxRadioInput;

  @Input() public set group(value: string) {
    this.name = value;
  }

  @Output() public checkedEvent: EventEmitter<ValueCheckdEvent> =
    new EventEmitter<ValueCheckdEvent>();

  constructor() { }

  ngOnInit(): void {
    if (!this.form) {
      throw new Error('form is required: set attribute form in component');
    }
    if (!this.group) {
      throw new Error('attribute group is required: set attribute group in component');
    }
  }

  public onChecked(value: any): void {
    this.form.setValue(value);
    this.checkedEvent.emit({
      value: this.form.value,
      checked: !!this.form.value,
    });
  }

  public isDesabled(): boolean {
    return !!this.form?.disabled;
  }

  public isChecked(): boolean {
    return !!this.form.value;
  }

  public disableCheckIfDisabledAndNotChecked(): { [key: string]: boolean } {
    return {
      'not-checked-disable': this.isDesabled() && this.isChecked() === false,
    };
  }

  protected generateStringId(): string {
    return Math.random().toString(15).substring(2, 8);
  }

}
