import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home-not-logged',
  templateUrl: './home-not-logged.component.html',
  styleUrls: ['./home-not-logged.component.scss'],
})
export class HomeNotLoggedComponent implements OnInit {

  ngOnInit(): void {

  }
}
