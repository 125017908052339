import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BatchSignRoutingModule } from './batch-sign-routing.module';
import { BatchSignComponent } from './views/batch-sign/batch-sign.component';
import { ModalSelectSignatureTypeService } from '../new-documents/components/modal-select-signature-type/modal-select-signature-type.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { AssinarDocumentoStorageService } from '../assinar-documento/assinar-documento.storage.service';
import { SignatureLayoutStorageService } from 'src/app/core/signature-layout/signature-layout.storage.service';
import { SignatureDigitalService } from 'src/app/shared/services/signature/digital/signature-digital.service';
import { SignatureHttpService } from 'src/app/shared/services/signature/signature.http.service';
import { ModalAssinaturaService } from '../assinar-documento/components/modal-assinatura/modal-assinatura.service';
import { ModalConfirmarInicioAssinaturaService } from '../assinar-documento/components/modal-confirmar-inicio-assinatura/modal-confirmar-inicio-assinatura.service';
import { ModalSucessoAssinaturaComponentService } from '../assinar-documento/components/modal-sucesso-assinatura/modal-sucesso-assinatura.component.service';
import { SignatureEletronicService } from 'src/app/shared/services/signature/eletronic/signature-eletronic.service';
import { IpService } from 'src/app/shared/services/ip/ip.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { ModalAtualizarAssinaturaService } from '../assinar-documento/components/modal-atualizar-assinatura/modal-atualizar-assinatura.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [BatchSignComponent],
  imports: [
    CommonModule,
    BatchSignRoutingModule,
    SharedModule
  ],
  providers: [
    ModalSelectSignatureTypeService,
    AssinarDocumentoStorageService,
    SignatureLayoutStorageService,
    SignatureDigitalService,
    SignatureHttpService,
    ModalAssinaturaService,
    ModalConfirmarInicioAssinaturaService,
    ModalSucessoAssinaturaComponentService,
    SignatureEletronicService,
    IpService,
    LocationService,
    ModalAtualizarAssinaturaService,
    NgbDropdown
  ]
})
export class BatchSignModule { }
