<div class="app-select-normal">
    <label *ngIf="description !== ''" [for]="id">
        {{description}}<span *ngIf="isRequired" class="icon-required"> * </span></label>
    <br>
    <div ngbDropdown placement="bottom" #dropdownSelectNormal="ngbDropdown" autoClose="true" class="w-100">
        <button type="button" class="
            input
            dropdown-toggle
            d-flex
            align-items-center
            justify-content-between
            text-wrap
          " id="dropdownInput" ngbDropdownToggle [disabled]="formulario.disabled">
            <div class="body14">{{optionSelect?.label || placeholder}}</div>
            <i class="np"  [ngClass]="{'np-angle-down': !dropdownSelectNormal.isOpen(), 'np-angle-up': dropdownSelectNormal.isOpen()}"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownInput" class="p-1 w-100">
            <button ngbDropdownItem class="btn px-0 py-2 d-flex justify-content-between w-100 text-wrap mt-1" (click)="onSelectOption(option)" *ngFor="let option of options"
                value="{{option.valor}}">
                <div class="wBody14 ml-3">{{option.label}}</div>
            </button>
        </div>
    </div>
</div>