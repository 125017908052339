import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecoverPasswordComponent } from './views/recover-password.component';
import { RecoverPasswordRoutingModule } from './recover-password-routing.module';
import { FormRecoverPasswordComponent } from './components/form-recover-password/form-recover-password.component';

@NgModule({
  declarations: [
    RecoverPasswordComponent,
    FormRecoverPasswordComponent,

  ],
  imports: [
    CommonModule,
    RecoverPasswordRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class RecoverPasswordModule { }
