import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { ModalFolder } from 'src/app/shared/models/folder';

@Component({
  selector: 'app-modal-folder',
  templateUrl: './modal-folder.component.html',
  styleUrls: ['./modal-folder.component.scss'],
})
export class ModalFolderComponent implements OnInit {
  @Input() viewModal: ModalFolder;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  onCancel() {
    this.activeModal.close();
  }
}
