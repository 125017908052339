import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSucessoAssinaturaComponent } from './modal-sucesso-assinatura.component';

@Injectable()
export class ModalSucessoAssinaturaComponentService {
    constructor(
        private modalService: NgbModal,
    ) { }

    public async open(): Promise<void> {
        try {
            const modalRef = this.modalService.open(ModalSucessoAssinaturaComponent, {
                centered: true,
            });
            await modalRef.result;
            return Promise.resolve();
        } catch (error) {
            return Promise.resolve();
        }
    }
}
