import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmarSair } from '../../models/modal-confirmar-sair.model';
import { IResponsibility } from '../../models/responsibility.interface';
import { ModalConfirmDeleteTagComponent } from './modal-confirm-delete-tag.component';

@Injectable()
export class ModalConfirmDeleteTagService {

  constructor(
    private ngbModal: NgbModal
  ) { }

  public open(): Promise<boolean> {
    const modal = this.ngbModal.open(ModalConfirmDeleteTagComponent, {
      centered: true,
      windowClass: 'windowModal',
      backdropClass: 'backdrop-blur-filter',
    });

    return modal.result;
  }

}
