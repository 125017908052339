import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NpaperIcon } from '../../types/npaper-icon.type';

@Component({
  selector: 'app-card-with-icon-title-radio',
  templateUrl: './card-with-icon-title-radio.component.html',
  styleUrls: ['./card-with-icon-title-radio.component.scss']
})
export class CardWithIconTitleRadioComponent implements OnInit {

  @Input() value: string;
  @Input() group: string;
  @Input() formControl: FormControl;
  @Input() title: string | number;
  @Input() icons: NpaperIcon[] = [];
  @Input() description: string = '';

  constructor() { }

  ngOnInit(): void {
    if (!this.formControl) {
      throw new Error('CardWithIconTitleRadioComponent: formControl is required');
    }
    if (!this.value) {
      throw new Error('CardWithIconTitleRadioComponent: value is required');
    }
    if (!this.title) {
      throw new Error('CardWithIconTitleRadioComponent: title is required');
    }
  }

  public selectOption(value: string): void {
    this.formControl.setValue(value);
  }

}
