import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EletronicSignData } from 'src/app/shared/models/eletronicSignData';
import { ModalAssinaturaComponent } from './modal-assinatura.component';

@Injectable()
export class ModalAssinaturaService {
    constructor(
        private modalService: NgbModal,
    ) { }

    public async open(pAssinaturaCadastrada: string | null): Promise<EletronicSignData | null> {
        try {
            const modalRef = this.modalService.open(ModalAssinaturaComponent, {
                centered: true,
                backdrop: 'static',
                modalDialogClass: 'modal-content-width-auto',
            });
            modalRef.componentInstance.assinaturaCarregada = pAssinaturaCadastrada;
            const result = await modalRef.result;
            return Promise.resolve(result);
        } catch (error) {
            return Promise.resolve(null);
        }
    }
}
