<div class="tag">
  <span
    class="badge badge-light mr-2 align-items-center px-2"
    *ngFor="let tag of tags; let i = index"
    [ngStyle]="{ background: tag.color }"
  >
    <i class="np-tag badge-icon iconBody14 color-000"></i>
    <div class="body12 color-000 mx-1">{{ tag.name }}</div>
    <i class="np-error badge-remove iconBody14 color-000" (click)="removeBadge(tag.id)"></i>
  </span>
</div>
