export const homeAvatarList: Array<string> = [
    'Georgi Mateus Parente',
    'Maria da Silva',
    'João da Silva',
    'Pedro da Silva',
    'Gisela Meira',
    'Joana da Silva',
    'Liz Gabeira',
    'Eliel Piteira',
    'Derek Peçanha',
    'Nélson Durão ',
    'José da Silva',
    'Laurindo Eanes',
    'Mohammed Feijó',
    'Zlata Caeiro',
    'Evelina Proença',
    'Liam Arouca',
    'Luan da Silva',
    'Kayla Jorge'
];
