import { Component, OnInit } from '@angular/core';
import { TypedFormControl, typedFormControlBuilder } from 'src/typedReactiveForm';
import { signatureDefaultSize } from '../../constants/signatureDefaultSize';

@Component({
  selector: 'app-assinatura-digitar',
  templateUrl: './assinatura-digitar.component.html',
  styleUrls: ['./assinatura-digitar.component.scss']
})
export class AssinaturaDigitarComponent implements OnInit {
  public nomeControl: TypedFormControl<string> = typedFormControlBuilder<string>('');
  public fontControl: TypedFormControl<string> = typedFormControlBuilder<string>('italianno');
  public fontsList = [
    { value: 'italianno', fontClass: 'ff-italianno' },
    { value: 'kalam', fontClass: 'ff-kalam' },
    { value: 'kaufmannbt', fontClass: 'ff-kaufmannbt' },
  ];
  constructor() { }

  ngOnInit(): void { }

  public getAssinaturaDados(): string {
    const canvasEl = document.createElement('canvas');
    const canvasContext = canvasEl.getContext('2d');
    if (canvasContext === null) {
      throw new Error('Erro ao carregar o modulo de desenho de assinatura');
    }

    const cx = canvasContext;
    canvasEl.width = signatureDefaultSize.width;
    canvasEl.height = signatureDefaultSize.height;
    cx.font = '42px ' + this.fontControl.value;
    cx.fillText(this.nomeControl.value, 50, canvasEl.height / 2);

    return canvasEl.toDataURL('image/png');
  }
}
