import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { geradorStringAleatorio } from '../../utils/string-util';

@Component({
    template: '',
})
export class BehaviorInputComponent {

    @Input() public label: string = 'Default';
    @Input() public placeholder: string = 'Default';
    @Input() public id: string;
    @Input() public inputControl: FormControl;
    @Input() public isRequired: boolean = false;
    @Input() public labelErro: string = '';
    @Output() public eventFocusOut: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(
    ) {
        if (!!this.id === false) {
            this.id = geradorStringAleatorio(10);
        }
    }

    public checkInvalidTouched(): boolean {
        return this.inputControl.invalid && (this.inputControl.value || this.inputControl.touched);
    }

    public checkValid(): boolean {
        return this.inputControl.valid && this.inputControl.touched;
    }

    public checkInvalidTouchedColor(): boolean {
        return this.inputControl.invalid && (this.inputControl.value || this.inputControl.touched);
    }

    public checkValidColor(): boolean {
        return this.inputControl.valid && this.inputControl.touched;
    }

    public onEmitterEventFocusOut(event: Event): void {
        this.eventFocusOut.emit(event);
    }

}
