import { SharedModule } from './../../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CardUsersComponent } from './components/card-users/card-users.component';
import { ModalConfirmBlockerOrDeleteComponent } from './components/modal-confirm-blocker-or-delete/modal-confirm-blocker-or-delete.component';
import { ModalUsersComponent } from './components/modal-users/modal-users.component';
import { UsersComponent } from './views/users/users.component';

@NgModule({
  declarations: [
    CardUsersComponent,
    ModalUsersComponent,
    UsersComponent,
    ModalConfirmBlockerOrDeleteComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  providers: [
    NgbActiveModal
  ]
})
export class UsersModule { }
