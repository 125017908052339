<div class="row">
    <app-select-search-customized
        class="col-md-12"
        placeholder="Buscar"
        [formSelect]="formSelect"
        [options]="optionCertificate"
        (eventSelectOption)="onEventSelectOption($event)">
    </app-select-search-customized>
    <div class="col-md-12 mt-3">
        <button class="btn btn-secondary" (click)="refreshCertificate()">
            <i class="np np-update mr-2"></i>
            Recarregar Certificado Digital
        </button>
    </div>
</div>