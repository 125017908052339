import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NSignatureHttpService } from '../../models/signatureHttpService.model';
import { RequestService } from '../request/request.service';

@Injectable()
export class SignatureHttpService {
    constructor(private requestService: RequestService) { }

    public assinarEletronico(pDados: NSignatureHttpService.ISignatureEletronicRequest): Promise<NSignatureHttpService.ISignatureEletronicResponse> {
        return this.requestService
            .setHeaderToken()
            .post(environment.baseUrlAPi + '/signature/eletronic', pDados);
    }
    public assinarDigitalStart(pDados: NSignatureHttpService.ISignatureDigitalStartRequest): Promise<NSignatureHttpService.ISignatureDigitalStartResponse> {
        return this.requestService
            .setHeaderToken()
            .post(environment.baseUrlAPi + '/signature/start', pDados);
    }
    public assinarDigitalCancelar(pDados: NSignatureHttpService.ISignatureDigitalCancelRequest): Promise<void> {
        return this.requestService
            .setHeaderToken()
            .post(environment.baseUrlAPi + '/signature/cancel', pDados);
    }
    public async assinarDigitalComplete(pDados: NSignatureHttpService.ISignatureDigitalCompleteRequest): Promise<NSignatureHttpService.ISignatureDigitalCompleteResponse> {
        try {
            const response = await this.requestService
                .setHeaderToken()
                .post(environment.baseUrlAPi + '/signature/complete', pDados);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(this.onServerErrorSignature(error));
        }
    }

    private onServerErrorSignature(err: any): Error {
        console.log(err);
        throw new Error(err);
    }
}
