import { Component, Input, OnInit } from '@angular/core';
import { BehaviorCheckboxRadioInputComponent } from '../behavior-checkbox-radio-input/behavior-checkbox-radio-input.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends BehaviorCheckboxRadioInputComponent implements OnInit {

  @Input() checked: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
