<footer class="footer wBody14">
  <div class="row">
    <div *ngIf="!isVisaoMobile" class="col-lg-9 offset-lg-3">
      <div class="d-flex justify-content-between margin">
        <div>
          <span>© 2020 <strong>Decisão Sistemas</strong>. Todos os direitos
            reservados.</span>
        </div>
        <div>
          <span>Privacidade | Termos</span>
        </div>
      </div>
    </div>

    <div *ngIf="isVisaoMobile" class="col-lg-9 offset-lg-3 pl-3">
      <div style="margin-left: 5px" class="d-flex justify-content-between">
        <div>
          <span class="d-flex">© 2020 <strong>Decisão Sistemas</strong>. Todos os direitos
            reservados.</span>
          <span><strong>Privacidade | Termos</strong></span>
        </div>
      </div>
    </div>
  </div>
</footer>