import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutModule } from './core/auth-layout/auth-layout.module';
import { NotAuthLayoutModule } from './core/not-auth-layout/not-auth-layout.module';
import { HomeNotLoggedComponent } from './home-not-logged/home-not-logged.component';
import { HomeComponent } from './home/home.component';
import { BatchSignModule } from './modules/batch-sign/batch-sign.module';
import { FolderModule } from './modules/folder/folder.module';
import { NewPasswordModule } from './modules/new-password/new-password.module';
import { RecoverPasswordModule } from './modules/recover-password/recover-password.module';
import { SendModule } from './modules/send/send.module';
import { UsersModule } from './modules/users/users.module';
import { NgbDatePTParserFormatterService } from './shared/services/datePicker/ngb-date-ptparser-formatter.service';
import { SharedModule } from './shared/shared.module';
import { SiteComponent } from './pages/site/site.component';
@NgModule({
  declarations: [AppComponent, HomeComponent, HomeNotLoggedComponent, SiteComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AuthLayoutModule,
    NotAuthLayoutModule,
    UsersModule,
    SendModule,
    FolderModule,
    BatchSignModule,
    RecoverPasswordModule,
    NewPasswordModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [],
  providers: [
    [
      {
        provide: NgbDateParserFormatter,
        useClass: NgbDatePTParserFormatterService,
      },
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
