<div class="w-100 px-3 py-2 app-modal-select-signature-type">
  <div class=row>
    <div class="col-md-12 Heading20 text-center p-3 px-md-2 Heading20 body14-sm color-gray-500"
    [ngClass]="{'p-3': !isVisaoMobile, 'p-2': isVisaoMobile}">
      <span class="d-none d-md-inline">
        Para assinatura dos documentos será necessário selecionar uma das opções abaixo!
      </span>
      <span class="d-inline d-md-none">
        Para assinatura dos documentos é necessário selecionar uma das opções abaixo!
      </span>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 col-sm-12 mb-2">
          <app-card-with-icon-title-radio value="P" [formControl]="this.formControlSignatureType"
            (click)="onClickSelectSignatureType()" group="group-radio-signature-type-modal"
            [icons]="['np-digital-signature']" title="Digital" description="Com certificado digital" class="mb-2">
          </app-card-with-icon-title-radio>
        </div>
        <div class="col-md-6 col-sm-12 mb-2">
          <app-card-with-icon-title-radio value="E" [formControl]="this.formControlSignatureType"
            (click)="onClickSelectSignatureType()" [icons]="['np-eletronic-signature']"
            group="group-radio-signature-type-modal" title="Eletrônica" description="Sem certificado digital"
            class="mb-2">
          </app-card-with-icon-title-radio>
        </div>
        <div class="col-md-12 mt-2 message-modal-edit" *ngIf="this.message !== undefined">
          <p class="wBody14 wBody12-sm text-center px-4">
            {{this.message}}
          </p>
        </div>
      </div>
    </div>

    <div class="box-select-certificate" [ngStyle]="{'width': isVisaoMobile ? '90%' : '100%'}" *ngIf="acionador === 'batch-sign' && formControlSignatureType.value === 'P'">
      <div class="space-box" [ngClass]="{'desktop-tablet': !isVisaoMobile, 'mobile': isVisaoMobile}"
        *ngIf="certificates">
        <app-login-certificate [certificates]="certificates" [acionador]="'batch-sign'"
          (eventCertificate)="onReturnCertificateSelected($event)" class="w-100 p-3"></app-login-certificate>

      </div>
    </div>



  </div>
</div>