import {
    AfterViewInit,
    Directive,
    DoCheck,
    ElementRef,
    forwardRef,
    HostListener,
    Inject, Input, KeyValueDiffer,
    KeyValueDiffers, OnInit,
    Optional
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyMaskConfig, CurrencyMaskInputMode, CURRENCY_MASK_CONFIG } from './currency-mask.config';
import { InputHandler } from './input.handler';

export const CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyMaskDirective),
    multi: true,
};

@Directive({
    selector: '[appCurrencyMask]',
    providers: [CURRENCYMASKDIRECTIVE_VALUE_ACCESSOR]
})
export class CurrencyMaskDirective implements AfterViewInit, DoCheck, OnInit, ControlValueAccessor {

    public inputHandler: InputHandler;
    public keyValueDiffer: KeyValueDiffer<any, any>;

    public optionsTemplate: CurrencyMaskConfig = {
        align: 'left',
        allowNegative: false,
        allowZero: true,
        decimal: '.',
        precision: 2,
        prefix: '$ ',
        suffix: '',
        thousands: ',',
        nullable: true,
        required: false,
        inputMode: CurrencyMaskInputMode.FINANCIAL,
    };

    @Input() options: Partial<CurrencyMaskConfig> = {};

    @HostListener('blur', ['$event'])
    handleBlur(event: any): any {
        return this.inputHandler.getOnModelTouched().apply(this, [event]);
    }

    @HostListener('cut', ['$event'])
    handleCut(event: any): boolean | void {
        if (!this.isChromeAndroid()) {
            return !this.isReadOnly() && this.inputHandler.handleCut(event);
        }
    }

    @HostListener('input', ['$event'])
    handleInput(event: any): boolean | void {
        if (this.isChromeAndroid()) {
            return !this.isReadOnly() && this.inputHandler.handleInput(event);
        }
    }

    @HostListener('keydown', ['$event'])
    handleKeydown(event: any): boolean | void {
        if (!this.isChromeAndroid()) {
            return !this.isReadOnly() && this.inputHandler.handleKeydown(event);
        }
    }

    @HostListener('keypress', ['$event'])
    handleKeypress(event: any): boolean | void {
        if (!this.isChromeAndroid()) {
            return !this.isReadOnly() && this.inputHandler.handleKeypress(event);
        }
    }

    @HostListener('paste', ['$event'])
    handlePaste(event: any): boolean | void {
        if (!this.isChromeAndroid()) {
            return !this.isReadOnly() && this.inputHandler.handlePaste(event);
        }
    }

    @HostListener('drop', ['$event'])
    handleDrop(event: any): void {
        if (!this.isChromeAndroid()) {
            event.preventDefault();
        }
    }

    constructor(
        @Optional() @Inject(CURRENCY_MASK_CONFIG)
        private currencyMaskConfig: CurrencyMaskConfig,
        private elementRef: ElementRef,
        private keyValueDiffers: KeyValueDiffers,
    ) {
        if (currencyMaskConfig) {
            this.optionsTemplate = currencyMaskConfig;
        }
        this.keyValueDiffer = keyValueDiffers.find({}).create();
    }

    ngAfterViewInit(): void {
        this.elementRef.nativeElement.style.textAlign = this.options && this.options.align ? this.options.align : this.optionsTemplate.align;
    }

    ngDoCheck(): void {
        if (this.keyValueDiffer.diff(this.options)) {
            this.elementRef.nativeElement.style.textAlign = this.options.align ? this.options.align : this.optionsTemplate.align;
            this.inputHandler.updateOptions(Object.assign({}, this.optionsTemplate, this.options));
        }
    }

    ngOnInit(): void {
        this.inputHandler = new InputHandler(this.elementRef.nativeElement, Object.assign({}, this.optionsTemplate, this.options));
    }

    isChromeAndroid(): boolean {
        return /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
    }

    isReadOnly(): boolean {
        return this.elementRef.nativeElement.hasAttribute('readonly');
    }

    registerOnChange(callbackFunction: (fs: any) => void): void {
        this.inputHandler.setOnModelChange(callbackFunction);
    }

    registerOnTouched(callbackFunction: (fn: any) => void): void {
        this.inputHandler.setOnModelTouched(callbackFunction);
    }

    setDisabledState(value: boolean): void {
        this.elementRef.nativeElement.disabled = value;
    }

    writeValue(value: number): void {
        this.inputHandler.setValue(value);
    }
}
