import { ButtonDetail } from '../../models/detail';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ButtonsDetailService {
  private allStateButton: ButtonDetail[] = [
    {
      type: 'sharedButton',
      icon: 'np-add-user',
      text: 'Compartilhar',
    },
    {
      type: 'renameButton',
      icon: 'np-edit-2',
      text: 'Renomear',
    },
    {
      type: 'signButton',
      icon: 'np-edit',
      text: 'Assinar',
    },
    {
      type: 'resendingButton',
      icon: 'np-sending',
      text: 'Reenviar',
    },
    {
      type: 'saveButton',
      icon: 'np-download',
      text: 'Salvar',
    },
    {
      type: 'trashButton',
      icon: 'np-trash',
      text: 'Descartar',
    },
    {
      type: 'tagButton',
      icon: 'np-tag',
      text: 'Marcadores',
    },
    {
      type: 'moveFolderButton',
      icon: 'np-move-folder',
      text: 'Mover documentos',
    },
    {
      type: 'signButton',
      icon: 'np-edit',
      text: 'Assinar',
    },
    {
      type: 'restoreButton',
      icon: 'np-update',
      text: 'Restaurar',
    },
  ];

  constructor() {}

  selectTypeButton(types: string[]) {
    return this.allStateButton.filter(value => types.indexOf(value.type) >= 0);
  }
}
