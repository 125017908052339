import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface ISubjectLoading {
  isStart: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private subject = new Subject<ISubjectLoading>();

  constructor() { }

  public getObservable(): Observable<ISubjectLoading> {
    return this.subject.asObservable();
  }

  public showLoader(): void {
    this.subject.next({ isStart: true });
  }

  public hideLoader(): void {
    this.subject.next({ isStart: false });
  }
}
