import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class StateService {
  public sessionCertificteAnonymous: Map<string, any> = new Map();

  constructor() { }
}