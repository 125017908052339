import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { geradorStringAleatorio } from '../../utils/string-util';
import { IDataPickerFormControl, IDataPickerValues } from './data-picker.model';

@Component({
  selector: 'app-data-picker',
  templateUrl: './data-picker.component.html',
  styleUrls: ['./data-picker.component.scss']
})
export class DataPickerComponent implements OnInit {
  @Input() public label: string = '';
  @Input() public placeholder: string = '';
  @Input() public control: IDataPickerFormControl;
  @Input() public id: string;
  @Input() public isRequired: boolean = false;
  @Input() public labelErro: string = '';
  @Input() public showErro: boolean = true;
  @Output() public valueChangeEvent: EventEmitter<IDataPickerValues | null> = new EventEmitter<IDataPickerValues | null>();

  constructor() {
    if (!!this.id === false) {
      this.id = geradorStringAleatorio(10);
    }
  }

  ngOnInit(): void {
    this.control?.valueChanges?.subscribe((pEvent) => {
      this.valueChangeEvent.emit(pEvent);
    });
  }
}
