import { Component, OnInit } from '@angular/core';
import { BehaviorCheckboxRadioInputComponent } from '../behavior-checkbox-radio-input/behavior-checkbox-radio-input.component';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent extends BehaviorCheckboxRadioInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void { }

  public onChecked(): void {
    super.onChecked(this.value);
  }

}
