<nav class="nav-bar d-flex align-items-center w-100">
  <div class="brand">
    <div>
      <img src="../../../../assets/images/npaperLogoNav.svg " alt="Logo Npaper" />
    </div>
  </div>
  <div class="containerNav w-100">
    <div class="buttons d-flex align-items-center ml-1">
      <button
        type="button"
        class="
          d-flex
          justify-content-between
          align-items-center
          mx-auto
          return-user-button
          body14
        "
        id="dropdownUser"
        [routerLink]="['../login']"
      >
        <span class="mr-2">Retornar para login</span>
      </button>
    </div>
  </div>
</nav>