<div class="header-info">
  <div class="d-flex justify-content-between card-group mx-3 mx-sm-0">
    <div *ngFor="let card of cards" class="flex-grow-1 cards">
      <div class="card-extract wBody14  {{card.color}} d-flex">
        <span class="span-card-extract d-flex flex-grow-1 row">
          <div class="card-span mb-2">
            <span class="mr-1">
              <i class=" {{card.icon}} icon-mobile"></i>
            </span>
            <span>{{card.label}}</span>
            <span class="d-sm-none">:</span>
          </div>
          <div class="ml-1">
            <span class="Heading16 d-lg-block d-none  color-{{card.color}}">{{card.value}}</span>
            <span class="body14 d-lg-none color-{{card.color}}">{{card.value}}</span>
          </div>
        </span>
        <i class="{{card.color}} {{card.icon}} icon-desktop  iconBody18"></i>
      </div>
    </div>
  </div>
</div>