import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IResponsibility } from '../../models/responsibility.interface';
import { ModalAddEditResponsibilityComponent } from './modal-add-edit-responsibility.component';

@Injectable()
export class ModalAddEditResponsibilityService {

  constructor(
    private ngbModal: NgbModal
  ) { }

  public async openAdd(): Promise<IResponsibility> {
    const modal = this.ngbModal.open(ModalAddEditResponsibilityComponent, {
      size: 'md',
      centered: true,
      modalDialogClass: 'modal-mobile-align-start modal-tablet-align-start modal-dialog-margin-inline',
      backdropClass: 'backdrop-blur-filter',
    });
    return await modal.result;
  }

  public async openEdit(responsibility: IResponsibility): Promise<boolean> {
    const modal = this.ngbModal.open(ModalAddEditResponsibilityComponent, {
      size: 'md',
      centered: true,
      modalDialogClass: 'modal-mobile-align-start modal-tablet-align-start modal-dialog-margin-inline',
      backdropClass: 'backdrop-blur-filter',
    });
    modal.componentInstance.edit = true;
    modal.componentInstance.responsibility = responsibility;
    return await modal.result;

  }

}
